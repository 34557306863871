import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {

  static targets = [ "dropdown", "content", "counter" ]

  initialize() {
    if(this.element.getAttribute("data-uuid")) {
      this.uuid = this.element.getAttribute("data-uuid");
    }
  }

  connect() {
    let thisController = this;

    consumer.subscriptions.create({ channel: "NotificationsChannel" }, {
      received(data) {
        thisController.notify(data.message);
      },

      connected() {
        console.log("Connected to NotificationsSocket");
      },

      disconnected() {
        console.log("Disconnected from NotificationsSocket");
      }
    })

    $(this.dropdownTarget).on('show.bs.dropdown', function () {
      setTimeout(() => {

        if(thisController.contentTarget.classList.contains('show')) {
          let counter = parseInt(thisController.counterTarget.dataset.counter);
          thisController.counterTarget.classList.add('d-none');
          thisController.counterTarget.textContent = '';
          thisController.counterTarget.dataset.counter = '0';

          Rails.ajax({
            url: `/notifications`,
            type: "POST",
            data: null,
            contentType: false,
            processData: false,
            method: 'POST',
            success: function(data) {},
            error: function(data) {}
          });

          thisController.contentTarget.querySelectorAll('.fa-circle.text-danger').forEach((icon, i) => {
            icon.classList.add('d-none');
          });
        }

      }, 1500)
    })

    Rails.ajax({
      url: `/notifications`,
      type: "GET",
      data: null,
      contentType: false,
      processData: false,
      method: 'POST',
      success: function(data) {
        data.forEach((data, i) => {
          if(i > 9) return

          let p = thisController.contentTarget.querySelector('p.text-muted');
          if(p) {
            thisController.contentTarget.innerHTML = '';
          }

          let entry = thisController.notificationEntry(data, data.read.includes(thisController.uuid));
          thisController.contentTarget.innerHTML += entry;

          counter = 0;
          let counter = parseInt(thisController.counterTarget.dataset.counter);
          if(!data.read.includes(thisController.uuid)) {
            counter += 1;
          }

          if(counter > 0) {
            thisController.counterTarget.textContent = counter;
            thisController.counterTarget.classList.remove('d-none');
          } else {
            thisController.counterTarget.classList.add('d-none');
            thisController.counterTarget.textContent = '';
          }
          thisController.counterTarget.dataset.counter = counter;
        });
      },
      error: function(data) {}
    });
  }

  notify(data) {
    let p = this.contentTarget.querySelector('p.placeholder');
    if(p) {
      this.contentTarget.innerHTML = '';
    }

    let entry = this.notificationEntry(data, false);

    if(entry) {
      this.contentTarget.innerHTML = entry + this.contentTarget.innerHTML;

      let counter = parseInt(this.counterTarget.dataset.counter);
      counter += 1;
      this.counterTarget.classList.remove('d-none');
      this.counterTarget.textContent = counter;
      this.counterTarget.dataset.counter = counter;

      let sound = document.querySelector('#notificationSound')
      if(sound) {
        sound.play();
      }
    }
  }

  notificationEntry(data, read) {
    let icon = '<i class="fas fa-tasks"></i>';
    let category = 'Application';
    let message = '';
    switch(data.category) {
      case "accounting":
        icon = '<i class="fa-regular fa-file-invoice-dollar"></i>';
        category = I18n.t('accounting.accounting');
        switch(data.message) {
          case "camt053_import":
            message = I18n.t('notifications.accounting.camt053_import', {files: data.data.files, entries: data.data.entries, imported: data.data.imported, account: data.data.account});
            break;
        }
        break;
      case "orders":
        icon = '<i class="fas fa-file-invoice fa-fw"></i>';
        category = I18n.t('order.orders');
        switch(data.message) {
          case "status_change":
            message = I18n.t('notifications.orders.status_change', {reference: data.data.reference, status: I18n.t(`order.states.${data.data.state}`)});
            break;
          case "galaxus_import":
            message = I18n.t('notifications.custom_modules.galaxus.order_import', {available: data.data.available, imported: data.data.imported});
            break;
          case "paid":
            message = I18n.t('notifications.orders.paid', {order: data.data.order, reference: data.data.reference, account: data.data.account});
            break;
          case "webshop_import":
            message = I18n.t('notifications.orders.webshop_import', {shop: data.data.name, count: data.data.count});
            break;
        }
        break;
      case "invoices":
        icon = '<i class="fas fa-file-invoice fa-fw"></i>';
        category = I18n.t('document.invoices');
        switch(data.message) {
          case "paid":
            message = I18n.t('notifications.invoices.paid', {invoice: data.data.invoice, account: data.data.account});
            break;
        }
        break;
      case "purchases":
        icon = '<i class="fas fa-file-invoice fa-fw"></i>';
        category = I18n.t('purchase.purchases');
        break;
      case "items":
        icon = '<i class="fas fa-list-ul fa-fw"></i>';
        category = I18n.t('item.items');
        switch(data.message) {
          case "status_change":
            message = I18n.t('notifications.items.status_change', {item: data.data.item, status: data.data.state});
            break;
        }
        break;
      case "warehouses":
        icon = '<i class="fas fa-boxes fa-fw"></i>';
        category = I18n.t('warehouse.warehouses');
        switch(data.message) {
          case "low_stock":
            message = I18n.t('notifications.warehouses.low_stock', {item: data.data.item, warehouse: data.data.warehouse});
            break;
        }
        break;
      case "accounting":
        icon = '<i class="fas fa-coins fa-fw"></i>';
        category = I18n.t('accounting.accounting');
        break;
      case "webshops":
        icon = '<i class="fas fa-store fa-fw"></i>';
        category = I18n.t('webshop.webshops');
        switch(data.message) {
          case "sync_items":
            if(data.data.status == 'ok') {
              message = I18n.t('notifications.webshops.sync_items', {shop: data.data.name});
            }
            break;
          case "sync_orders":
            if(data.data.status == 'ok') {
              message = I18n.t('notifications.webshops.sync_orders', {shop: data.data.name});
            }
            break;
          case "sync_customers":
            if(data.data.status == 'ok') {
              message = I18n.t('notifications.webshops.sync_customers', {shop: data.data.name});
            }
            break;
          case "sync_categories":
            if(data.data.status == 'ok') {
              message = I18n.t('notifications.webshops.sync_categories', {shop: data.data.name});
            }
            break;
          case "sync_google_merchant":
            if(data.data.status == 'ok') {
              message = I18n.t('notifications.webshops.sync_google_merchant', {shop: data.data.name});
            }
            break;
        }
        break;
      case "stores":
        icon = '<i class="fas fa-cash-register fa-fw"></i>';
        category = I18n.t('store.stores');
        break;
      default:
        // code block
    }

    let html = `
        <div class="media px-2 py-1">
          <div class="media-body mr-1">
            <div class="small text-muted ml-1">
              ${icon}
              ${category}
      `
    if(!read) {
      html += '<small><i class="fas fa-circle text-danger"></i></small>';
    }
    html += `
              <span class="float-right">
                ${data.data.timestamp}
              </span>
            </div>
            <p class="notification-content p-1 mb-1">
              ${message}
            </p>
          </div>
        </div>
      `
    if(message != '' && message != null)
      return html;
    else
      return '';
  }
}
