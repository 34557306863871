import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"
import { TempusDominus, Namespace } from "@eonasdan/tempus-dominus"

export default class extends Controller {

  static targets = [ "filter", "description_long", "description_3", "description_4", "description_5", "btn_description_show_all", "i18n_toggle", "i18n_name", "movement_comment", "movement_comment_input", "movement_comment_label", "categories", "categoriesInp", "label", "description_short", "description_long", "uploadingImage", "uploadingFile", "uploadingVideo", "links", "variants", "accounting_income", "accounting_expense", "prices", "hciBtnSearch", "bexio_account", "bexio_tax_account", "data_delivery_time_unit"]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-page") && ['categories', 'new', 'edit'].includes(this.element.getAttribute("data-page"))) {
      this.expandCategories(this.element.getAttribute("data-page"));
    }
    if(this.element.getAttribute("data-uuid")) {
      this.uuid = this.element.getAttribute("data-uuid");
    }
    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
    if(this.element.getAttribute("data-warehouses")) {
      this.warehouses = this.element.getAttribute("data-warehouses");
    }
    if(this.element.getAttribute("data-permissions")) {
      this.permissions = this.element.getAttribute("data-permissions");
    }
  }

  connect() {
    if(this.page == 'index') {
      this.initTableFunctions();
      this.initData();
    }

    if(this.page == 'show') {
      this.initOrdersTableFunctions();
      this.initOrdersData();
    }

    if(this.page == 'hci_search') {
      this.initHCITableFunctions()
    }

    if(this.page == 'new' || this.page == 'edit') {
      this.initItemSelectDialog();

      if(this.hasAccounting_incomeTarget) {
        this.accounting_incomeTarget.value = this.accounting_incomeTarget.dataset.account;
      }
      if(this.hasAccounting_expenseTarget) {
        this.accounting_expenseTarget.value = this.accounting_expenseTarget.dataset.account;
      }
      if(this.hasBexio_accountTarget) {
        this.bexio_accountTarget.value = this.bexio_accountTarget.dataset.account;
      }
      if(this.hasBexio_tax_accountTarget) {
        this.bexio_tax_accountTarget.value = this.bexio_tax_accountTarget.dataset.account;
      }

      if(document.querySelector('#datetimepicker_status')) {
        const picker = new TempusDominus(document.getElementById('datetimepicker_status'), {
          localization: {
            locale: this.locale
          },
          useCurrent: false,
          display: {
            components: {
              date: true,
              decades: true,
              month: true,
              year: true,
              hours: false,
              seconds: false,
              minutes: false
            },
            buttons: {
              clear: true
            }
          }
        });

        const subscriptionC = picker.subscribe(Namespace.events.change, (e) => {
          if(e.isClear && e.oldDate != undefined) {
            picker.clear()
            picker.toggle()
          }
        });
      }

      if(this.hasData_delivery_time_unitTarget) {
        this.data_delivery_time_unitTarget.value = this.data_delivery_time_unitTarget.dataset.selected;
      }
    }

    if(this.page == 'categories') {
      this.initItemSelectDialog();
    }

    if(this.hasDescription_longTarget && this.page == 'show') {

      let divs = this.description_longTargets;

      divs.forEach( (div) => {
        if(div.scrollHeight > div.clientHeight) {
          div.querySelector('button').classList.remove('d-none');
        }
      })

      divs = this.description_3Targets;
      divs.forEach( (div) => {
        if(div.scrollHeight > div.clientHeight) {
          div.querySelector('button').classList.remove('d-none');
        }
      })

      divs = this.description_4Targets;
      divs.forEach( (div) => {
        if(div.scrollHeight > div.clientHeight) {
          div.querySelector('button').classList.remove('d-none');
        }
      })

      divs = this.description_5Targets;
      divs.forEach( (div) => {
        if(div.scrollHeight > div.clientHeight) {
          div.querySelector('button').classList.remove('d-none');
        }
      })

      $('a[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
        divs.forEach( (div) => {
          if(div.scrollHeight > div.clientHeight) {
            div.querySelector('button').classList.remove('d-none');
          }
        })
      })
    }

    let itemsController = this;
    $(".selectpicker.vat").selectpicker({ width: '80px' });
    var sp = $(".selectpicker").selectpicker({ width: '100%' });
    sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      if(e.target.id == 'price_vat') {
        itemsController.calcPriceWithVAT();
      }
    });

    let images = document.querySelector("#images");
    if(images) {
      this.enableImageSorting();
    }

    let videos = document.querySelector("#videos");
    if(videos) {
      this.enableVideoSorting();
    }

    let files = document.querySelector("#files");
    if(files) {
      this.enableFileSorting();
    }

    let suppliers = document.querySelector("#suppliers");
    if(suppliers) {
      this.enableSupplierSorting();
    }

    let filter = Cookies.get('filter_items');
    if(filter && this.hasFilterTarget) {
      this.filterTarget.value = filter;
      this.filterItems();
    }

    let movements = document.querySelector("#movements");
    if(movements) {
      this.loadWarehouseMovements();
    }

    document.querySelectorAll(".trix-content").forEach((div) => {
      div.querySelectorAll("a").forEach((link) => {
        link.setAttribute("target", "_blank");
      });
    });

    $('#loading').fadeOut(200);
  }

  filterItems() {
    Cookies.set('filter_items', this.filterTarget.value.toLowerCase());
  }

  clearFilter() {
    this.filterTarget.value = '';
    Cookies.expire('filter_items');

    $("#items").bootstrapTable('resetSearch', '');
    this.filterTarget.focus();
    this.filterTarget.blur();
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  loadAllItems() {
    let itemsController = this;
    if(document.querySelector(".container-fluid.items").dataset.loaded == 'false') {
      Rails.ajax({
        url: `/${this.locale}/items`,
        type: "get",
        data: "",
        success: function(data) {
          if(document.querySelector(".container-fluid.items")) {
            document.querySelector(".container-fluid.items").dataset.loaded = 'true';
          }
          if(itemsController.filterTarget.value != '') {
            itemsController.filterItems();
          }
        },
        error: function(data) {
          console.log("Error loading items");
        }
      });
    }
  }

  toggleStocks() {
    if(!document.querySelector("#item_with_stock").checked) {
      $("#stocks").removeClass("d-none");
    } else {
      $("#stocks").addClass("d-none");
    }
  }

  addStockPosition() {
    let row = event.target.closest('tr');
    let tbody = event.target.closest('tbody');

    let tr = tbody.insertRow(-1);
    var td1 = tr.insertCell(0);
    var td2 = tr.insertCell(1);
    var td3 = tr.insertCell(2);
    var td4 = tr.insertCell(3);
    var td5 = tr.insertCell(4);
    var td6 = tr.insertCell(5);
    td1.innerHTML = `
      <input type="hidden" id="warehouse_data_uuid" name="stock_data[][warehouse]" value="${event.target.dataset.warehouse}">
      <input type="hidden" id="warehouse_data_uuid" name="stock_data[][uuid]" value="${crypto.randomUUID()}">
    `
    td2.innerHTML = `
      <label for="data_stock" class="text-truncate">${I18n.t('item.stock_on')}</label>
      <input type="number" id="data_stock" name="stock_data[][stock]" style="width:100px;" class="form-control form-control-sm" value="0" data-action="input->items#updateTotalStock">
      `
    td3.innerHTML = `
      <label for="data_stock_position" class="text-truncate">${I18n.t('item.stock_position')}</label>
      <input type="text" id="data_stock_position" name="stock_data[][position]" class="form-control form-control-sm">
      `
    td4.innerHTML = `
      <label for="data_batch" class="text-truncate">${I18n.t('item.batch')}</label>
      <input type="text" id="data_batch" name="stock_data[][batch]" style="max-width:240px;" class="form-control form-control-sm">
      `
    td5.innerHTML = `
      <label for="data_expiration_date" class="text-truncate">${I18n.t('item.expiration_date')}</label>
      <input type="date" id="data_expiration_date" name="stock_data[][expiration_date]" style="width:140px;" class="form-control form-control-sm">
    `
    td6.innerHTML = `
      <button type="button" class="btn btn-xs btn-danger" data-action="click->items#removeStockPosition">
        <i class="fa-solid fa-trash fa-fw"></i>
      </button>
    `
    td2.classList.add("pe-3");
    td3.classList.add("pe-3");
    td4.classList.add("pe-3");
    td5.classList.add("pe-3");
    td6.classList.add("w-auto", "align-bottom");
  }

  removeStockPosition() {
    let tr = event.target.closest('tr')
    let tbody = tr.closest('tbody')
    tr.remove()

    this.updateTotalStock(tbody)
  }

  updateTotalStock(tbody) {
    if(tbody instanceof InputEvent || tbody instanceof KeyboardEvent) {
      tbody = event.target.closest('tbody')
    }
    let quantity = 0;
    for (var i = 0, tr; tr = tbody.rows[i]; i++) {
      if(i > 0) {
        let inp = tr.cells[1].querySelector('input');
        quantity += parseInt(inp.value)
      }
    }
    let q = tbody.rows[0].cells[1].querySelector('input');
    q.value = quantity;
  }

  toggleCategories() {
    var toggle = event.target;
    var icon = toggle.querySelector('svg');

    setTimeout(() => {
      if(toggle.getAttribute('aria-expanded') == 'true') {
        icon.classList.remove('fa-rotate-270');
      } else {
        icon.classList.add('fa-rotate-270');
      }
    }, 100);
  }

  toggleCategoryItems() {
    let toggle = event.target.querySelector('svg');
    let cexp = Cookies.get('categories_expanded');
    document.querySelectorAll('div.collapse').forEach((div, i) => {
      let icon = div.parentNode.querySelector('svg');
      if(cexp == 'false') {
        div.classList.add('show');
        icon.classList.remove('fa-rotate-270');
        Cookies.set('categories_expanded', 'true');
        toggle.classList.remove('fa-toggle-off');
        toggle.classList.add('fa-toggle-on');
      } else {
        div.classList.remove('show');
        icon.classList.add('fa-rotate-270');
        Cookies.set('categories_expanded', 'false');
        toggle.classList.remove('fa-toggle-on');
        toggle.classList.add('fa-toggle-off');
      }
    });
  }

  addPrice() {
    let itemsController = this;
    Rails.ajax({
      url: `/${this.locale}/items/price/add`,
      type: "get",
      data: "",
      success: function(data) {
        var sp = $(".selectpicker").selectpicker({ width: '100%' });
        sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
          if(e.target.id == 'price_vat') {
            itemsController.calcPriceWithVAT();
          }
        });
      },
      error: function(data) {}
    });
  }

  deletePrice() {
    var tr = event.target.closest("tr");
    tr.remove();
  }

  updatePrice() {
    let tr = event.target.closest('tr');

    let price = tr.querySelector("#price_price");
    let discount = tr.querySelector("#price_discount");
    let discount_type = tr.querySelector("#price_discount_type");
    let price_with_discount = tr.querySelector("#price_price_with_discount");

    if(discount.value == '') {
      price_with_discount.value = '';
    } else {
      if(discount_type.value == "percentage") {
        price_with_discount.value = (price.value-((price.value/100)*discount.value)).toFixed(2);
      } else {
        price_with_discount.value = (price.value - discount.value).toFixed(2);
      }
    }

    this.calcPriceWithVAT()
  }

  addSupplier() {
    Rails.ajax({
      url: `/${this.locale}/items/supplier/add`,
      type: "get",
      data: "",
      success: function(data) {
        $(".selectpicker").selectpicker({ width: '100%', maxWidth: '400px' });
        let producer_number = document.querySelector('#item_producer_number').value;
        let suppliers = document.querySelector('#suppliers').children;
        let supplier = suppliers[suppliers.length-1];
        supplier.querySelector('#supplier_order_number').value = producer_number;
      },
      error: function(data) {}
    });
  }

  deleteSupplier() {
    var tr = event.target.closest("tr");
    tr.remove();
  }

  enableImageSorting() {
    new Sortable(images, {
        handle: '.handle',
        animation: 150
    });
  }

  enableVideoSorting() {
    new Sortable(videos, {
        handle: '.handle',
        animation: 150
    });
  }

  enableFileSorting() {
    new Sortable(files, {
        handle: '.handle',
        animation: 150
    });
  }

  enableSupplierSorting() {
    new Sortable(suppliers, {
        handle: '.handle',
        animation: 150
    });
  }

  deleteImage() {
    var media = event.target.closest("div.d-flex");
    media.remove();
  }

  deleteVideo() {
    var row = event.target.closest("div.d-flex");
    if(!row) {
      row = event.target.closest("div.row");
    }
    row.remove();
  }

  deleteFile() {
    var media = event.target.closest("div.d-flex");
    media.remove();
  }

  selectImage() {
    var div = event.target.closest("div#nav_media");
    var input = div.querySelector("#imgpicker");
    input.click();
  }

  selectFile() {
    var div = event.target.closest("div#nav_media");
    var input = div.querySelector("#filepicker");
    input.click();
  }

  selectVideo() {
    var div = event.target.closest("div#nav_media");
    var input = div.querySelector("#vidpicker");
    input.click();
  }

  uploadImage() {
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var images = document.querySelector("div#images");

    var files = input.files.length;
    var count = 0;

    var busy = this.uploadingImageTarget;
    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      busy.classList.remove('d-none');
      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/item/image/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              busy.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  uploadFile() {
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var files = document.querySelector("div#files");

    var files = input.files.length;
    var count = 0;

    var busy = this.uploadingFileTarget;
    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      busy.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/item/file/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              busy.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  uploadVideo() {
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var videos = document.querySelector("div#videos");

    var files = input.files.length;
    var count = 0;

    var busy = this.uploadingVideoTarget;
    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      busy.classList.remove('d-none');
      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/item/video/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              busy.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  calcPriceWithVAT() {
    let tr = event.target.closest('tr');

    let inp_pwov = tr.querySelector("#price_price");
    let inp_disc = tr.querySelector("#price_discount");
    let inp_pwdisc = tr.querySelector("#price_price_with_discount");
    let inp_vat = tr.querySelector("#price_vat");
    let inp_pvw = tr.querySelector("#price_price_with_vat");

    let price;
    if(inp_disc.value != '') {
      price = inp_pwdisc.value
    } else {
      price = inp_pwov.value
    }

    let vat = inp_vat.options[inp_vat.selectedIndex].value;
    let price_with_vat = price*(1+(vat/100));

    inp_pvw.value = price_with_vat.toFixed(2);
  }

  calcPriceWithoutVAT() {
    let tr = event.target.closest('tr');

    let inp_pwov = tr.querySelector("#price_price");
    let inp_disc = tr.querySelector("#price_discount");
    let inp_pwdisc = tr.querySelector("#price_price_with_discount");
    let inp_vat = tr.querySelector("#price_vat");
    let inp_pvw = tr.querySelector("#price_price_with_vat");
    let price_with_vat = inp_pvw.value;
    let vat = inp_vat.options[inp_vat.selectedIndex].value;
    let price = price_with_vat/(1+(vat/100));

    if(inp_disc.value != '') {
      inp_pwdisc.value = price.toFixed(2);

      let discount_type = tr.querySelector("#price_discount_type");

      if(discount_type.value == "percentage") {
        price = (price*100)/(100-inp_disc.value)
      } else {
        price = price + parseFloat(inp_disc.value);
      }

      inp_pwov.value = price.toFixed(2);
    } else {
      inp_pwov.value = price.toFixed(2);
    }
  }

  deleteConfirm() {
    let itemsController = this;
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead">${event.target.dataset.name}</span>`;
    bootbox.dialog({
      message: `${I18n.t('item.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${itemsController.locale}/item/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
    	      bootbox.hideAll();
          }
        }
      }
    });
  }

  getCategories() {
    let link = event.target.parentNode.querySelector('a[data-toggle]');
    if(link) {
      let icon = link.querySelector('i');
      icon.classList.remove('fa-rotate-270');
      $(`#${link.dataset.category}`).collapse('show');
    }

    var uuid = event.target.dataset.uuid;
    var loaded = event.target.dataset.loaded == 'true';
    if(!loaded) {
      Rails.ajax({
        url: `/${this.locale}/items/categories/${uuid}.js`,
        type: "get",
        data: "",
        success: function(data) {},
        error: function(data) {}
      });
    }
  }

  confirmRemoveFromCategory() {
    let that = this;
    let tr = event.target.closest('tr');
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead">${event.target.dataset.name}</span>`;
    var table = event.target.closest("table");
    var category = table.dataset.categoryUuid;

    bootbox.dialog({
      message: `${I18n.t('item.confirm.remove_item_from_category')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.remove'),
          className: "btn-danger",
          callback: function() {

            var fd = new FormData();
            fd.append("category", category);
            fd.append("items", [uuid]);
            Rails.ajax({
              url: `/${that.locale}/item/category/items/remove`,
              type: "delete",
              data: fd,
              success: function(data) {
                tr.remove();
                if(table.rows.length == 0) {
                  table.closest('tr').remove()
                }
                let btn = that.categoriesTarget.querySelector(`button[data-category-uuid="${category}"]`)
                btn.textContent = `${I18n.t('item.items')}: ${table.rows.length}`;
              },
              error: function(data) {}
            });

    	      bootbox.hideAll();
          }
        }
      }
    });
  }

  confirmDeleteCategory() {
    event.target.classList.add("d-none");
    event.target.parentNode.querySelector(".btn-group").classList.remove("d-none");
  }

  cancelDeleteCategory() {
    event.target.parentNode.classList.add("d-none");
    event.target.parentNode.parentNode.querySelector(".confirm-cancel-category").classList.remove("d-none");
  }

  deleteCategory() {
    var uuid = event.target.dataset.uuid;
    var li = event.target.parentNode.parentNode.parentNode;
    if(uuid) {
      Rails.ajax({
        url: `/${this.locale}/item/category/${uuid}/delete`,
        type: "delete",
        data: "",
        success: function(data) {
          $(li).fadeOut('fast');
        },
        error: function(data) {}
      });
    }
  }

  expandCategories(view) {
    let that = this;
    let div_categories = document.querySelector("#categories");
    let categories = div_categories.querySelectorAll("tr[data-loaded='false']");
    let active_categories = div_categories.dataset.categories;
    let url = `/${this.locale}/items/categories`;
    if(['new', 'edit'].includes(view)) {
      url = `/${this.locale}/items/categories/select`;
    }

    categories.forEach((category) => {
      let uuid = category.dataset.uuid;
      category.setAttribute('data-loaded', 'true');
      Rails.ajax({
        url: `${url}/${uuid}.json`,
        type: "get",
        data: "",
        success: function(data) {
          data.forEach((d, i) => {
            var parent = that.categoriesTarget.querySelector(`tr[data-uuid='${d.parent}']`)
            let level = parseInt(parent.dataset.level)
            var tr = document.createElement('tr')
            tr.setAttribute("data-uuid", d.uuid);
            tr.setAttribute("data-loaded", "false");
            tr.setAttribute("data-level", level+1)
            tr.classList.add("align-top");
            var c1 = tr.insertCell(0);
            c1.style.paddingLeft = `${(level+1)*20}px`;

            if(['new', 'edit'].includes(view)) {
              let div_class = (that.permissions == "seo" ? "pointer-not-allowed" : "");
              let chk_class = (that.permissions == "seo" ? "no-pointer-events" : "");
              c1.innerHTML = `
                <td>
                  <div class="form-check ${div_class}">
                    <input class="form-check-input ${chk_class}" type="checkbox" value="" id="categories_${d.uuid}" name="categories[${d.uuid}]" data-uuid="${d.uuid}" data-action="click->items#refreshCategoryList">
                    <label class="form-check-label ${chk_class} fs-6" for="categories_${d.uuid}">
                      ${d.i18n_name}
                    </label>
                  </div>
                </td>
              `
            } else {
              var c2 = tr.insertCell(1);
              var c3 = tr.insertCell(2);

              let t = d.translations.map(function(t) {
                return `<span data-items-target="i18n_name" class="i18n_${t.locale} ${t.locale == I18n.locale ? "" : "d-none"}">${t.name}</span>`;
              });
              c1.innerHTML = t.join("\n");

              c2.innerHTML = `
                <button type="button" class="btn btn-sm btn-outline-secondary" data-category-uuid="${d.uuid}"  data-action="click->items#categoryLoadItems">
                  ${I18n.t('item.items')}
                  ${d.items_count}
                </button>
              `

              c3.classList.add("text-end");
              if(that.permissions && that.permissions == "write") {
                c3.innerHTML = `
                  <button type="button" class="btn btn-sm btn-outline-success me-2" data-action="click->items#selectItem" data-category-uuid="${d.uuid}">
                    <i class="fas fa-plus-square" aria-hidden="true"></i> ${I18n.t('item.item')}
                  </button>
                  <a class="btn btn-sm btn-outline-success" href="/de/item/categories/new/${d.uuid}">
                    <i class="fas fa-plus-square" aria-hidden="true"></i> ${I18n.t('item.category.category')}
                  </a>
                  `
                }
              if(that.permissions && (that.permissions == "write" || that.permissions == "seo")) {
                c3.innerHTML += `
                  <a class="btn btn-sm btn-outline-secondary ms-2" href="/de/item/category/${d.uuid}/edit">
                    <i class="fas fa-pen" aria-hidden="true"></i>
                  </a>
                `
              }
              if(that.permissions && that.permissions == "write") {
                c3.innerHTML += `
                  <button class="btn btn-sm btn-outline-danger confirm-cancel-category ms-1" data-action="click->items#confirmDeleteCategory">
                    <i class="fas fa-trash" aria-hidden="true"></i>
                  </button>

                  <div class="btn-group btn-group-sm ms-1 d-none" role="group" aria-label="Delete">
                    <button class="btn btn-danger" data-action="click->items#deleteCategory" data-uuid="${d.uuid}">${I18n.t('btn.delete')}&nbsp;?</button>
                    <button class="btn btn-outline-danger" data-action="click->items#cancelDeleteCategory">
                      <i class="fas fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                `
              }
            }

            parent.after(tr);
            parent.setAttribute("data-loaded", "true");
          });
        },
        error: function(data) {}
      });
    })

    setTimeout(() => {
      let div_categories = document.querySelector("#categories");
      if(div_categories) {
        let categories = div_categories.querySelectorAll("tr[data-loaded='false']");

        if(categories.length > 0) {
          this.expandCategories(view);
        } else {
          setTimeout(() => {
            if(active_categories) {
              active_categories.split(' ').forEach( (category) => {
                let checkbox = div_categories.querySelector(`#categories_${category}`);
                if(checkbox) {
                  checkbox.checked = true
                }
              });
            }
          }, 500);
        }
      }
    }, 500);
  }

  categoryLoadItems() {
    let that = this;
    let parent = event.target.closest('tr');
    let uuid = event.target.dataset.categoryUuid;
    Rails.ajax({
      url: `/${I18n.locale}/item/category/${uuid}/items`,
      type: "get",
      data: "",
      success: function(data) {
        if(data.length > 0) {
          var row = that.categoriesTarget.querySelector(`tr[data-category-items='${uuid}']`)
          if(row) {
            row.remove();
          } else {
            that.categoryLoadItemsData(uuid, data);
          }
        }
      },
      error: function(data) {}
    });
  }

  categoryLoadItemsData(category, data) {
    var parent = this.categoriesTarget.querySelector(`tr[data-uuid="${category}"]`)
    var row = document.createElement('tr');
    row.setAttribute("data-category-items", category);
    var cell = row.insertCell(0);
    cell.colSpan = "3";
    cell.classList.add("ps-4", "pe-4");

    if(this.permissions && this.permissions == "write") {
      var toolbar = document.createElement('div');
      toolbar.classList.add('d-flex', 'justify-content-end', 'py-2');
      toolbar.innerHTML = `
        <button type="button" class="btn btn-xs btn-danger" data-action="click->items#confirmRemoveSelectedItemsFromCategory" disabled>
          <i class="fa-solid fa-trash fa-fw"></i>
          ${I18n.t('btn.remove_selected')}
        </button>
      `
    }

    var table = document.createElement('table');
    table.classList.add("table", "table-sm");
    table.setAttribute("data-category-uuid", category);
    data.forEach((item, i) => {
      let tr = table.insertRow(-1);
      tr.classList.add("small", "align-middle");
      tr.setAttribute('data-uuid', item.uuid);
      let td = tr.insertCell(0)
      let details = [item.color, item.size].filter(n => n).join(" | ")
      td.innerHTML = `
        <div class="form-check d-inner-block">
          <input class="form-check-input" type="checkbox" value="" id="item_${item.uuid}" data-action="click->items#categoryItemSelected">
          <label class="form-check-label small" for="item_${item.uuid}">
            ${item.i18n_name}
            <span class="text-muted small ms-4">${details}</span>
          </label>
        </div>
      `;

      td = tr.insertCell(1)
      td.width = "300px"
      td.textContent = item.number

      td = tr.insertCell(2)
      td.classList.add('text-end')
      td.width = "100px"
      td.innerHTML = `
        <a class="btn btn-outline-secondary btn-xs" target="_blank" id="btn_item_open" href="/${this.locale}/item/${item.uuid}">
          <i class="fas fa-external-link-alt fa-fw"></i>
        </a>
      `
      if(this.permissions && this.permissions == "write") {
        td.innerHTML += `
          <button type="button" class="btn btn-outline-danger btn-xs ms-1" data-uuid="${item.uuid}" data-name="${item.i18n_name}" data-action="click->items#confirmRemoveFromCategory">
            <i class="fa-solid fa-link-slash fa-fw"></i>
          </button>
        `
      }
    });
    cell.append(toolbar);
    cell.append(table);
    parent.after(row);
  }

  categoryItemSelected() {
    var parent = this.categoriesTarget.querySelector(`tr[data-category-items]`)
    var btn = parent.querySelector('div').querySelector('button');
    if(parent.querySelectorAll('input[type="checkbox"]:checked').length > 0) {
      btn.removeAttribute('disabled')
    } else {
      btn.setAttribute('disabled', true)
    }
  }

  confirmRemoveSelectedItemsFromCategory() {
    var that = this;
    var tr = event.target.closest('tr');
    var table = tr.querySelector('table');
    let items = []
    let uuids = []
    table.rows.forEach((row, i) => {
      let chk = row.cells[0].querySelector('input[type="checkbox"]')
      if(chk.checked) {
        let lbl = row.cells[0].querySelector('label');
        items.push(lbl.textContent);
        uuids.push(row.dataset.uuid);
      }
    });
    if(items.length > 0) {
      let category = tr.dataset.categoryItems;
      let msg = `
        <div class="smaller">
        ${items.join('<br>')}
        </div>
      `
      bootbox.dialog({
        message: `${I18n.t('item.confirm.remove_item_from_category')}<br><br>${msg}`,
        onEscape: true,
        buttons: {
          cancel: {
            label: I18n.t('btn.cancel'),
            className: "btn-outline-secondary",
          },
          delete: {
            label: I18n.t('btn.remove'),
            className: "btn-danger",
            callback: function() {
              var fd = new FormData();
              fd.append("category", category);
              fd.append("items", uuids);
              Rails.ajax({
                url: `/${that.locale}/item/category/items/remove`,
                type: "delete",
                data: fd,
                success: function(data) {
                  for(let i = table.rows.length-1; i>= 0; i--) {
                    let chk = table.rows[i].cells[0].querySelector('input[type="checkbox"]')
                    if(chk.checked) {
                      table.deleteRow(i)
                    }
                  }
                  let btn = that.categoriesTarget.querySelector(`button[data-category-uuid="${category}"]`)
                  btn.textContent = `${I18n.t('item.items')}: ${table.rows.length}`;
                  btn = tr.querySelector('div').querySelector('button');
                  btn.setAttribute('disabled', 'true');
                },
                error: function(data) {}
              });
            }
          }
        }
      });
    }
  }

  updateCurrency() {
    var tr = event.target.closest('tr');
    var currency = tr.querySelector("#price_currency").value = event.target.value;
    var sel_pdt = tr.querySelector('#price_discount_type');
    sel_pdt.querySelectorAll('option')[0].innerText = event.target.options[event.target.selectedIndex].text;

    tr.querySelectorAll('.input-group-text.currency').forEach((span, i) => {
      span.innerText = event.target.options[event.target.selectedIndex].text;
    });
  }

  showCompleteDescription() {
    let div = event.target.closest("div.item-show-description-long");
    if(div) {
      if(div.dataset.locale == event.target.dataset.locale) {
        div.style.maxHeight = 'none';
        div.style.paddingBottom = '20px';
        event.target.classList.add('d-none');
      }
    }
  }

  toggleAdditionalDescription() {
    let div = event.target.closest('div.row').querySelector('div.trix-content');
    if(div) {
      div.classList.toggle('d-none');
    }
  }

  generateMetaTitle() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    }

    let locale;
    if(event.target.type !== 'button') {
      locale = event.target.parentNode.dataset.locale;
    } else {
      locale = event.target.dataset.locale;
    }

    let name = '';
    for(let i=0; i < this.labelTargets.length;i++) {
      if(this.labelTargets[i].dataset.locale == locale) {
        name = this.labelTargets[i].value;
      }
    }

    let meta_inp = event.target.closest("div.tab-pane").querySelector(`input#webshop_meta_title_${locale}`);
    meta_inp.value = name;
  }

  generateMetaDescription() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    }

    let locale;
    if(event.target.type !== 'button') {
      locale = event.target.parentNode.dataset.locale;
    } else {
      locale = event.target.dataset.locale;
    }

    let description_short = '';

    for(let i=0; i < this.description_shortTargets.length;i++) {
      if(this.description_shortTargets[i].dataset.locale == locale) {
        description_short = this.description_shortTargets[i].editor.getDocument();
      }
    }

    let meta_inp = event.target.closest("div.tab-pane").querySelector("textarea");
    meta_inp.value = description_short;
  }

  generateKeywords() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    }

    let locale;
    if(event.target.type !== 'button') {
      locale = event.target.parentNode.dataset.locale;
    } else {
      locale = event.target.dataset.locale;
    }

    let label = '';
    let description_short = '';
    let description_long = '';

    for(let i=0; i < this.labelTargets.length;i++) {
      if(this.labelTargets[i].dataset.locale == locale) {
        label = this.labelTargets[i].value;
      }
    }

    for(let i=0; i < this.description_shortTargets.length;i++) {
      if(this.description_shortTargets[i].dataset.locale == locale) {
        description_short = this.description_shortTargets[i].editor.getDocument();
      }
    }

    for(let i=0; i < this.description_longTargets.length;i++) {
      if(this.description_longTargets[i].dataset.locale == locale) {
        description_long = this.description_longTargets[i].editor.getDocument();
      }
    }

    let descriptions = label+' '+description_short.toString().trim()+' '+description_long.toString().trim();
    descriptions = descriptions.toLowerCase().replace(/[^a-zA-Z0-9àáèéùòäöü]+/g, " ").split(' ');
    let keywords = descriptions.filter(words => words.length > 3);

    let keywords_inp = event.target.parentNode.parentNode.querySelector(`input#webshop_keywords_${locale}`);
    keywords_inp.value = keywords.filter(distinct).join(', ');
  }

  toggleLocale() {
    let locale = event.target.id;
    if(this.hasI18n_toggleTarget) {
      this.i18n_toggleTargets.forEach((elem, i) => {
        if(elem.id == locale) {
          elem.querySelector('span').innerHTML = '<i class="fa-solid fa-check fa-fw"></i>';
        } else {
          elem.querySelector('span').innerHTML = '<i class="fa-solid fa-fw"></i>';
        }
      });
    }
    if(this.hasI18n_nameTarget) {
      this.i18n_nameTargets.forEach((elem, i) => {
        if(elem.classList.contains(`i18n_${locale}`)) {
          elem.classList.remove('d-none');
        } else {
          elem.classList.add('d-none');
        }
      });
    }
  }

  editStockQuantity() {
    let itemsController = this;
    let stock = event.target;
    let row = event.target.closest('div.row');
    let wh = row.querySelector('div.semi-bold').innerText;
    let content = `
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="stock_now" class="semi-bold">${I18n.t("item.stock_on")}</label>
            <input type="number" class="form-control" id="stock_now" value='${stock.dataset.stock}' readonly>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="stock_new" class="semi-bold">${I18n.t("item.stock_new")}</label>
            <input type="number" class="form-control" id="stock_new" value="${stock.value}">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="stock_new" class="semi-bold">${I18n.t("subscription.comment")}</label>
            <textarea class="form-control" id="comment" rows="3">${this.movement_comment_inputTarget.value}</textarea>
          </div>
        </div>
      </div>
    `;
    var box = bootbox.dialog({
      title: wh,
      message: content,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t("btn.cancel"),
          className: "btn-outline-secondary",
        },
        update: {
          label: I18n.t("btn.apply"),
          className: "btn-success",
          callback: function() {
            stock.value = $(box).find("#stock_new").val();
            if(stock.value != stock.dataset.stock) {
              stock.classList.add('border-primary');
            } else {
              stock.classList.remove('border-primary');
            }
            itemsController.movement_commentTarget.innerText = $(box).find("#comment").val();
            itemsController.movement_comment_inputTarget.value = $(box).find("#comment").val();
            itemsController.movement_comment_labelTarget.classList.remove('d-none');
            box.modal('hide');
          }
        }
      }
    });

    box.on("shown.bs.modal", function() {
      $(box).find("#stock_new").select();
    });

  }

  loadStockQuantities() {
    let btn = event.target;
    let div = btn.parentNode;
    let uuid = btn.dataset.uuid;
    let table = div.querySelector('table');
    if(btn.dataset.loaded == 'true') {
      table.innerHTML = '';
      btn.dataset.loaded = 'false';
    } else {
      Rails.ajax({
        url: `/${this.locale}/item/${uuid}/stock`,
        type: "get",
        success: function(data) {
          table.innerHTML = '';
          data.forEach((stock) => {
            let api_quantity;
            if(stock.data) {
              if(stock.data.ext_api) {
                if(stock.data.ext_api.fluehmann) {
                  api_quantity = stock.data.ext_api.fluehmann.quantity;
                }
              }
            }
            if(stock.name) {
              table.innerHTML += `<tr><td class="pb-0 text-nowrap">${stock.name}:</td>
                <td class="pb-0 text-nowrap"> ${stock.stock ? stock.stock : '-'} ${api_quantity ? `         (${api_quantity})` : ''}</td></tr>`;
            }
          });
          btn.dataset.loaded = 'true';
        },
        error: function(data) {}
      });
    }
  }

  refreshCategoryList() {
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index;
    }
    if(event.target.checked) {
      let list = this.categoriesInpTarget.value.split(' ')
      list.push(event.target.dataset.uuid);
      this.categoriesInpTarget.value = list.filter(distinct).join(' ');
    } else {
      this.categoriesInpTarget.value = this.categoriesInpTarget.value.replace(event.target.dataset.uuid, '');
    }
  }

  loadWarehouseMovements() {
    let uuid = this.uuid;
    Rails.ajax({
      url: `/${this.locale}/item/${uuid}/movements/25`,
      type: "get",
      success: function(data) {
        $('[data-bs-toggle="popover"]').popover();
      },
      error: function(data) {}
    });
  }

  loadAllWarehouseMovements() {
    let uuid = this.uuid;
    let btn = event.target.closest('button');
    btn.setAttribute('disabled', 'true');
    btn.querySelector('svg').classList.add('fa-spin');
    Rails.ajax({
      url: `/${this.locale}/item/${uuid}/movements`,
      type: "get",
      success: function(data) {
        $('[data-bs-toggle="popover"]').popover();
        btn.classList.add('d-none');
        btn.classList.remove('fa-spin');
        btn.removeAttribute('disabled');
      },
      error: function(data) {}
    });
  }

  toggleAllOrders() {
    var table = $('#orders').bootstrapTable({});

    let url = `/${this.locale}/item/${this.uuid}/orders`
    if(document.querySelector("#ordersShowCompleted").checked) {
      url += '/all'
    }

    table.bootstrapTable('refresh', {url: url});
  }

  hciSearch() {
    if(event instanceof KeyboardEvent) {
      if(event.key !== "Enter") {
        return
      }
    }
    let that = this;
    this.hciBtnSearchTarget.disabled = true;
    $('#loading').fadeIn(200);
    let icon = this.hciBtnSearchTarget.querySelector('svg');
    icon.classList.add('fa-beat')
    Rails.ajax({
      url: `/${this.locale}/items/module/hci/search?q=${this.filterTarget.value}`,
      type: "get",
      success: function(data) {
        let products = []
        if(data) {
          if(data.brands) {
            data.brands.forEach((brand, i) => {
              if(brand.products) {
                brand.products.forEach((product, i) => {
                  product.brand = brand.description;
                  products.push(product)
                });
              }
            });
          } else if(data.length > 0) {
            products.push(data)
          }
        }
        let icon = that.hciBtnSearchTarget.querySelector('svg');
        icon.classList.remove('fa-beat');
        that.hciSearchShowResults(products);
        that.hciBtnSearchTarget.disabled = false;
        $('#loading').fadeOut(200);
      },
      error: function(data) {
        let icon = that.hciBtnSearchTarget.querySelector('svg');
        icon.classList.remove('fa-beat');
        that.hciSearchShowResults(products);
        that.hciBtnSearchTarget.disabled = false;
        $('#loading').fadeOut(200);
      }
    });
  }

  hciLookup(productNumber) {
    let that = this;
    $('#loading').fadeIn(200);
    Rails.ajax({
      url: `/${this.locale}/items/module/hci/lookup/${productNumber}`,
      type: "get",
      success: function(data) {
        let html = `
          <input type="hidden" id="hci_product_number" value="${productNumber}">
          <h1 class="display-7">${data.description.description}</h1>
          <p>${data.description.descriptionLong}</p>
          <table class="table table-sm" id="hci_articles">
            <tr>
              <th>${I18n.t('custom_modules.hci_solutions.pharma_code')}</th>
              <th>${I18n.t('item.packaging_content')}</th>
              <th>${I18n.t('item.price')}</th>
            </tr>
          `

        if(data.articles) {
          data.articles.forEach((article, i) => {
            if(article.description.description) {
              html += `
                <tr class="smaller">
                  <td>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="${article.id}" id="article_${article.pharmacode}" name="article[${article.pharmacode}]" ${article.pharmacode == that.filterTarget.value.trim() ? 'checked' : ''}>
                      <label class="form-check-label" for="article_${article.pharmacode}">
                        ${article.pharmacode}
                      </label>
                    </div>
                  </td>
                  <td>
                    ${article.description.description}
                  </td>
                  <td>
              `
              if(article.prices) {
                article.prices.forEach((price, i) => {
                  if(price.isCurrent) {
                    html += `<div>CHF ${price.price} <span class="badge text-bg-light">${price.type.description}</span></div>`
                  }
                });
              }
              html += `
                  </td>
                </tr>
              `
            }
          });
        }
        html += `</table>`
        bootbox.dialog({
          title: I18n.t('item.item'),
          message: html,
          onEscape: true,
          size: 'large',
          buttons: {
            cancel: {
              label: I18n.t('btn.close'),
              className: "btn-outline-secondary",
            },
            import: {
              label: I18n.t('btn.import'),
              className: "btn-success",
              callback: function() {
                let table = document.querySelector('#hci_articles');
                let articles = table.querySelectorAll('input:checked');
                let productNumber = document.querySelector('#hci_product_number');
                if(articles.length > 0) {
                  var fd = new FormData();
                  fd.append("product_number", productNumber.value);
                  articles.forEach((article, i) => {
                    fd.append("articles[]", article.value);
                  });

                  Rails.ajax({
                    url: `/${that.locale}/items/module/hci/import`,
                    type: "post",
                    data: fd,
                    success: function(data) {
                      business.showAlert('success', I18n.t('custom_modules.hci_solutions.notifications.items_background_import'), 3000);
                    },
                    error: function(data) {
                      business.showAlert('danger', I18n.t('custom_modules.hci_solutions.notifications.errors_items_background_import'), 3000);
                    }
                  });
                }
              }
            }
          }
        });
        $('#loading').fadeOut(200);
      },
      error: function(data) {
        $('#loading').fadeOut(200);
      }
    });
  }

  hciSearchShowResults(products) {
    let table = $("#items");
    table.bootstrapTable('load', products);
    table.bootstrapTable({
      data: products,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      searchTimeOut: 500,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
    });
  }

  initData() {
    let itemsController = this;
    let table = $("#items");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('item.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('item.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `${window.location.href}?limit=500`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 500,
      loadingFontSize: '1rem',
    });

    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }

      //Disable show/hide toggle for actions column
      let chks = document.querySelector('.fixed-table-toolbar').querySelectorAll('input[type=checkbox]');
      if(chks) {
        chks[chks.length-1].parentNode.classList.add('d-none');
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))

      Rails.ajax({
        url: `/${itemsController.locale}/subscription/config`,
        type: "post",
        data: `parameter=items_list_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });

    table.on('reset-view.bs.table', () => {
      $('[data-bs-toggle="popover"]').popover();
    });
  }

  initOrdersData() {
    let itemsController = this;
    let table = $("#orders");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('order.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('order.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `/${itemsController.locale}/item/${itemsController.uuid}/orders?limit=500`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchTimeOut: 500,
      loadingFontSize: '1rem',
    });

    table.on('load-success.bs.table', () => {
      if(table.bootstrapTable('getData').length >= 500) {
        this.loadNextOrderData(500);
      }

      //Disable show/hide toggle for actions column
      let chks = document.querySelector('.fixed-table-toolbar').querySelectorAll('input[type=checkbox]');
      if(chks) {
        if(chks[chks.length-1]) {
          chks[chks.length-1].parentNode.classList.add('d-none');
        }
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))

      Rails.ajax({
        url: `/${itemsController.locale}/subscription/config`,
        type: "post",
        data: `parameter=orders_list_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });

    table.on('reset-view.bs.table', () => {
      $('[data-bs-toggle="popover"]').popover();
    });
  }

  loadNextData(offset) {
    let table = $("#items");

    let itemsController = this;
    Rails.ajax({
      url: `${window.location.href}?limit=500&offset=${offset}`,
      type: "get",
      data: "",
      success: function(data) {
        table.bootstrapTable('append', data)
        if(data.length == 500) {
          itemsController.loadNextData(offset+500);
        }
      },
      error: function(data) {
        console.log("Error loading orders");
      }
    });
  }

  loadNextOrderData(offset) {
    let table = $("#orders");
    let itemsController = this;

    Rails.ajax({
      url: `/${itemsController.locale}/item/${itemsController.uuid}/orders?limit=500&offset=${offset}`,
      type: "get",
      data: "",
      success: function(data) {
        table.bootstrapTable('append', data)
        if(data.length == 500) {
          itemsController.loadNextOrderData(offset+500);
        }
      },
      error: function(data) {
        console.log("Error loading orders");
      }
    });
  }

  setPriceValidity() {
    let that = this;
    let tr = event.target.closest('tr')
    let output = tr.querySelector("td.price_validity output");
    var uuid = tr.querySelector("#price_uuid").value;
    let inp_price_valid_from = tr.querySelector("#price_valid_from");
    let inp_price_valid_until = tr.querySelector("#price_valid_until");
    var msg = `
      <div class="container">
        <div class="form-group mb-3">
          <label for="valid_from" class="form-label">${I18n.t('item.price_valid_from')}</label>
          <div class="input-group" id="picker_valid_from" data-td-target-input="nearest" data-td-target-toggle="nearest">
            <input id="valid_from" type="text" class="form-control datetimepicker-input" data-td-target="#picker_valid_from" readonly value="${inp_price_valid_from.value}" autocomplete="off">
            <span class="input-group-text" data-td-target="#picker_valid_from" data-td-toggle="datetimepicker">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
        <div class="form-group mb-3">
          <label for="valid_from" class="form-label">${I18n.t('item.price_valid_until')}</label>
          <div class="input-group" id="picker_valid_until" data-td-target-input="nearest" data-td-target-toggle="nearest">
            <input id="valid_until" type="text" class="form-control datetimepicker-input" data-td-target="#picker_valid_until" readonly value="${inp_price_valid_until.value}" autocomplete="off">
            <span class="input-group-text" data-td-target="#picker_valid_until" data-td-toggle="datetimepicker">
              <i class="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      </div>
    `;
    var box = bootbox.dialog({
      title: I18n.t('item.price_validity'),
      message: msg,
      onEscape: true,
      size: 'small',
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
          callback: function() {
            subscription1.unsubscribe();
            subscription2.unsubscribe();
          }
        },
        accept: {
          label: I18n.t('btn.apply'),
          className: "btn-success",
          callback: function() {
            output.innerHTML = ''
            if(picker1.dates._dates.length > 0) {
              inp_price_valid_from.value = moment(picker1.viewDate).format('YYYY-MM-DD');
              output.innerHTML = `${I18n.t('item.price_from')} ${I18n.l('date.formats.price_validity', moment(picker1.viewDate).toDate())} `
            } else {
              inp_price_valid_from.value = '';
            }
            if(picker2.dates._dates.length > 0) {
              inp_price_valid_until.value = moment(picker2.viewDate).format('YYYY-MM-DD');
              output.innerHTML += `${I18n.t('item.price_until').toLowerCase()} ${I18n.l('date.formats.price_validity', moment(picker2.viewDate).toDate())}`
            } else {
              inp_price_valid_until.value = '';
            }

            subscription1.unsubscribe();
            subscription2.unsubscribe();
    	      bootbox.hideAll();
          }
        }
      }
    });

    let inp1 = document.querySelector("#picker_valid_from");
    let inp2 = document.querySelector("#picker_valid_until");
    const picker1 = new TempusDominus(inp1, {
      localization: {
        locale: this.locale
      },
      useCurrent: false,
      defaultDate: undefined,
      allowInputToggle: true,
      display: {
        components: {
          date: true,
          decades: true,
          month: true,
          year: true,
          hours: false,
          seconds: false,
          minutes: false
        },
        buttons: {
          today: true,
          clear: true,
          close: true
        }
      }
    });
    const picker2 = new TempusDominus(inp2, {
      localization: {
        locale: this.locale
      },
      useCurrent: false,
      defaultDate: undefined,
      allowInputToggle: true,
      display: {
        components: {
          date: true,
          decades: true,
          month: true,
          year: true,
          hours: false,
          seconds: false,
          minutes: false
        },
        buttons: {
          today: true,
          clear: true,
          close: true
        }
      }
    });

    const subscription1 = picker1.subscribe(Namespace.events.change, (e) => {
      if(e.isClear && e.oldDate != undefined) {
        picker1.clear()
        picker1.toggle()
      }
      picker2.updateOptions({
        restrictions: {
          minDate: e.date,
        },
      });
    });
    const subscription2 = picker2.subscribe(Namespace.events.change, (e) => {
      if(e.isClear && e.oldDate != undefined) {
        picker2.clear()
        picker2.toggle()
      }
      picker1.updateOptions({
        restrictions: {
          maxDate: e.date,
        },
      });
    });
  }

  toggleInactivePrices() {
    if(event.target.checked) {
      this.pricesTarget.rows.forEach((row, i) => {
        row.classList.remove('d-none');
      });
    } else {
      this.pricesTarget.rows.forEach((row, i) => {
        if(row.classList.contains('inactive')) {
          row.classList.add('d-none');
        }
      });
    }
  }

  initTableFunctions() {
    let that = this;
    I18n.locale = this.locale;
    let locale = this.locale;
    let warehouses = this.warehouses;

    window.rowStyle = function(row, index) {
      return {
        classes: (row.statusname == 'no_longer_available' ? 'text-muted' : '')
      }
    }

    /*
      Warehouse
    */
    window.itemNameFormatter = function(name, item) {
      let link_class = (item.statusname == 'no_longer_available' ? 'text-warning-emphasis' : '')
      let str = `<a href="/${locale}/item/${item.uuid}" class="${link_class}">${name}</a>`
      if(item.statusname) {
        if(item.status_subscription_id) {
          str += `<span class="badge bg-light text-dark float-right small">${item.statusname}</span>`
        } else {
          str += `<span class="badge bg-light text-dark float-right small">${I18n.t(`item.states.${item.statusname}`)}</span>`
        }
      } else {
        str += `<span class="d-none">${I18n.t('item.states.no_state')}</span>`
      }
      return str
    }
    window.itemNumberFormatter = function(number, item) {
      let str = '';
      if(number) {
        str = number;
      }
      if(item.ean && item.ean != '') {
        str += `
          <button type="button" class="btn btn-oultine-info btn-sm" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="focus" data-bs-content="${I18n.t('item.ean_abbr')}: ${item.ean}">
            <i class="fas fa-barcode"></i>
          </button>
        `
      }
      return str
    }
    window.itemPriceFormatter = function(price, item) {
      if(item.price_with_discount) {
        return `
          <small class="strikethrough">
            ${that.currenciesFormat(item.price.toFixed(2), item.currency)}
          </small><br>
          <span class="text-danger">
            ${that.currenciesFormat(item.price_with_discount.toFixed(2), item.currency)}
          </span>
        `
      } else if(item.price) {
        return `
          ${that.currenciesFormat(item.price.toFixed(2), item.currency)}
        `
      }
    }
    window.itemPriceWitVatFormatter = function(price, item) {
      if(item.price_with_discount) {
        return `
          <small class="strikethrough">
            ${that.currenciesFormat((item.price*(1+(item.vat/100))).toFixed(2), item.currency)};
          </small><br>
          <span class="text-danger">
            ${that.currenciesFormat((item.price_with_discount*(1+(item.vat/100))).toFixed(2), item.currency)}
          </span>
        `
      } else if(item.price_with_vat) {
        return `
          ${that.currenciesFormat(item.price_with_vat.toFixed(2), item.currency)}
        `
      }
    }
    window.itemStockFormatter = function(stock, item) {
      if(stock == null) {
        stock = '-'
      }
      if(item.with_stock) {
        if(warehouses > 1) {
          return `
            <button type="button" class="btn btn-outline-light btn-sm text-dark" data-action="click->items#loadStockQuantities" data-uuid="${item.uuid}" data-loaded="false">
              ${stock}
            </button>
            <table class="small table-borderless table-sm"></table>
          `
        } else {
          return stock
        }
      } else {
        return ''
      }
    }
    window.itemReservedFormatter = function(reserved, item) {
      if(item.with_stock && item.reserved && item.reserved > 0) {
        return reserved
      } else {
        return ''
      }
    }
    window.itemAvailableFormatter = function(available, item) {
      if(item.with_stock) {
        return item.availability
      } else {
        return ''
      }
    }
    window.itemActionsFormatter = function(i, item) {
      return `
        <div class="btn-group" role="group">
          <a class="btn btn-outline-secondary" href="/${locale}/item/${item.uuid}/edit">
            <i class="fas fa-edit"></i>
          </a>
          <button type="button" class="btn btn-outline-danger" data-uuid="${item.uuid}" data-name="${item.i18n_name}" data-number="${item.number}" data-action="click->items#deleteConfirm">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      `
    }
    window.itemSeoActionsFormatter = function(i, item) {
      return `
        <div class="btn-group" role="group">
          <a class="btn btn-outline-secondary" href="/${locale}/item/${item.uuid}/edit">
            <i class="fas fa-edit"></i>
          </a>
        </div>
      `
    }
  }

  initOrdersTableFunctions() {
    let that = this;
    I18n.locale = this.locale;
    let locale = this.locale;
    let ordernext = I18n.t('order.states.progress');
    let itemsController = this;

    window.orderReferenceFormatter = function(reference, row) {
      let link = `/${locale}/order/${row.uuid}`;
      return `<a href="${link}">${reference}</a>`
    }

    window.orderDateFormatter = function(date) {
      return I18n.l("date.formats.default", date);
    }

    window.orderPaidFormatter = function(paid, row) {
      var icon = '';
      if(row.state == '50_delivered' || row.state == '60_completed') {
        if(paid) {
          icon = '<i class="far fa-check-square text-success"></i>';
        } else {
          icon = '<i class="fas fa-minus-square text-danger"></i>';
        }
      } else {
        if(paid) {
          icon = '<i class="far fa-check-square text-success"></i>';
        }
      }
      return icon
    }

    window.orderStateFormatter = function(state) {
      switch(state) {
        case '10_new':
          ordernext = I18n.t('order.states.progress');
          return `<span class="text-danger semi-bold">${I18n.t('order.states.new')}</span>`
          break;
        case '20_progress':
          ordernext = I18n.t('order.states.ready_for_shipment');
          return `<span class="text-primary semi-bold">${I18n.t('order.states.progress')}</span>`
          break;
        case '30_ready_shipment':
          ordernext = I18n.t('order.states.shipped');
          return `<span class="text-secondary semi-bold">${I18n.t('order.states.ready_for_shipment')}</span>`
          break;
        case '39_shipped_partial':
          ordernext = I18n.t('order.states.shipped');
          return `<span class="text-secondary semi-bold">${I18n.t('order.states.shipped_partial')}</span>`
          break;
        case '40_shipped':
          ordernext = I18n.t('order.states.delivered');
          return `<span class="text-secondary semi-bold">${I18n.t('order.states.shipped')}</span>`
          break;
        case '51_delivered_partial':
          ordernext = I18n.t('order.states.delivered');
          return `<span class="text-success semi-bold">${I18n.t('order.states.delivered_partial')}</span>`
          break;
        case '50_delivered':
          ordernext = I18n.t('order.states.completed');
          return `<span class="text-success semi-bold">${I18n.t('order.states.delivered')}</span>`
          break;
        case '60_completed':
          return `<span class="text-body semi-bold">${I18n.t('order.states.completed')}</span>`
          break;
        case '90_cancelled':
          return `<span class="text-black-50 semi-bold">${I18n.t('order.states.cancelled')}</span>`
          break;
      }
    }

    window.orderCustomerFormatter = function (company, row) {
      let link = `/${locale}/order/${row.uuid}`;
      var subject = '';
      if(row.subject && row.subject != '') {
        subject = `
          <small class="text-muted">
            <br>${row.subject}
          </small>
        `;
      }

      var info = '';
      if(row.data && row.data.fulfillment) {
        info = `
          <br>
          <small class="text-muted">
        `;
        if (row.data.fulfillment == 'fluehmann') {
          info += I18n.t('order.handled_by', {name: 'Flühmann'})

          if(row.state != '30_ready_shipment' && row.state != '40_shipped' && row.state != '50_delivered' && row.state != '60_completed') {
            if(!row.data.ext_api || !row.data.ext_api.fluehmann || !row.data.ext_api.fluehmann) {
              info += `<i class="fas fa-exclamation-triangle text-danger"></i>`
            }
          }

        } else if(row.data.fulfillment == 'evasioni') {
          info += I18n.t('order.handled_by', {name: 'Evasioni'})
        }
        info += `
          </small>
        `
      }
      return `<a href="${link}">${company}</a>${subject}${info}`
    }

    window.orderAddressFormatter = function (address, row) {
      let addr = [];

      if(address) {
        if(address.addressline1 && address.addressline1 != '') addr.push(address.addressline1)
        if(address.addressline2 && address.addressline2 != '') addr.push(address.addressline2)
        if(address.name && address.name != '') addr.push(address.name)
        if(address.street && address.street != '') addr.push(address.street)
        let str = `${address.zip} ${address.location}`.trim();
        if(str != '') addr.push(str)

        return `<address class="small order_shipping_address">${addr.join('<br>')}</address>`
      } else {
        return `<address class="small order_shipping_address"></address>`
      }
    }

    window.orderAmountFormatter = function (total, row) {
      if(row.total_with_vat) {
        return `
          <span class="smaller">
            ${that.currenciesFormat(total.toFixed(2), row.currency)}
            <br>
            ${that.currenciesFormat(row.total_with_vat.toFixed(2), row.currency)}
          </span>`
      } else {
        return `
          <span class="smaller">
            ${that.currenciesFormat('0.00', row.currency)}
            <br>
            ${that.currenciesFormat('0.00', row.currency)}
          </span>`
      }
    }

    window.orderCustomGalaxusFormatter = function (foo, order) {
      let icons = '';
      if(order.data.galaxus)
      {
        if(order.deliveries.length == 0) {
          icons = '<i class="fas fa-shipping-fast text-muted"></i>';
        } else {
          let count_notification = 0;
          for(let i = 0; i<order.deliveries.length; i++) {
            if(order.deliveries[i].data && order.deliveries[i].data.galaxus && order.deliveries[i].data.galaxus.dispatchId) {
              count_notification++;
            }
          }
          if(count_notification == order.deliveries.length) {
            icons = '<i class="fas fa-shipping-fast text-success"></i>'
          } else if(count_notification > 0) {
            icons = '<i class="fas fa-shipping-fast text-warning"></i>'
          } else {
            icons = '<i class="fas fa-shipping-fast text-danger"></i>'
          }
        }
        icons += '&nbsp;';
        if(order.data.galaxus.invoiceId) {
          icons += '<i class="fas fa-file-invoice text-success"></i>'
        } else {
          icons += '<i class="fas fa-file-invoice text-danger"></i>'
        }
      }

      return icons;
    }
  }

  selectItem() {
    var table = $('#itemSelect').bootstrapTable({});

    this.itemSelectDialog.show();
    let dialog = document.querySelector('#itemSelectDialog');

    if(this.page == 'categories') {
      dialog.dataset.target = 'category'
      dialog.dataset.uuid = event.target.dataset.categoryUuid;
    } else {
      dialog.dataset.target = event.target.dataset.target;
    }

    let btnSelectLink = dialog.querySelector("#btnSelectLink");
    let btnSelectVariant = dialog.querySelector("#btnSelectVariant");
    let btnSelectCategory = dialog.querySelector("#btnSelectCategory");
    if(event.target.dataset.target == 'links') {
      btnSelectLink.parentNode.classList.remove('d-none');
      btnSelectVariant.parentNode.classList.add('d-none');
    } else if(event.target.dataset.target == 'variants') {
      btnSelectVariant.parentNode.classList.remove('d-none');
      btnSelectLink.parentNode.classList.add('d-none');
    } else {
      btnSelectVariant.parentNode.classList.add('d-none');
      btnSelectLink.parentNode.classList.add('d-none');
      btnSelectCategory.classList.remove('d-none');
    }

    dialog.addEventListener('shown.bs.modal', function (event) {
      event.target.querySelector(".search-input").focus();
    })
  }

  addSelectedItems(target, items, type) {
    let table;
    if(target == 'links') {
      items.forEach((item, i) => {
        let row = this.linksTarget.insertRow();
        let td1 = row.insertCell(0);
        let td2 = row.insertCell(1);
        let td3 = row.insertCell(2);
        let td4 = row.insertCell(3);
        let td5 = row.insertCell(4);

        td1.innerHTML = `
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="${target}_${item.uuid}" data-action="click->items#toggleBulkAction">
                            <label class="form-check-label" for="${target}_${item.uuid}">${item.i18n_name}</label>
                          </div>
                        `;
        td2.innerHTML = `<label for="${target}_${item.uuid}">${item.number}</label>`;
        td3.innerHTML = `<label for="${target}_${item.uuid}">${item.ean}</label>`;
        td4.innerHTML = `
                          <select id="item_links" name="links[][link]" class="form-select form-select-sm">
                            <option value="accessory">${I18n.t('item.accessory')}</option>
                            <option value="accessory_of">${I18n.t('item.accessory_of')}</option>
                            <option value="similar">${I18n.t('item.similar')}</option>
                            <option value="followup_model">${I18n.t('item.followup_model')}</option>
                            <option value="component">${I18n.t('item.component')}</option>
                            <option value="other">${I18n.t('item.other')}</option>
                          </select>
                          <input id="item_links" name="links[][uuid]" type="hidden" value="${item.uuid}">
                        `;
        td5.innerHTML = `
                          <button type="button" class="btn btn-outline-danger btn-sm" data-action="click->items#removeItemsToLinksVariants">
                            <i class="fas fa-trash"></i>
                          </button>
                        `;

        if(type) {
          let sel = td4.querySelector('select');
          sel.value = type;
        }
      });
    } else {
      items.forEach((item, i) => {
        let row = this.variantsTarget.insertRow();
        let td1 = row.insertCell(0);
        let td2 = row.insertCell(1);
        let td3 = row.insertCell(2);
        let td4 = row.insertCell(3);
        let td5 = row.insertCell(4);



        td1.innerHTML = `
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="${target}_${item.uuid}" data-action="click->items#toggleBulkAction">
                            <label class="form-check-label" for="${target}_${item.uuid}">${item.i18n_name}</label>
                          </div>
                        `;
        td2.innerHTML = `<label for="${target}_${item.uuid}">${item.number}</label>`;
        td3.innerHTML = `<label for="${target}_${item.uuid}">${item.ean}</label>`;
        td4.innerHTML = `
                          <select id="item_variants" name="variants[][variant]" class="form-select form-select-sm">
                            <option value="color">${I18n.t('item.color')}</option>
                            <option value="size">${I18n.t('item.size')}</option>
                            <option value="material">${I18n.t('item.material')}</option>
                            <option value="packaging_unit">${I18n.t('item.packaging_unit')}</option>
                            <option value="packaging_content">${I18n.t('item.packaging_content')}</option>
                          </select>
                          <input id="item_variants" name="variants[][uuid]" type="hidden" value="${item.uuid}">
                        `;
        td5.innerHTML = `
                          <button type="button" class="btn btn-outline-danger btn-sm" data-action="click->items#removeItemsToLinksVariants">
                            <i class="fas fa-trash"></i>
                          </button>
                        `;

        if(type) {
          let sel = td4.querySelector('select');
          sel.value = type;
        }
      });
    }
  }

  toggleBulkAction() {
    let table = event.target.closest('table');
    let chks = table.querySelectorAll("input[type='checkbox']:checked");
    if(table.id == 'item_links') {
      let btn = document.querySelector("#dropdownItemLinks");
      if(chks.length > 0) {
        btn.disabled = false;
      }
    } else {
      let btn = document.querySelector("#dropdownItemVariants");
      if(chks.length > 0) {
        btn.disabled = false;
      }
    }
  }

  setBulkAction() {
    if(event.target.dataset.link) {
      this.linksTarget.rows.forEach((row, i) => {
        let chk = row.querySelector("input[type='checkbox']");
        if(chk) {
          if(chk.checked) {
            let select = row.querySelector("select");
            select.value = event.target.dataset.link;
            chk.checked = false;
          }
        }
      });
    } else {
      this.variantsTarget.rows.forEach((row, i) => {
        let chk = row.querySelector("input[type='checkbox']");
        if(chk) {
          if(chk.checked) {
            let select = row.querySelector("select");
            select.value = event.target.dataset.variant;
            chk.checked = false;
          }
        }
      });
    }
  }

  addItemsToCategory() {
    let that = this;
    var table = $('#itemSelect').bootstrapTable({});
    let dialog = document.querySelector('#itemSelectDialog');
    let btnSelect = event.target;
    btnSelect.disabled = true;
    let icon = document.createElement("i");
    icon.classList.add("fas", "fa-spinner", "fa-pulse");
    btnSelect.prepend(icon);

    setTimeout(() => {
      let items = table.bootstrapTable('getSelections');
      if(items.length > 0) {

        let json = {
          category: dialog.dataset.uuid,
          items: table.bootstrapTable('getSelections').map(function (it) {
            return it.uuid
          })
        }

        var fd = new FormData();
        fd.append("category", dialog.dataset.uuid);
        fd.append("items", table.bootstrapTable('getSelections').map(function (it) {
          return it.uuid
        }));

        Rails.ajax({
          url: `/${that.locale}/items/category/items`,
          type: "post",
          data: fd,
          success: function(data) {
            let tr = that.categoriesTarget.querySelector(`tr[data-uuid='${dialog.dataset.uuid}']`);
            let btn = tr.cells[1].querySelector('button');
            btn.textContent = `${I18n.t('item.item')}: ${data.length}`;

            let items_tr = that.categoriesTarget.querySelector(`tr[data-category-items='${dialog.dataset.uuid}']`);
            if(items_tr) {
              items_tr.remove()
              that.categoryLoadItemsData(dialog.dataset.uuid, data)
            }
          },
          error: function(data) {}
        });

      }
    }, 50);

    setTimeout(() => {
      that.itemSelectDialog.hide();
      let icon2 = btnSelect.querySelector('svg');
      btnSelect.removeChild(icon2);
      btnSelect.disabled = false;

      dialog.querySelectorAll("button[data-loaded='true']").forEach((btn, i) => {
        let tab = btn.parentNode.querySelector('table');
        tab.innerHTML = '';
        btn.dataset.loaded = 'false';
      });

      table.bootstrapTable('uncheckAll');
      table.bootstrapTable('refresh', {silent: true});
    }, 250)
  }

  addItemsToLinksVariants() {
    let itemsController = this;
    var table = $('#itemSelect').bootstrapTable({});
    let dialog = document.querySelector('#itemSelectDialog');
    let btnSelect = event.target;
    btnSelect.disabled = true;
    let icon = document.createElement("i");
    icon.classList.add("fas", "fa-spinner", "fa-pulse");
    btnSelect.prepend(icon);

    let type;
    if(event.target.dataset.link) {
      type = event.target.dataset.link;
    } else if(event.target.dataset.variant) {
      type = event.target.dataset.variant;
    }

    setTimeout(() => {
      let items = table.bootstrapTable('getSelections');
      if(items.length > 0) {
        itemsController.addSelectedItems(dialog.dataset.target, items, type);
      }
    }, 50);

    setTimeout(() => {
      itemsController.itemSelectDialog.hide();
      let icon2 = btnSelect.querySelector('svg');
      btnSelect.removeChild(icon2);
      btnSelect.disabled = false;

      dialog.querySelectorAll("button[data-loaded='true']").forEach((btn, i) => {
        let tab = btn.parentNode.querySelector('table');
        tab.innerHTML = '';
        btn.dataset.loaded = 'false';
      });

      table.bootstrapTable('uncheckAll');
      table.bootstrapTable('refresh', {silent: true});
    }, 250)
  }

  removeItemsToLinksVariants() {
    let row = event.target.closest('tr');
    row.remove();
  }

  initItemSelectDialog() {
    let itemsController = this;

    this.itemSelectDialog = new bootstrap.Modal(document.querySelector('#itemSelectDialog'), {
      keyboard: true,
      focus: true
    });

    let table = $('#itemSelect').bootstrapTable({
      locale: this.locale,
      url: `/${this.locale}/orders/items`,
      search: true,
      loadingFontSize: '1rem',
      classes: 'table',
      searchTimeOut: 500,
      clickToSelect: true,
      showColumns: true,
    });

    document.querySelector('#itemSelectDialog div.search').classList.add('w-75');
    document.querySelector('#itemSelectDialog input.search-input').style.height = '34px';

    itemsController.vat_select = '';
    Rails.ajax({
      url: `/${itemsController.locale}/orders/vats`,
      type: "get",
      data: "",
      success: function(data) {
        itemsController.vat = data;
      },
      error: function(data) {
      }
    });

    itemsController.warehouse_select = '';
    Rails.ajax({
      url: `/${itemsController.locale}/warehouses`,
      type: "get",
      data: "",
      success: function(data) {
        itemsController.warehouses = data;
        data.forEach((warehouse, i) => {
          itemsController.warehouse_select += `<option value="${warehouse.uuid}" style="color: ${warehouse.data.color}">${warehouse.name}</option>`
        });
      },
      error: function(data) {
        console.log("Not authorized")
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))

      Rails.ajax({
        url: `/${itemsController.locale}/subscription/config`,
        type: "post",
        data: `parameter=items_items_select_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });

    window.itemNameFormatter = function(name, item) {
      return `${name}&nbsp;&nbsp;<span class="small text-muted">${[item.i18n_size, item.i18n_color].filter(item => item).join(' | ')}</span>`
    }

    window.itemStockFormatter = function(stock, item) {
      if(stock == null) {
        stock = '-'
      }
      if(item.with_stock) {
        return `
          <button type="button" class="btn btn-outline-light btn-sm text-dark" data-action="click->items#loadStockQuantities" data-uuid="${item.uuid}" data-loaded="false">
            ${stock}
          </button>
          <table class="small table-borderless table-sm warehouse-quantities"></table>
        `
      } else {
        return ''
      }
    }

    window.itemStatusFormatter = function(status, item) {
      let str = '';
      if(item.statusname) {
        if(item.status_subscription_id) {
          str += item.statusname;
        } else {
          str += I18n.t(`item.states.${item.statusname}`);
        }
      }
      return str
    }

    window.itemPriceFormatter = function(price, item) {
      if(price && !isNaN(price)) {
        if(item.price_with_discount) {
          return `
            <span class="text-danger">${item.price_with_discount.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}</span>
            <br>
            <small class="text-muted">
              ${item.price_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            </small>
          `
        } else {
          return `
            ${price.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            <br>
            <small class="text-muted">
              ${item.price_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            </small>
          `
        }
      } else {
        return ''
      }
    }

    window.itemReservedFormatter = function(reserved, item) {
      if(item.with_stock && item.reserved && item.reserved > 0) {
        return reserved
      } else {
        return ''
      }
    }

    window.itemAvailabilityFormatter = function(available, item) {
      if(item.with_stock) {
        return item.availability
      } else {
        return ''
      }
    }
  }

  initHCITableFunctions() {
    let that = this;

    window.hciLookup = function(productNumber) {
      that.hciLookup(productNumber)
    }

    window.hciProductLookupFormatter = function(i, product) {
      return `
        <button class="btn btn-outline-secondary btn-sm" onclick="hciLookup('${product.productNumber}')">
          <i class="fa-solid fa-eye fa-fw"></i>
        </button>
      `
    }

    window.hciProductActionsFormatter = function(i, product) {
      return `

      `
    }
  }

}
