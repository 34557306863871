import { Controller } from "stimulus"
import { TempusDominus } from "@eonasdan/tempus-dominus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = [ "filter", "lblName", "inpName", "btnCancelItem", "contact", "purchasedAtAddress", "addressSelectDiv", "addressSelect", "contactAddress", "contactAddressSelectDiv", "contactAddressSelect", "attachments_counter", "attachment_uploading", "parts", "partsTemplate"]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
  }

  connect() {
    if(this.page == 'index') {
      this.initTableFunctions();
      this.initData();
    }

    if(this.page == 'item_new' || this.page == 'item_edit') {
      let that = this;

      var sp = $("#item_item").selectpicker({ width: '100%'});
      sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.itemSelected(e);
      });

      var sp_c1 = $("#item_purchased_at").selectpicker({ width: '100%'});
      sp_c1.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.contactSelected(e, 'purchased_at')
      });

      var sp_c1 = $("#item_contact").selectpicker({ width: '100%'});
      sp_c1.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.contactSelected(e, 'contact')
      });

      $(".selectpicker").selectpicker({ width: '100%'});

      new TempusDominus(document.getElementById('dtproduction'), {
        localization: {
          locale: this.locale
        },
        useCurrent: false,
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          }
        }
      });

      new TempusDominus(document.getElementById('dtexpiration'), {
        localization: {
          locale: this.locale
        },
        useCurrent: false,
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          }
        }
      });

      new TempusDominus(document.getElementById('dtwarrantyexp'), {
        localization: {
          locale: this.locale
        },
        useCurrent: false,
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          }
        }
      });

      if(this.hasAddressSelectTarget) {
        $(this.addressSelectTarget).selectpicker().on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
          that.setAlternateAddress(e.target.options[clickedIndex].dataset, 'purchased_at');
        });
      }
      if(this.hasContactAddressSelectTarget) {
        $(this.contactAddressSelectTarget).selectpicker().on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
          that.setAlternateAddress(e.target.options[clickedIndex].dataset, 'contact');
        });
      }

      if(this.page == 'item_edit') {
        $(this.partsTarget.querySelectorAll('select.sp')).selectpicker({width: '100%'});
      }
    }
  }

  filterItems() {

  }

  clearFilter() {

  }

  itemSelected(e) {
    let bs = document.querySelector('button[data-id="item_item"]').parentNode;
    let text = e.target.options[e.target.selectedIndex].textContent.trim();
    bs.classList.add('d-none');
    this.lblNameTarget.textContent = text;
    this.inpNameTarget.value = text;
    this.inpNameTarget.classList.remove('d-none');
    this.btnCancelItemTarget.classList.remove('d-none');
    e.target.classList.add('d-none');
  }

  clearItemSelected() {
    $("#item_item").selectpicker('val', '');
    let bs = document.querySelector('button[data-id="item_item"]').parentNode;
    event.target.classList.add('d-none');
    this.inpNameTarget.classList.add('d-none');
    bs.classList.remove('d-none');
    this.lblNameTarget.textContent = I18n.t('item.name');
    this.inpNameTarget.value = '';
  }

  updateUsecase() {
    if(['ownuse', 'onstock'].includes(event.target.value)) {
      this.contactTarget.classList.add('d-none');
    } else {
      this.contactTarget.classList.remove('d-none');
    }
  }

  deleteConfirm() {
    var that = this;
    var uuid = event.target.dataset.uuid;
    var msg = `<div class="lead mb-2">${event.target.dataset.name}</div>`;
    if(event.target.dataset.inventorynumber != '') {
      msg += `<div class="smaller">${I18n.t('inventory.inventorynumber')}: ${event.target.dataset.inventorynumber}</div>`;
    }
    if(event.target.dataset.serialnumber != '') {
      msg += `<div class="smaller">${I18n.t('inventory.serialnumber')}: ${event.target.dataset.serialnumber}</div>`;
    }
    bootbox.dialog({
      message: `${I18n.t('inventory.confirm.delete_item')}<br><br>${msg}<br>`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/inventory/item/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  contactSelected(e, target) {
    let uuid = e.target.value;
    let that = this;

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/contact/${uuid}.json`,
      type: "get",
      data: "",
      success: function(data) {
        that.setAddress(data, target);
      },
      error: function(data) {}
    });
  }

  setAddress(data, target) {
    let address_addressline1;
    let address_addressline2;
    let address_name;
    let address_street;
    let address_zip;
    let address_location;
    let address_state;
    let address_country;

    //Hidden fields
    if(target == 'purchased_at') {
      address_addressline1 = document.querySelector('#purchased_at_addressline1');
      address_addressline2 = document.querySelector('#purchased_at_addressline2');
      address_name = document.querySelector('#purchased_at_name');
      address_street = document.querySelector('#purchased_at_street');
      address_zip = document.querySelector('#purchased_at_zip');
      address_location = document.querySelector('#purchased_at_location');
      address_state = document.querySelector('#purchased_at_state');
      address_country = document.querySelector('#purchased_at_country');
    } else {
      address_addressline1 = document.querySelector('#contact_addressline1');
      address_addressline2 = document.querySelector('#contact_addressline2');
      address_name = document.querySelector('#contact_name');
      address_street = document.querySelector('#contact_street');
      address_zip = document.querySelector('#contact_zip');
      address_location = document.querySelector('#contact_location');
      address_state = document.querySelector('#contact_state');
      address_country = document.querySelector('#contact_country');
    }

    let addr = '';
    if(data.company.trim() != '') {
      address_name.value = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        address_name.value = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    if(data.addresses.length > 0) {

      //If addrelines are set, do not set the company name
      if((data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') || (data.addresses[0].addressline2 && data.addresses[0].addressline2 != '')) {
        if(data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') {
          addr += data.addresses[0].addressline1+"\n";
          address_addressline1.value = data.addresses[0].addressline1;
        } else {
          address_addressline1.value = '';
        }
        if(data.addresses[0].addressline2 && data.addresses[0].addressline2 != '') {
          addr += data.addresses[0].addressline2+"\n";
          address_addressline2.value = data.addresses[0].addressline2;
        } else {
          address_addressline2.value = '';
        }

        address_name.value = '';
      } else {
        addr += address_name.value+"\n";

        address_addressline1.value = '';
        address_addressline2.value = '';
      }

      addr += data.addresses[0].street+"\n";

      if(data.addresses[0].country && data.addresses[0].country != '') {
        addr += `${data.addresses[0].country}-${data.addresses[0].zip} ${data.addresses[0].location}`;
      } else {
        addr += `${data.addresses[0].zip} ${data.addresses[0].location}`;
      }

      address_street.value = data.addresses[0].street;
      address_zip.value = data.addresses[0].zip;
      address_location.value = data.addresses[0].location;

      if(data.addresses[0].state) {
        //addr += "\n"+data.addresses[0].state;
        address_state.value = data.addresses[0].state;
      } else {
        address_state.value = '';
      }
      if(data.addresses[0].country) {
        //addr += "\n"+countries.getName(data.addresses[0].country, this.locale);
        address_country.value = data.addresses[0].country;
      } else {
        address_country.value = '';
      }
    }

    if(target == 'purchased_at') {
      this.purchasedAtAddressTarget.value = addr;
    } else {
      this.contactAddressTarget.value = addr;
    }

    // Set alternate addresses if present
    this.addAlternateAddresses(data, target);
  }

  addAlternateAddresses(data, target) {

    let select;
    let div;
    if(target == 'purchased_at') {
      select = this.addressSelectTarget;
      div = this.addressSelectDivTarget;
    } else {
      select = this.contactAddressSelectTarget;
      div = this.contactAddressSelectDivTarget;
    }

    select.innerHTML = '';

    if(data.addresses.length > 1) {
      for(let i = 0; i < data.addresses.length; i++) {

        let str = [];

        //If addrelines are set, do not set the company name
        if((data.addresses[i].addressline1 && data.addresses[i].addressline1 != '') || (data.addresses[i].addressline2 && data.addresses[i].addressline2 != '')) {
          if(data.addresses[i].addressline1 && data.addresses[i].addressline1 != '') {
            str.push(data.addresses[i].addressline1);
          }
          if(data.addresses[i].addressline2 && data.addresses[i].addressline2 != '') {
            str.push(data.addresses[i].addressline2);
          }
        } else {
          if(data.company.trim() != '') {
            str.push(data.company);
          } else {
            if(data.firstname != '' || data.lastname != '') {
              str.push((`${data.firstname} ${data.lastname}`).trim());
            }
          }
        }

        str.push(data.addresses[i].street);
        str.push(data.addresses[i].zip);
        str.push(data.addresses[i].location);
        if(data.addresses[i].state) {
          str.push(data.addresses[i].state);
        }
        if(data.addresses[i].country) {
          str.push(countries.getName(data.addresses[i].country, this.locale));
        }

        let option = document.createElement("option");
        option.text = str.join(', ');
        option.dataset.subtext = data.addresses[i].label;
        this.setAddressDataFields(option, data, data.addresses[i]);
        select.appendChild(option);
      }

      $(select).selectpicker('refresh');
      div.classList.remove("d-none");
    } else {
      div.classList.add("d-none");
      $(select).selectpicker('refresh');
    }
  }

  setAlternateAddress(data, target) {
    let address_addressline1;
    let address_addressline2;
    let address_name;
    let address_street;
    let address_zip;
    let address_location;
    let address_state;
    let address_country;

    if(target == 'purchased_at') {
      address_addressline1 = document.querySelector('#purchased_at_addressline1');
      address_addressline2 = document.querySelector('#purchased_at_addressline2');
      address_name = document.querySelector('#purchased_at_name');
      address_street = document.querySelector('#purchased_at_street');
      address_zip = document.querySelector('#purchased_at_zip');
      address_location = document.querySelector('#purchased_at_location');
      address_state = document.querySelector('#purchased_at_state');
      address_country = document.querySelector('#purchased_at_country');
    } else {
      address_addressline1 = document.querySelector('#contact_addressline1');
      address_addressline2 = document.querySelector('#contact_addressline2');
      address_name = document.querySelector('#contact_name');
      address_street = document.querySelector('#contact_street');
      address_zip = document.querySelector('#contact_zip');
      address_location = document.querySelector('#contact_location');
      address_state = document.querySelector('#contact_state');
      address_country = document.querySelector('#contact_country');
    }


    address_addressline1.value = '';
    address_addressline2.value = '';
    address_name.value = '';
    address_street.value = '';
    address_zip.value = '';
    address_location.value = '';
    address_state.value = '';
    address_country.value = '';

    let str = [];

    if(data.addressline1) {
      address_addressline1.value = data.addressline1;
      str.push(data.addressline1);
    }
    if(data.addressline2) {
      address_addressline2.value = data.addressline2;
      str.push(data.addressline2);
    }
    if(data.name) {
      address_name.value = data.name;
      str.push(data.name);
    }

    address_street.value = data.street;
    address_zip.value = data.zip;
    address_location.value = data.location;

    str.push(data.street);

    if(data.country_code && data.country_code != '') {
      str.push(`${data.country_code}-${data.zip} ${data.location}`.trim());
    } else {
      str.push(`${data.zip} ${data.location}`.trim());
    }

    if(data.state) {
      //str.push(data.state);
      address_state.value = data.state;
    }
    if(data.country) {
      //str.push(data.country);
      address_country.value = data.country;
    }

    if(target == 'purchased_at') {
      this.purchasedAtAddressTarget.value = str.join('\n').trim();
    } else {
      this.contactAddressTarget.value = str.join('\n').trim();
    }

  }

  setAddressDataFields(target, data, address) {

    if(address.addressline1) {
      target.dataset.addressline1 = address.addressline1;
    }
    if(address.addressline2) {
      target.dataset.addressline2 = address.addressline2;
    }

    if(data.company.trim() != '') {
      target.dataset.name = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        target.dataset.name = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    target.dataset.street = address.street;
    target.dataset.zip = address.zip;
    target.dataset.location = address.location;
    target.dataset.state = address.state;
    if(address.country) {
      target.dataset.country = countries.getName(address.country, this.locale);
      target.dataset.country_code = address.country;
    }
  }

  editAddress() {

    let address_addressline1;
    let address_addressline2;
    let address_name;
    let address_street;
    let address_zip;
    let address_location;
    let address_state;
    let address_country;

    //Hidden fields
    if(event.target.id == 'data_purchased_at') {
      address_addressline1 = document.querySelector('#purchased_at_addressline1');
      address_addressline2 = document.querySelector('#purchased_at_addressline2');
      address_name = document.querySelector('#purchased_at_name');
      address_street = document.querySelector('#purchased_at_street');
      address_zip = document.querySelector('#purchased_at_zip');
      address_location = document.querySelector('#purchased_at_location');
      address_state = document.querySelector('#purchased_at_state');
      address_country = document.querySelector('#purchased_at_country');
    } else {
      address_addressline1 = document.querySelector('#contact_addressline1');
      address_addressline2 = document.querySelector('#contact_addressline2');
      address_name = document.querySelector('#contact_name');
      address_street = document.querySelector('#contact_street');
      address_zip = document.querySelector('#contact_zip');
      address_location = document.querySelector('#contact_location');
      address_state = document.querySelector('#contact_state');
      address_country = document.querySelector('#contact_country');
    }

    let str = `target=${event.target.id}`;
    if(address_addressline1.value != '') {
      str += `&addressline1=${address_addressline1.value}`
    }
    if(address_addressline2.value != '') {
      str += `&addressline2=${address_addressline2.value}`
    }
    if(address_name.value != '') {
      str += `&name=${address_name.value}`
    }
    str += `&street=${address_street.value}`
    str += `&zip=${address_zip.value}`
    str += `&city=${address_location.value}`
    str += `&state=${address_state.value}`
    str += `&country=${address_country.value}`

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/inventory/item/0/address/edit`,
      type: "get",
      data: str,
      success: function(data) {},
      error: function(data) {}
    });
  }

  selectAttachment() {
    var input = document.querySelector("#filepicker");
    input.click();
  }

  uploadAttachment() {
    let that = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var files = input.files.length;

    let counter = parseInt(this.attachments_counterTarget.dataset.counter);

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      that.attachment_uploadingTarget.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/inventory/item/attachment/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              counter += 1;
              that.attachments_counterTarget.innerText = counter;
              that.attachments_counterTarget.dataset.counter = counter;
              that.attachment_uploadingTarget.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  deleteAttachment() {
    var attachment = event.target.closest("div.d-flex");
    attachment.remove();
    this.attachments_counterTarget.dataset.counter = parseInt(this.attachments_counterTarget.dataset.counter - 1)
    this.attachments_counterTarget.innerText = this.attachments_counterTarget.dataset.counter;
  }

  addPart() {
    let tmpl = this.partsTemplateTarget.querySelector('div');

    let row = tmpl.cloneNode(true);
    let sel = row.querySelector('select.sp');
    $(sel).selectpicker({width: '100%'});
    this.partsTarget.append(row);
  }

  deletePart() {
    let div = event.target.closest('div.row');
    if(div) {
      div.remove();
    }
  }

  initData() {
    let that = this;
    let table = $("#items");
    let locale = this.locale;

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('item.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('item.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `${window.location.href}?limit=500`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 500,
      loadingFontSize: '1rem',
    });

    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }
      if(table.bootstrapTable('getData').length >= 500) {
        this.loadNextData(500);
      }

      //Disable show/hide toggle for actions column
      let chks = document.querySelector('.fixed-table-toolbar').querySelectorAll('input[type=checkbox]');
      if(chks) {
        chks[chks.length-1].parentNode.classList.add('d-none');
      }
    });

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))

      Rails.ajax({
        url: `/${that.locale}/subscription/config`,
        type: "post",
        data: `parameter=inventory_list_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });
  }

  initTableFunctions() {
    I18n.locale = this.locale;
    let locale = this.locale;

    window.nameFormatter = function(name, item) {
      let str = `<a href="/${locale}/inventory/item/${item.uuid}">${name}</a>`
      return str
    }
    window.statusFormatter = function(status, item) {
      let str = ''
      if(status == 'inuse') {
        str = `<span class="badge bg-primary">${I18n.t(`inventory.status.${status}`)}</span>`
      } else {
        str = `<span class="badge bg-secondary">${I18n.t(`inventory.status.${status}`)}</span>`
      }
      return str
    }
    window.usecaseFormatter = function(usecase, item) {
      let str = I18n.t(`inventory.usecases.${usecase}`);
      return str;
    }
    window.contactFormatter = function(contact, item) {
      let str = '';
      if(contact) {
        if(item.company) {
          str = `<a href="/${locale}/contact/${contact}">${item.company}</a>`
        } else {
          str = `<a href="/${locale}/contact/${contact}">${[item.firstname, item.lastname].join(' ')}</a>`
        }
      }
      return str;
    }
    window.actionsFormatter = function(i, item) {
      let attachments = '';
      if(item.attachments.length > 0) {
        attachments = `<i class="fa-solid fa-paperclip text-muted me-2"></i>`
      }
      return `
        ${attachments}
        <div class="btn-group" role="group">
          <a class="btn btn-outline-secondary" href="/${locale}/inventory/item/${item.uuid}/edit">
            <i class="fas fa-edit"></i>
          </a>
          <button type="button" class="btn btn-outline-danger" data-uuid="${item.uuid}" data-name="${item.name}" data-inventorynumber="${item.inventorynumber}" data-serialnumber="${item.serialnumber}" data-action="click->inventory#deleteConfirm">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      `
    }
  }

}
