import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "filter", "total_with_vat", "cart", "vats", "customer", "clearCustomer"]

  connect() {
    let checkoutController = this;

    var sp = $(".selectpicker").selectpicker({ width: '100%' });
    sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      checkoutController.setCustomer(e.target.value);
      checkoutController.clearCustomerTarget.classList.remove('d-none');
    });

    if(this.customerTarget.value) {
      checkoutController.setCustomer(this.customerTarget.value);
    }

    this.loadCart();
    $('#loading').fadeOut(200);
  }

  filterItems() {
    var table = document.querySelector("#items");
    var filter = event.target.value.toLowerCase();
    for (var i = 0, cell; cell = table.rows[i]; i++) {
      if(!table.rows[i].dataset.name) {
        continue;
      }
      if(!table.rows[i].dataset.name.includes(filter) && !table.rows[i].dataset.number.includes(filter)) {
        table.rows[i].classList.add("d-none");
      } else {
        table.rows[i].classList.remove("d-none");
      }
    }
  }

  checkQuantity() {
    if(event.code == "Enter") {
      this.addToCart();
    }
  }

  showVat(data) {
    this.vatsTarget.innerHTML = '';
    for(let vat in data.vats) {
      this.vatsTarget.innerHTML += `<li class="list-group-item pl-1 pr-1 d-flex justiy-content-between"> \
        <div class="flex-fill">VAT ${vat} %</div> \
          <span class="float-right text-right">${data.vats[vat].sum.toFixed(2)} €</span> \
          <span class="float-right text-right w-25">${data.vats[vat].vat.toFixed(2)} €</span> \
        </li>`;
    }
  }

  showCart(data) {
    this.cartTarget.innerHTML = '';
    for(let i = 0; i < data.items.length; i++) {
      this.cartTarget.innerHTML += `<li class="list-group-item pl-1 pr-1 d-flex justiy-content-between align-items-center flex-wrap"> \
        <div class="flex-fill">${data.items[i].item}</div> \
        <span class="float-right text-right">${data.items[i].quantity} x ${data.items[i].price_with_vat.toFixed(2)}&nbsp;€</span> \
        <span class="float-right text-right w-25">${data.items[i].total.toFixed(2)}&nbsp;€</span> \
        <div class="float-right text-right vat">${data.items[i].vat}&nbsp;%</div> \
        <button class="btn btn-outline-danger btn-sm ml-3" data-uuid="${data.items[i].uuid}" data-action="click->checkout#removeFromCart"><i class="fas fa-trash"></i></button> \
        </li>`;
    }
  }

  loadCart() {
    let checkoutController = this;

    Rails.ajax({
      url: "/checkout/get",
      type: "GET",
      data: '',
      success: function(data) {
        checkoutController.total_with_vatTarget.innerHTML = data.total_with_vat.toFixed(2);

        checkoutController.showVat(data);
        checkoutController.showCart(data);
        checkoutController.filterTarget.focus();
      },
      error: function(data) {}
    });
  }

  addToCart() {
    let checkoutController = this;
    let row = event.target.closest('tr');
    var uuid = row.dataset.uuid;
    var item = row.dataset.item;

    var quantity = row.querySelector("#item_quantity");
    if(quantity.value ==  '') {
      quantity.focus();
      return;
    }

    Rails.ajax({
      url: "/checkout/add",
      type: "POST",
      data: `uuid=${uuid}&item=${item}&quantity=${quantity.value}`,
      success: function(data) {
        checkoutController.total_with_vatTarget.innerHTML = data.total_with_vat.toFixed(2);

        checkoutController.showVat(data);
        checkoutController.showCart(data);
        quantity.value = '';
        checkoutController.filterTarget.focus();
      },
      error: function(data) {}
    });
  }

  removeFromCart() {
    let checkoutController = this;
    var uuid = event.target.dataset.uuid;
    Rails.ajax({
      url: `/checkout/remove/${uuid}`,
      type: "POST",
      data: '',
      success: function(data) {
        checkoutController.total_with_vatTarget.innerHTML = data.total_with_vat.toFixed(2);

        checkoutController.showVat(data);
        checkoutController.showCart(data);
        checkoutController.filterTarget.focus();
      },
      error: function(data) {}
    });
  }

  clearCart() {
    let checkoutController = this;

    var str = document.querySelector("#config");
    bootbox.dialog({
      message: `<span class="lead">${str.dataset.clear_confirm}</span>`,
      onEscape: true,
      buttons: {
        cancel: {
          label: str.dataset.btn_cancel,
          className: "btn-outline-secondary",
        },
        clear: {
          label: str.dataset.btn_clear,
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: "/checkout/clear",
              type: "POST",
              data: '',
              success: function(data) {
                checkoutController.total_with_vatTarget.innerHTML = '0.00';
                checkoutController.cartTarget.innerHTML = '';
                checkoutController.filterTarget.focus();
                checkoutController.customerTarget.value = ''
              },
              error: function(data) {}
            });
    	      bootbox.hideAll();
          }
        }
      }
    });
  }

  setCustomer(uuid) {
    let checkoutController = this;
    let table = document.querySelector("#items");

    for (var i = 0, cell; cell = table.rows[i]; i++) {
      delete table.rows[i].dataset.customer;
      delete table.rows[i].dataset.customer_price;
      delete table.rows[i].dataset.customer_price_with_vat;
      delete table.rows[i].dataset.customer_vat;
      table.rows[i].querySelector(".price").classList.remove("semi-bold");
      table.rows[i].querySelector(".price").innerHTML = table.rows[i].dataset.price_with_vat;
    }

    Rails.ajax({
      url: `/checkout/customer`,
      type: "POST",
      data: `customer=${uuid}`,
      success: function(data) {
        for(let i = 0; i < data.length; i++) {
          let item = table.querySelector(`[data-uuid="${data[i].uuid}"]`)
          if(item) {
            if(!item.dataset.customer || data[i].contact == checkoutController.customerTarget.value) {
              item.dataset.customer = data[i].contact;
              item.dataset.customer_price = data[i].price;
              item.dataset.customer_price_with_vat = data[i].price_with_vat;
              item.dataset.customer_vat = data[i].vat
              item.querySelector(".price").innerHTML = data[i].price_with_vat.toFixed(2);
              item.querySelector(".price").classList.add("semi-bold");
              item.classList.add('alert-secondary');
            }
          }
        }
      },
      error: function(data) {}
    });
  }

  clearCustomer() {
    Rails.ajax({
      url: "/checkout/customer",
      type: "DELETE",
      data: '',
      success: function(data) {
        location.reload();
      },
      error: function(data) {}
    });
  }

}
