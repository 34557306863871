import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "filter", "total_with_vat", "cart", "vats", "customer", "clearCustomer", "change"]
  static store = '';

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-currency")) {
      this.currency = this.element.getAttribute("data-currency");
      this.currency_abbr = I18n.t(`currencies.abbr.${this.currency}`);
    }
  }

  connect() {
    this.store = this.data.get('uuid');

    let storesController = this;
    I18n.locale = this.locale;

    var sp = $(".selectpicker").selectpicker({ width: '100%' });
    sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      storesController.setCustomer(e.target.value);
      storesController.clearCustomerTarget.classList.remove('d-none');
    });

    if(this.customerTarget.value) {
      storesController.setCustomer(this.customerTarget.value);
    }

    this.loadCart();
    $('#loading').fadeOut(200);
  }

  filterItems() {
    var table = document.querySelector("#items");

    var filter = event.target.value.toLowerCase();
    for (var i = 0, cell; cell = table.rows[i]; i++) {
      if(!table.rows[i].dataset.name.includes(filter) && !table.rows[i].dataset.number.includes(filter)) {
        table.rows[i].classList.add("d-none");
      } else {
        table.rows[i].classList.remove("d-none");
      }
    }
  }

  filterEnter() {
    var table = document.querySelector("#items");

    if(event.code == "Enter") {
      let items = table.querySelectorAll("tr:not(.d-none)")
      if(items.length == 1) {
        items[0].click();
      }
    }
  }

  checkQuantity() {
    if(event.code == "Enter") {
      this.addToCart();
    }
  }

  showVat(data) {
    this.vatsTarget.innerHTML = '';
    for(let vat in data.vats) {
      this.vatsTarget.innerHTML += `<li class="list-group-item pl-1 pr-1 d-flex justiy-content-between"> \
        <div class="flex-fill">VAT ${vat} %</div> \
          <span class="float-right text-end">${data.vats[vat].sum.toFixed(2)} ${this.currency_abbr}</span> \
          <span class="float-right text-end w-25">${data.vats[vat].vat.toFixed(2)} ${this.currency_abbr}</span> \
        </li>`;
    }
  }

  showCart(data) {
    this.cartTarget.innerHTML = '';

    if(data.items.length == 0) {
      this.cartTarget.innerHTML += `<tr><td class="text-muted pt-3" colspan=5><i class="fas fa-shopping-cart fa-lg me-2"></i>${I18n.t('store.cart_empty')}</td></tr>`;
      return;
    }

    for(let i = 0; i < data.items.length; i++) {
      let price_with_vat = data.items[i].price_with_vat;
      let percentage_selected = "";
      let classes_price = "";
      let classes_discounted_price = "d-none";
      if(data.items[i].discount) {
        price_with_vat = data.items[i].price*(data.items[i].vat/100+1);
        if(data.items[i].discount_type == 'percentage') {
          percentage_selected = "selected";
        } else {
          data.items[i].discount = data.items[i].discount*(data.items[i].vat/100+1);
        }
        classes_price = "strikethrough";
        classes_discounted_price = "";
      }
      this.cartTarget.innerHTML += `
        <tr>
          <td colspan="7" class="pb-1 pt-2">${data.items[i].item}</td>
        </tr><tr class="border-bottom pb-2 align-middle"
            data-pos="${i}"
            data-uuid="${data.items[i].uuid}"
            data-price="${price_with_vat.toFixed(2)}"
          >
          <td class="pt-1">
            <input id="quantity" class="form-control form-control-sm text-end" min="0" type="number" data-action="blur->stores#calcPrice" value="${data.items[i].quantity}" style="width: 80px;">
          </td>
          <td class="text-end">
            <div class="input-group input-group-sm ms-auto me-2" style="width: 120px;">
              <input id="price_with_vat" class="form-control text-end" type="number" value="${price_with_vat.toFixed(2)}" data-action="blur->stores#calcPrice">
              <span class="input-group-text bg-white">${this.currency_abbr}</span>
            </div>
          </td>
          <td class="pt-1">
            <div class="input-group input-group-sm rounded-top">
              <span class="input-group-text bg-white border-0">
                <i class="fas fa-percent"></i>
              </span>
              <input id="discount" class="form-control text-end" min="0" type="number" data-action="blur->stores#calcPrice" value="${data.items[i].discount}" style="width: 80px;">
              <select class="form-control form-control-sm input-group-append" data-action="change->stores#calcPrice" style="width:55px;max-width:65px;">
                <option value="fix"> ${this.currency_abbr} </option>
                <option value="percentage" ${percentage_selected}> % </option>
              </select>
            </div>
          </td>
          <td class="text-end">
            <span id="price" class="${classes_price}">
              <span>${price_with_vat.toFixed(2)}</span>
              <span>&nbsp;${this.currency_abbr}</span>
            </span>
            <span id="discounted_price" class="text-danger ms-4 ${classes_discounted_price}">
              <span>${data.items[i].price_with_vat.toFixed(2)}</span>
              <span>&nbsp;${this.currency_abbr}</span>
            </span>
          </td>
          <td class="text-end">
            <span id="total" class="semi-bold">
              <span>${data.items[i].total_with_vat.toFixed(2)}</span>
              <span>&nbsp;${this.currency_abbr}</span>
            </span>
          </td>
          <td>
            <div class="text-end vat me-0">${data.items[i].vat}&nbsp;%</div>
          </td>
          <td class="pt-1">
            <button class="btn btn-outline-danger btn-sm ms-3 me-0" data-pos="${i}" data-action="click->stores#removeFromCart"><i class="fas fa-trash"></i></button>
          </td>
        </tr>`;
    }
  }

  loadCart() {
    let storesController = this;

    Rails.ajax({
      url: `/${this.locale}/store/${this.store}/get`,
      type: "GET",
      data: '',
      success: function(data) {
        storesController.total_with_vatTarget.innerHTML = data.total_with_vat.toFixed(2);

        storesController.showVat(data);
        storesController.showCart(data);
        storesController.filterTarget.focus();
      },
      error: function(data) {}
    });
  }

  addToCart() {
    let storesController = this;
    let row = event.target.closest('tr');
    var item = row.dataset.item;

    var str = document.querySelector("#config");
    bootbox.prompt({
      title: I18n.t('store.quantity_add'),
      centerVertical: true,
      type: 'range',
      min: 1,
      value: 1,
      onShow: function(e) {
        e.target.querySelector(".form-control").classList.add('form-control-lg');
        e.target.querySelector("input").select();
      },
      callback: function(quantity) {
        if(quantity && quantity > 0) {
          Rails.ajax({
            url: `/${storesController.locale}/store/${storesController.store}/add`,
            type: "POST",
            data: `item=${item}&quantity=${quantity}`,
            success: function(data) {
              storesController.total_with_vatTarget.innerHTML = data.total_with_vat.toFixed(2);
              storesController.showVat(data);
              storesController.showCart(data);
              storesController.filterTarget.select();
              storesController.filterTarget.focus();
            },
            error: function(data) {}
          });
        }
      }
    });

  }

  removeFromCart() {
    let storesController = this;
    var pos = event.target.dataset.pos;
    Rails.ajax({
      url: `/${this.locale}/store/${this.store}/remove/${pos}`,
      type: "POST",
      data: '',
      success: function(data) {
        storesController.total_with_vatTarget.innerHTML = data.total_with_vat.toFixed(2);

        storesController.showVat(data);
        storesController.showCart(data);
        storesController.filterTarget.focus();
      },
      error: function(data) {}
    });
  }

  clearCart() {
    let storesController = this;

    bootbox.dialog({
      message: `<span class="lead">${I18n.t('store.confirm.clear_cart')}</span>`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        clear: {
          label: I18n.t('btn.clear'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${storesController.locale}/store/${storesController.store}/clear`,
              type: "POST",
              data: '',
              success: function(data) {
                storesController.total_with_vatTarget.innerHTML = '0.00';
                storesController.cartTarget.innerHTML = '';
                storesController.filterTarget.focus();
                storesController.customerTarget.value = '';
                storesController.vatsTarget.innerHTML = '';
                storesController.showCart(data);
              },
              error: function(data) {}
            });
    	      bootbox.hideAll();
          }
        }
      }
    });
  }

  setCustomer(uuid) {
    let storesController = this;
    let table = document.querySelector("#items");

    let items = table.querySelectorAll(`*[data-customer]`)
    if(items) {
      items.forEach((item, index) => {
        delete item.dataset.customer;
        delete item.dataset.customer_price;
        delete item.dataset.customer_price_with_vat;
        delete item.dataset.customer_vat;
        item.classList.remove("semi-bold");
        item.querySelector(".price").innerHTML = item.dataset.price_with_vat;
        item.classList.remove('alert-secondary');
      })
    }

    Rails.ajax({
      url: `/${this.locale}/store/${this.store}/customer`,
      type: "POST",
      data: `customer=${uuid}`,
      success: function(data) {
        for(let i = 0; i < data.length; i++) {
          let item = table.querySelector(`[data-item="${data[i].uuid}"]`)
          if(item) {
            if(!item.dataset.customer || data[i].contact == storesController.customerTarget.value) {
              item.dataset.customer = data[i].contact;
              item.dataset.customer_price = data[i].price;
              item.dataset.customer_price_with_vat = data[i].price_with_vat;
              item.dataset.customer_vat = data[i].vat
              item.querySelector(".price").innerHTML = data[i].price_with_vat.toFixed(2);
              item.querySelector(".price").classList.add("semi-bold");
              item.classList.add('alert-secondary');
            }
          }
        }
      },
      error: function(data) {}
    });
  }

  clearCustomer() {
    let storesController = this;
    this.clearCustomerTarget.classList.add('d-none');

    Rails.ajax({
      url: `/${this.locale}/store/${this.store}/customer`,
      type: "DELETE",
      data: '',
      success: function(data) {
        storesController.customerTarget.value = '';
        $(storesController.customerTarget).selectpicker('refresh');

        let table = document.querySelector("#items");

        let items = table.querySelectorAll(`*[data-customer]`)
        if(items) {
          items.forEach((item, index) => {
            delete item.dataset.customer;
            delete item.dataset.customer_price;
            delete item.dataset.customer_price_with_vat;
            delete item.dataset.customer_vat;
            item.querySelector(".price").classList.remove("semi-bold");
            item.querySelector(".price").innerHTML = item.dataset.price_with_vat;
            item.classList.remove('alert-secondary');
          })
        }
      },
      error: function(data) {}
    });
  }

  calcPrice() {
    let storesController = this;
    let itemstr = '';

    let tr = event.target.closest("tr");
    let quantity = tr.querySelector("input#quantity");
    let discount = tr.querySelector("input#discount");
    let price_with_vat = tr.querySelector("input#price_with_vat");
    let discount_type = tr.querySelector("select");
    let price = tr.querySelector("#price > span");
    let discounted_price = tr.querySelector("#discounted_price > span");
    let total = tr.querySelector("#total > span");

    price.innerText = parseFloat(price_with_vat.value).toFixed(2);

    if(quantity.value == '')
      return;

    itemstr = `pos=${tr.dataset.pos}
      &item=${tr.dataset.uuid}
      &quantity=${quantity.value}
      &price=${parseFloat(price_with_vat.value).toFixed(2)}`

    tr.dataset.quantity = quantity.value;

    if(discount.value != '') {
      if(discount_type.value == 'percentage') {
        discounted_price.innerText = (parseFloat(price.innerText) * (1-(parseFloat(discount.value) / 100))).toFixed(2);
      } else {
        discounted_price.innerText = (parseFloat(price.innerText) - parseFloat(discount.value)).toFixed(2);
      }

      price.parentNode.classList.add("strikethrough");
      discounted_price.parentNode.classList.remove("d-none");

      total.innerText = (parseFloat(discounted_price.innerText) * parseFloat(quantity.value)).toFixed(2);

      itemstr += `&discount=${discount.value}&discount_type=${discount_type.value}`;

      tr.dataset.discount = discount.value;
      tr.dataset.discount_type = discount_type.value;
    } else {
      price.parentNode.classList.remove("strikethrough");
      discounted_price.parentNode.classList.add("d-none");

      delete tr.dataset.discount;
      delete tr.dataset.discount_type;

      total.innerText = (parseFloat(parseFloat(price_with_vat.value).toFixed(2)) * parseFloat(quantity.value)).toFixed(2);
    }

    if(itemstr != '') {
      Rails.ajax({
        url: `/${this.locale}/store/${storesController.store}/update`,
        type: "POST",
        data: `${itemstr}`,
        success: function(data) {
          storesController.total_with_vatTarget.innerHTML = data.total_with_vat.toFixed(2);
          storesController.showVat(data);
        },
        error: function(data) {}
      });
    }
  }

}
