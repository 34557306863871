$( document ).on('turbolinks:load', function() {
  $('#sidebarCollapse').on('click', function () {
    toggleSidebar();
  });

  $('#overlay').on('click', function () {
    toggleSidebar();
  });

  $('div.dropdown-menu a[data-remote=true]').click(function () {
    $(this).closest('div').prev('button').dropdown('toggle');
    $(this).closest('div').prev('a').dropdown('toggle');
  });

  $(document).on("ajax:send", function(xhr) {
    $('#loading').fadeIn(200);
  });

  $(document).on("ajax:complete", function(xhr, status) {
    $('#loading').fadeOut(200);
  });

  let form = document.querySelector('form');
  if(form) {
    form.querySelectorAll('input, select').forEach((el, i) => {
      el.addEventListener('invalid', (e) => {
        showInactiveInput(e.target);
      });
    });
  }
});

$(document).on("turbolinks:before-cache", function() {
  $('table[data-caching="false"]').bootstrapTable('destroy');
});

function showInactiveInput(inp) {
  let nav = inp.closest("div.tab-pane");
  if(nav) {
    const triggerEl = document.querySelector(`button[data-bs-target="#${nav.id}"]`);
    if(triggerEl) {
      triggerEl.click();
    }

    let navParent = nav.parentNode.closest("div.tab-pane");
    if(navParent) {
      const triggerParentEl = document.querySelector(`button[data-bs-target="#${navParent.id}"]`);
      if(triggerParentEl) {
        triggerParentEl.click();
      }
    }
  }
}

export function toggleSidebar() {
  $('#sidebar').toggleClass('active');
  $('#overlay').toggleClass('active');
}

export function showAlert(type, message, reload = false, delay = 15000) {
  var content = document.querySelector("#content");
  var alert = document.createElement("div");

  alert.classList.add("alert", `alert-${type}`, "alert-dismissible", "fade", "show");
  alert.setAttribute("role", "alert");
  alert.innerHTML = `
    <i class='fa fa-info-circle fa-lg'></i> ${message}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  `;
  content.prepend(alert);

  if(reload) {
    delay = 3000;
  }
  setTimeout(() => {
    var a = bootstrap.Alert.getOrCreateInstance(alert);
    if(a) {
      a.close();
      if(reload) {
        location.reload();
      }
    }
  }, delay);
}

export function showToast(message) {
  var content = document.querySelector("#content");
  var toastDiv = document.createElement("div");
  toastDiv.classList.add('toast', 'bottom-0', 'start-50', 'translate-middle-x', 'mb-5', 'fixed-bottom', 'text-white', 'bg-secondary');
  toastDiv.setAttribute("role", "alert");
  toastDiv.innerHTML = `
    <div class="d-flex justify-content-center">
      <div class="toast-body">
        ${message}
      </div>
      <button type="button" class="btn-close me-2 m-auto btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  `;
  content.append(toastDiv);

  var toast = new bootstrap.Toast(toastDiv);
  toast.show();
}
