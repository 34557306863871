import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $('#sidebar a.nav-link').on('click', (e) => {
      let i = e.target.querySelector('i');
      if(i && !e.target.dataset.toggle) {
        i.classList.remove(i.classList[1])
        i.classList.add('fa-spinner', 'fa-spin')
      }
    })
  }
}
