import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = [ "filter", "lbl_private", "lbl_business", "col_company", "attachment_uploading"]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String,
    fulfillmentProviders: Array
  }

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-group")) {
      this.group = this.element.getAttribute("data-group");
    }

    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }

    if(this.element.getAttribute("data-contact")) {
      this.contact = this.element.getAttribute("data-contact");
    }
  }

  connect() {
    if(this.page == 'contacts' || this.page == 'group') {
      this.initTableFunctions();
      this.initData();
    }

    if(this.page == 'show' || this.page == 'new' || this.page == 'edit') {
      this.enableSorting();
    }

    $(".selectpicker").selectpicker({ width: '100%' });

    //Prepare functions for orders
    this.initTableFunctions();

    let path = window.location.pathname.split('/');
    switch(path[path.length - 1]) {
      case 'orders':
        this.loadOrders();
        var tabEl = document.querySelector('#orders-tab')
        var tab = new bootstrap.Tab(tabEl)
        tab.show()

        break;
      case 'statistics':
        var tabEl = document.querySelector('#statistics-tab')
        var tab = new bootstrap.Tab(tabEl)
        tab.show()

        break;
      case 'attachments':
        var tabEl = document.querySelector('#attachments-tab')
        var tab = new bootstrap.Tab(tabEl)
        tab.show()

        break;
      default:
    }

    let filter;
    if(this.group) {
      filter = Cookies.get(`filter_group_${this.group}`);
    } else {
      filter = Cookies.get('filter_contacts');
    }

    if(filter && this.hasFilterTarget) {
      this.filterTarget.value = filter;
      this.filterContacts();
    }

    $('#loading').fadeOut(200);
  }

  filterContacts() {
    if(this.group) {
      Cookies.set(`filter_group_${this.group}`, this.filterTarget.value.toLowerCase());
    } else {
      Cookies.set('filter_contacts', this.filterTarget.value.toLowerCase());
    }
  }

  clearFilter() {
    if(this.group) {
      Cookies.expire(`filter_group_${this.group}`);
    } else {
      Cookies.expire('filter_contacts');
    }

    $("#contacts").bootstrapTable('resetSearch', '');
    this.filterTarget.focus();
    this.filterTarget.blur();
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  loadOrders() {
    this.initOrdersData();
  }

  setRequiredFields() {
    if($("#contact_company").val() != '') {
      $("#contact_firstname").attr('required', false);
      $("#contact_lastname").attr('required', false);
      $("#contact_company").attr('required', true);
    }
    else if($("#contact_lastname").val() != '' && $("#contact_firstname").val() != '') {
      $("#contact_company").attr('required', false);
      $("#contact_firstname").attr('required', false);
      $("#contact_lastname").attr('required', true);
    }
  }

  addPerson() {
    Rails.ajax({
      url: `/${this.locale}/contact/person/add`,
      type: "get",
      data: "",
      success: function(data) {
        $(".selectpicker").selectpicker({ width: '100%' });
      },
      error: function(data) {}
    });
  }

  addPhone() {
    Rails.ajax({
      url: `/${this.locale}/contact/phone/add`,
      type: "get",
      data: "",
      success: function(data) {
        $(".selectpicker").selectpicker({ width: '100%' });
      },
      error: function(data) {}
    });
  }

  deletePerson() {
    var row = event.target.closest("div.d-flex.person");
    row.remove();
  }

  deletePhone() {
    var row = event.target.closest("div.d-flex.phone");
    row.remove();
  }

  addEmail() {
    Rails.ajax({
      url: `/${this.locale}/contact/email/add`,
      type: "get",
      data: "",
      success: function(data) {
        $(".selectpicker").selectpicker({ width: '100%' });
      },
      error: function(data) {}
    });
  }

  deleteEmail() {
    var row = event.target.closest("div.d-flex.email");
    row.remove();
  }

  addAddress() {
    Rails.ajax({
      url: `/${this.locale}/contact/address/add`,
      type: "get",
      data: "",
      success: function(data) {
        $(".selectpicker-address").selectpicker({ width: '200px' });
      },
      error: function(data) {}
    });
  }

  deleteAddress() {
    var row = event.target.closest("div.d-flex.address");
    row.remove();
  }

  deleteConfirm() {
    var contactsController = this;
    var uuid = event.target.dataset.uuid;
    var msg;
    if(event.target.dataset.firstname != '' || event.target.dataset.lastname != '') {
      msg = `<span class="lead">${event.target.dataset.firstname} ${event.target.dataset.lastname}<br> ${event.target.dataset.company}</span>`;
    } else {
      msg = `<span class="lead">${event.target.dataset.company}</span>`;
    }
    bootbox.dialog({
      message: `${I18n.t('contact.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('contact.confirm.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${contactsController.locale}/contact/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
    	      bootbox.hideAll();
          }
        }
      }
    });
  }

  deleteConfirmGroup() {
    var contactsController = this;
    var uuid = event.target.dataset.uuid;
    var msg = `<span class="lead">${event.target.dataset.name}</span>`;
    bootbox.dialog({
      message: `${I18n.t('contact.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${contactsController.locale}/contacts/group/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            bootbox.hideAll();
          }
        }
      }
    });
  }

  enableSorting() {
    if(document.querySelector("#persons")) {
      new Sortable(persons, {
          handle: '.handle',
          animation: 150
      });
    }
    if(document.querySelector("#phones")) {
      new Sortable(phones, {
          handle: '.handle',
          animation: 150
      });
    }
    if(document.querySelector("#emails")) {
      new Sortable(emails, {
          handle: '.handle',
          animation: 150
      });
    }
    if(document.querySelector("#addresses")) {
      new Sortable(addresses, {
          handle: '.handle',
          animation: 150
      });
    }
  }

  togglePrivateBusiness() {
    if(event.target.value == 'true') {
      this.lbl_privateTarget.classList.add('d-none');
      this.lbl_businessTarget.classList.remove('d-none');
      this.col_companyTarget.classList.remove('d-none');
      $("#contact_company").attr('required', true);
    } else {
      this.col_companyTarget.classList.add('d-none');
      this.lbl_businessTarget.classList.add('d-none');
      this.lbl_privateTarget.classList.remove('d-none');
      $("#contact_company").attr('required', false);
    }
  }

  selectAttachment() {
    var div = event.target.closest("div#nav-attachments");
    var input = div.querySelector("#filepicker");
    input.click();
  }

  uploadAttachment() {
    let contactsController = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var files = document.querySelector("div#attachments");

    var files = input.files.length;
    var count = 0;

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      contactsController.attachment_uploadingTarget.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/contact/attachment/add/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              contactsController.attachment_uploadingTarget.classList.add('d-none');
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

  deleteAttachment() {
    var attachment = event.target.closest("div.d-flex");
    attachment.remove();
  }

  initData() {
    let contactsController = this;
    let table = $("#contacts");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('contact.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('contact.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `${window.location.href}`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 500,
      loadingFontSize: '1rem',
    });

    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }
    });

    table.on('reset-view.bs.table', () => {
      $('[data-toggle="popover"]').popover();
    });
  }

  initOrdersData() {
    let contactsController = this;
    let table = $("#orders");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('order.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('order.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `/${this.locale}/contact/${this.contact}/orders.json`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchTimeOut: 500,
      loadingFontSize: '1rem',
      sortName: 'orderref',
      sortOrder: 'desc'
    });

    table.on('load-success.bs.table', () => {
      //Disable show/hide toggle for actions column
      let chks = document.querySelector('.fixed-table-toolbar').querySelectorAll('input[type=checkbox]');
      if(chks) {
        chks[chks.length-1].parentNode.classList.add('d-none');
      }
    });
  }

  loadNextData(offset) {
    let table = $("#contacts");

    let contactsController = this;
    Rails.ajax({
      url: `${window.location.href}?limit=500&offset=${offset}`,
      type: "get",
      data: "",
      success: function(data) {
        table.bootstrapTable('append', data)
        if(data.length == 500) {
          contactsController.loadNextData(offset+500);
        }
      },
      error: function(data) {
        console.log("Error loading orders");
      }
    });
  }

  initTableFunctions() {
    let that = this;
    I18n.locale = this.locale;
    let locale = this.locale;
    let ordernext = I18n.t('order.states.progress');

    window.nameSorter = function(fieldA, fieldB) {
      if(fieldA) fieldA = fieldA.toLowerCase();
      if(fieldB) fieldB = fieldB.toLowerCase();
      if(fieldA < fieldB) return -1;
      if(fieldA > fieldB) return 1;
      return 0;
    }

    window.companySorter = function(fieldA, fieldB) {
      if(fieldA) fieldA = fieldA.toLowerCase();
      if(fieldB) fieldB = fieldB.toLowerCase();
      if(!fieldA) return -1;
      if(!fieldB) return 1;
      if(fieldA < fieldB) return -1;
      if(fieldA > fieldB) return 1;
      return 0;
    }

    window.nameFormatter = function(name, contact) {
      if(name && name != '') {
        return `<a href="/${locale}/contact/${contact.uuid}">${name}</a>`
      } else {
        return ''
      }
    }

    window.actionsFormatter = function(i, contact) {
      let firstname = contact.firstname ? contact.firstname : '';
      let lastname = contact.lastname ? contact.lastname : '';
      let company = contact.company ? contact.company : '';

      return `
        <div class="btn-group" role="group">
          <a class="btn btn-outline-secondary" href="/${locale}/contact/${contact.uuid}/edit">
            <i class="fas fa-edit"></i>
          </a>
          <button type="button" class="btn btn-outline-danger" data-uuid="${contact.uuid}" data-firstname="${ firstname}" data-lastname="${lastname}" data-company="${company}" data-action="click->contacts#deleteConfirm">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      `
    }

    window.orderReferenceFormatter = function(reference, row) {
      let link = `/${locale}/order/${row.uuid}`;
      return `<a href="${link}">${reference}</a>`
    }

    window.orderDateFormatter = function(date) {
      return I18n.l("date.formats.default", date);
    }

    window.orderPaidFormatter = function(paid, row) {
      var icon = '';
      if(row.state == '50_delivered' || row.state == '60_completed') {
        if(paid) {
          icon = '<i class="far fa-check-square text-success"></i>';
        } else {
          icon = '<i class="fas fa-minus-square text-danger"></i>';
        }
      } else {
        if(paid) {
          icon = '<i class="far fa-check-square text-success"></i>';
        }
      }
      return icon
    }

    window.orderStateFormatter = function(state) {
      switch(state) {
        case '10_new':
          ordernext = I18n.t('order.states.progress');
          return `<span class="text-danger semi-bold">${I18n.t('order.states.new')}</span>`
          break;
        case '20_progress':
          ordernext = I18n.t('order.states.ready_for_shipment');
          return `<span class="text-primary semi-bold">${I18n.t('order.states.progress')}</span>`
          break;
        case '30_ready_shipment':
          ordernext = I18n.t('order.states.shipped');
          return `<span class="text-secondary semi-bold">${I18n.t('order.states.ready_for_shipment')}</span>`
          break;
        case '40_shipped':
          ordernext = I18n.t('order.states.delivered');
          return `<span class="text-secondary semi-bold">${I18n.t('order.states.shipped')}</span>`
          break;
        case '51_delivered_partial':
          ordernext = I18n.t('order.states.delivered');
          return `<span class="text-success semi-bold">${I18n.t('order.states.delivered_partial')}</span>`
          break;
        case '50_delivered':
          ordernext = I18n.t('order.states.completed');
          return `<span class="text-success semi-bold">${I18n.t('order.states.delivered')}</span>`
          break;
        case '60_completed':
          return `<span class="text-body semi-bold">${I18n.t('order.states.completed')}</span>`
          break;
        case '90_cancelled':
          return `<span class="text-black-50 semi-bold">${I18n.t('order.states.cancelled')}</span>`
          break;
      }
    }

    window.orderCustomerFormatter = function (company, row) {
      let link = `/${locale}/order/${row.uuid}`;
      var subject = '';
      if(row.subject && row.subject != '') {
        subject = `
          <small class="text-muted">
            <br>${row.subject}
          </small>
        `;
      }

      var info = '';
      if(row.data && row.data.fulfillment && row.data.fulfillment != '') {

        let fpstr = '';
        let fp = that.fulfillmentProvidersValue.filter((fp) => {
          return fp.name == row.data.fulfillment;
        });
        if(fp.length > 0) {
          fpstr = I18n.t('order.handled_by', {name: fp[0].label});
        }

        info = `
          <br>
          <small class="text-muted">
            ${fpstr}
        `
        if(row.state != '30_ready_shipment' && row.state != '40_shipped' && row.state != '50_delivered' && row.state != '60_completed') {
          if(!row.data.ext_api || !row.data.ext_api.fluehmann || !row.data.ext_api.fluehmann) {
            info += `<i class="fas fa-exclamation-triangle text-danger"></i>`
          }
        }
        info += `
          </small>
        `
      }
      return `<a href="${link}">${company}</a>${subject}${info}`
    }

    window.orderAddressFormatter = function (address, row) {
      let addr = [];

      if(address) {
        if(address.addressline1 && address.addressline1 != '') addr.push(address.addressline1)
        if(address.addressline2 && address.addressline2 != '') addr.push(address.addressline2)
        if(address.name && address.name != '') addr.push(address.name)
        if(address.street && address.street != '') addr.push(address.street)
        let str = `${address.zip} ${address.location}`.trim();
        if(str != '') addr.push(str)

        return `<address class="small order_shipping_address">${addr.join('<br>')}</address>`
      } else {
        return `<address class="small order_shipping_address"></address>`
      }
    }

    window.orderAmountFormatter = function (total, row) {
      if(row.total_with_vat) {
        return `<span class="smaller">${that.currenciesFormat(total.toFixed(2), row.currency)}<br>${that.currenciesFormat(row.total_with_vat.toFixed(2), row.currency)}</span>`
      } else {
        return `<span class="smaller">${that.currenciesFormat('0.00', row.currency)}<br>${that.currenciesFormat('0.00', row.currency)}</span>`
      }

    }

    window.orderActionsFormatter = function(data, row) {
      let reference = '';
      if(row.reference) {
        reference = row.reference;
      }

      let html = `
        <div class="btn-group">
          <button type="button" class="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" data-bs-boundary="window" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-ellipsis-v"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end">
          `
      if(row.state == '50_delivered') {
        html += `
          <h6 class="dropdown-header">${I18n.t('order.state')}</h6>
          <a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/complete"><i class="fas fa-chevron-circle-right"></i> ${ordernext}</a>
        `
      }
      else if(row.state != '60_completed' && row.state != '90_cancelled') {
        html += `
          <h6 class="dropdown-header">${I18n.t('order.state')}</h6>
          <a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/next"><i class="fas fa-chevron-circle-right"></i> ${ordernext}</a>
        `
      }

      if(!row.paid) {
        html += `
          <div class="dropdown-divider"></div>
          <h6 class="dropdown-header">${I18n.t('order.payment')}</h6>
          <a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/paid"><i class="far fa-check-circle"></i> ${I18n.t('order.paid')}</a>
        `
      }

      if(row.data && row.data.fulfillment && row.data.fulfillment != '' && row.data.ext_api) {
        html += `
          <div class="dropdown-divider"></div>
          <h6 class="dropdown-header">${I18n.t('order.fulfillment_service')}</h6>
        `
        if(row.data.ext_api && row.data.ext_api.fluehmann && row.data.ext_api.fluehmann.orderId) {
          html += `
            <span class="dropdown-item">
              ${I18n.t('order.order')} ${row.data.ext_api.fluehmann.orderId}
            </span>
          `
        } else {
          `<a class="dropdown-item" data-remote="true" href="/${locale}/order/${row.uuid}/submit_fulfillment_order">${I18n.t('order.submit_fulfillment_order')}</a>`
        }
      }

      html += `
          </div>
        </div>
        <div class="btn-group" role="group">
          <a class="btn btn-outline-secondary" href="/${locale}/order/${row.uuid}/edit"><i class="fas fa-edit"></i></a>

          <button type="button" class="btn btn-outline-danger" data-uuid="${row.uuid}" data-orderref="${row.orderref}" data-customer="${row.company}" data-action="click->orders#deleteConfirm"><i class="fas fa-trash"></i></button>
        </div>
      `

      return html;
    }

  }
}
