import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {

  static targets = [ "customers_timestamp", "customers_state", "customers_count", "customers_progress", "categories_timestamp", "customers_timestamp", "categories_state", "categories_count", "categories_progress", "items_timestamp", "items_count", "items_state", "items_progress", "orders_timestamp", "orders_state", "orders_count", "orders_progress", "google_merchant_timestamp", "google_merchant_state", "google_merchant_count", "google_merchant_progress"]

  initialize() {
    if(this.element.getAttribute("data-webshop")) {
      this.webshop = this.element.getAttribute("data-webshop");
    }
  }

  connect() {
    let webshopController = this;

    consumer.subscriptions.create({ channel: "NotificationsChannel" }, {
      received(data) {
        webshopController.notify(data.message);
      },

      connected() {
        console.log("Connected to WebSocket");
      },

      disconnected() {
        console.log("Disconnected from WebSocket");
      }
    })

    $('#loading').fadeOut(200);
  }

  notify(msg) {
    if(msg.category == 'webshops' && this.webshop == msg.data.webshop) {
      switch(msg.message) {
        case 'sync_items':
          if(this.hasItems_timestampTarget) {
            if(msg.timestamp) {
              this.items_timestampTarget.innerHTML = msg.data.timestamp;
            }
          }
          if(this.hasItems_stateTarget) {
            if(msg.data.status == 'ok') {
              this.items_stateTarget.innerHTML = '<i class="far fa-check-circle text-success"></i>';
              this.items_progressTarget.style.width = "100%"
              this.items_progressTarget.innerText = "100%"
              this.items_progressTarget.classList.remove('progress-bar-animated');
              this.items_progressTarget.classList.remove('progress-bar-animated', 'progress-bar-striped');

              if(this.hasItems_countTarget) {
                this.items_countTarget.innerHTML = msg.data.items;
              }

            } else if(msg.data.status == 'synching') {
              this.items_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';
              this.items_progressTarget.parentNode.classList.remove('d-none');
              this.items_progressTarget.setAttribute("aria-valuenow", msg.data.items);
              this.items_progressTarget.setAttribute("aria-valuemax", msg.data.count);
              this.items_progressTarget.style.width = `${parseInt(100/msg.data.count*msg.data.items)}%`
              this.items_progressTarget.innerText = `${parseInt(100/msg.data.count*msg.data.items)}%`
            } else {
              this.items_stateTarget.innerHTML = '<i class="far fa-times-circle text-danger"></i>';
            }
          }
          break;
        case 'sync_categories':
          if(this.hasCategories_timestampTarget) {
            this.categories_timestampTarget.innerHTML = msg.data.timestamp;
          }
          if(this.hasCategories_stateTarget) {
            if(msg.data.status == 'ok') {
              this.categories_stateTarget.innerHTML = '<i class="far fa-check-circle text-success"></i>';
              this.categories_progressTarget.style.width = "100%"
              this.categories_progressTarget.innerText = "100%"
              this.categories_progressTarget.classList.remove('progress-bar-animated', 'progress-bar-striped');

              if(this.hasCategories_countTarget) {
                this.categories_countTarget.innerHTML = msg.data.categories;
              }
            } else if(msg.data.status == 'synching') {
              this.categories_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';
              this.categories_progressTarget.parentNode.classList.remove('d-none');
              this.categories_progressTarget.setAttribute("aria-valuenow", msg.data.items);
              this.categories_progressTarget.setAttribute("aria-valuemax", msg.data.count);
              this.categories_progressTarget.style.width = `${parseInt(100/msg.data.count*msg.data.items)}%`
              this.categories_progressTarget.innerText = `${parseInt(100/msg.data.count*msg.data.items)}%`
            } else {
              this.categories_stateTarget.innerHTML = '<i class="far fa-times-circle text-danger"></i>';
            }
          }
          break;
        case 'sync_orders':
          if(this.hasOrders_timestampTarget) {
            this.orders_timestampTarget.innerHTML = msg.data.timestamp;
          }
          if(this.hasOrders_stateTarget) {
            if(msg.data.status == 'ok') {
              this.orders_stateTarget.innerHTML = '<i class="far fa-check-circle text-success"></i>';
              this.orders_progressTarget.style.width = "100%"
              this.orders_progressTarget.innerText = "100%"
              this.orders_progressTarget.classList.remove('progress-bar-animated', 'progress-bar-striped');

              if(this.hasOrders_countTarget) {
                this.orders_countTarget.innerHTML = msg.data.orders;
              }
            } else if(msg.data.status == 'synching') {
              this.orders_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';
              this.orders_progressTarget.parentNode.classList.remove('d-none');
              this.orders_progressTarget.setAttribute("aria-valuenow", msg.data.items);
              this.orders_progressTarget.setAttribute("aria-valuemax", msg.data.count);
              this.orders_progressTarget.style.width = `${parseInt(100/msg.data.count*msg.data.items)}%`
              this.orders_progressTarget.innerText = `${parseInt(100/msg.data.count*msg.data.items)}%`
            } else {
              this.orders_stateTarget.innerHTML = '<i class="far fa-times-circle text-danger"></i>';
            }
          }
          break;
        case 'sync_customers':
          if(this.hasCustomers_timestampTarget) {
            this.customers_timestampTarget.innerHTML = msg.data.timestamp;
          }
          if(this.hasCustomers_stateTarget) {
            if(msg.data.status == 'ok') {
              this.customers_stateTarget.innerHTML = '<i class="far fa-check-circle text-success"></i>';
              this.customers_progressTarget.style.width = "100%"
              this.customers_progressTarget.innerText = "100%"
              this.customers_progressTarget.classList.remove('progress-bar-animated', 'progress-bar-striped');

              if(this.hasCustomers_countTarget) {
                this.customers_countTarget.innerHTML = msg.data.customers;
              }
            } else if(msg.data.status == 'synching') {
              this.customers_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';
              this.customers_progressTarget.parentNode.classList.remove('d-none');
              this.customers_progressTarget.setAttribute("aria-valuenow", msg.data.orders);
              this.customers_progressTarget.setAttribute("aria-valuemax", msg.data.count);
              this.customers_progressTarget.style.width = `${parseInt(100/msg.data.count*msg.data.orders)}%`
              this.customers_progressTarget.innerText = `${parseInt(100/msg.data.count*msg.data.orders)}%`
            } else {
              this.customers_stateTarget.innerHTML = '<i class="far fa-times-circle text-danger"></i>';
            }
          }

          break;
        case 'sync_google_merchant':
          if(this.hasGoogle_merchant_timestampTarget) {
            this.google_merchant_timestampTarget.innerHTML = msg.data.timestamp;
          }
          if(this.hasGoogle_merchant_stateTarget) {
            if(msg.data.status == 'ok') {
              this.google_merchant_stateTarget.innerHTML = '<i class="far fa-check-circle text-success"></i>';
              this.google_merchant_progressTarget.style.width = "100%"
              this.google_merchant_progressTarget.innerText = "100%"
              this.google_merchant_progressTarget.classList.remove('progress-bar-animated', 'progress-bar-striped');

              if(this.hasGoogle_merchant_countTarget) {
                this.google_merchant_countTarget.innerHTML = msg.data.count;
              }
            } else if(msg.data.status == 'synching') {
              this.google_merchant_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';
              this.google_merchant_progressTarget.parentNode.classList.remove('d-none');
              this.google_merchant_progressTarget.setAttribute("aria-valuenow", msg.data.items);
              this.google_merchant_progressTarget.setAttribute("aria-valuemax", msg.data.count);
              this.google_merchant_progressTarget.style.width = `${parseInt(100/msg.data.count*msg.data.items)}%`
              this.google_merchant_progressTarget.innerText = `${parseInt(100/msg.data.count*msg.data.items)}%`
            } else {
              this.google_merchant_stateTarget.innerHTML = '<i class="far fa-times-circle text-danger"></i>';
            }
          }
          break;
        default:
          // code block
      }
    }
  }

  startSync() {
    let sync = 'all';
    if(event.target.dataset.sync) {
      sync = event.target.dataset.sync;
    }
    if(this.hasCustomers_stateTarget && (sync == 'all' || sync == 'customers')) {
      this.customers_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';

      this.customers_progressTarget.style.width = `0%`
      this.customers_progressTarget.innerText = `0%`
      this.customers_progressTarget.setAttribute("aria-valuenow", 0);
      this.customers_progressTarget.setAttribute("aria-valuemax", 100)
      this.customers_progressTarget.classList.add('progress-bar-animated', 'progress-bar-striped');
      this.customers_progressTarget.parentNode.classList.remove('d-none');
    }
    if(this.hasCategories_stateTarget && (sync == 'all' || sync == 'categories')) {
      this.categories_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';

      this.categories_progressTarget.style.width = `0%`
      this.categories_progressTarget.innerText = `0%`
      this.categories_progressTarget.setAttribute("aria-valuenow", 0);
      this.categories_progressTarget.setAttribute("aria-valuemax", 100)
      this.categories_progressTarget.classList.add('progress-bar-animated', 'progress-bar-striped');
      this.categories_progressTarget.parentNode.classList.remove('d-none');
    }
    if(this.hasItems_stateTarget && (sync == 'all' || sync == 'items')) {
      this.items_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';

      this.items_progressTarget.style.width = `0%`
      this.items_progressTarget.innerText = `0%`
      this.items_progressTarget.setAttribute("aria-valuenow", 0);
      this.items_progressTarget.setAttribute("aria-valuemax", 100)
      this.items_progressTarget.classList.add('progress-bar-animated', 'progress-bar-striped');
      this.items_progressTarget.parentNode.classList.remove('d-none');
    }
    if(this.hasOrders_stateTarget && (sync == 'all' || sync == 'orders')) {
      this.orders_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';

      this.orders_progressTarget.style.width = `0%`
      this.orders_progressTarget.innerText = `0%`
      this.orders_progressTarget.setAttribute("aria-valuenow", 0);
      this.orders_progressTarget.setAttribute("aria-valuemax", 100)
      this.orders_progressTarget.classList.add('progress-bar-animated', 'progress-bar-striped');
      this.orders_progressTarget.parentNode.classList.remove('d-none');
    }
    if(this.hasGoogle_merchant_stateTarget && (sync == 'all' || sync == 'google_merchant')) {
      this.google_merchant_stateTarget.innerHTML = '<i class="fas fa-spinner fa-pulse text-info"></i>';

      this.google_merchant_progressTarget.style.width = `0%`
      this.google_merchant_progressTarget.innerText = `0%`
      this.google_merchant_progressTarget.setAttribute("aria-valuenow", 0);
      this.google_merchant_progressTarget.setAttribute("aria-valuemax", 100)
      this.google_merchant_progressTarget.classList.add('progress-bar-animated', 'progress-bar-striped');
      this.google_merchant_progressTarget.parentNode.classList.remove('d-none');
    }
  }
}
