import { Controller } from "stimulus"
import { Autocomplete } from "../src/javascripts/autocomplete.js"
import { TempusDominus } from "@eonasdan/tempus-dominus"
import { DirectUpload } from "@rails/activestorage"
import I18n from 'i18n-js'

export default class extends Controller {

  static targets = [ "filter", "document", "document_header", "currency", "locale", "quote_validity", "quote_validity_div", "currency_abbrvs", "position_number", "position_total", "position_total_with_vat", "position_vat", "toggleVAT", "td_price", "td_price_with_vat", "td_total", "td_total_with_vat", "td_discount", "positions", "vat_select_template", "vats_summary", "customer", "address", "total", "total_vat_sum", "total_eqs_sum", "total_with_vat", "total_vat", "total_eqs", "address_select", "address_select_div", "contact", "payment_conditions", "equivalence_surcharge", "retention", "total_retention", "payment_methods", "discount_price", "discount_discount", "discount_type", "discount_price_discounted", "numbering", "btnSubmit", "accounting_select_template", "public_link", "vies_label", "uploadingAttachment"]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String,
    documentUuid: String,
    country: String
  }

  initialize() {
    if(this.element.getAttribute("data-documents")) {
      this.documents = this.element.getAttribute("data-documents");
    }
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    } else {
      this.locale = document.querySelector("meta[name='locale']").getAttribute('content');
    }
    if(this.element.getAttribute("data-documents-locale")) {
      this.document_locale = this.element.getAttribute("data-documents-locale");
    }
    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
    if(this.element.getAttribute("data-copy")) {
      this.copy = this.element.getAttribute("data-copy");
    }

    Number.prototype.round = function(places) {
      return +(Math.round(this + "e+" + places)  + "e-" + places);
    }
  }

  connect() {
    let filter;
    if(this.documents) {
      filter = Cookies.get(`filter_documents_${this.documents}`);
    }

    if(filter && this.hasFilterTarget) {
      this.filterTarget.value = filter;
      this.filterDocuments();
    }

    if(this.documents) {
      this.initTableFunctions();
      this.initData();
    }

    if(this.page == 'new' || this.page == 'edit') {
      let that = this;
      let sp_doc = $("#document_document").selectpicker({width: '200px'});
      let sp_locale = $("#document_locale").selectpicker({width: '150px'});
      let sp_currency = $("#document_currency").selectpicker({width: '180px'});
      var sp_customer = $("#document_customer").selectpicker({ width: '100%'});
      $(this.payment_methodsTarget).selectpicker({width: '200px'})

      sp_locale.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        this.document_locale = e.target.value;
        that.loadLocale(e);
      });

      sp_customer.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.customerSelected(e);
      });

      sp_currency.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        that.currency_abbrvsTargets.forEach((el, i) => {
          el.innerText = that.currenciesAbbr(e.target.value);
        });
      });

      sp_doc.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        if(that.copy) {
          history.replaceState(null, '', `/${that.locale}/document/new/${e.target.value}/${that.copy}`);
        } else {
          history.replaceState(null, '', `/${that.locale}/document/new/${e.target.value}`);
        }

        if(that.documentTarget.value == 'invoice') {
          that.btnSubmitTargets.forEach((btn, i) => {
            btn.setAttribute('type', 'button');
          });
        } else {
          that.btnSubmitTargets.forEach((btn, i) => {
            btn.setAttribute('type', 'submit');
          });
        }

        that.document_headerTarget.textContent = I18n.t(`document.${e.target.value}`);
        if(e.target.value == 'offer') {
          that.quote_validity_divTarget.classList.remove('d-none');
        } else {
          that.quote_validity_divTarget.classList.add('d-none');
        }

        for(let i = 0; i < that.numberingTarget.options.length; i++) {
          if(that.numberingTarget.options[i].value.startsWith(that.documentTarget.value)) {
            that.numberingTarget.options[i].classList.remove('d-none')
          } else {
            that.numberingTarget.options[i].classList.add('d-none')
          }
        }
        that.numberingTarget.value = `${that.documentTarget.value}s`
        $(that.numberingTarget).selectpicker('destroy').selectpicker()
      });

      if(that.numberingTarget.dataset.selected && that.numberingTarget.dataset.selected != '') {
        that.numberingTarget.value = that.numberingTarget.dataset.selected
      } else {
        that.numberingTarget.value = `${that.documentTarget.value}s`
      }
      $(that.numberingTarget).selectpicker('destroy').selectpicker()

      if(this.hasAddress_selectTarget) {
        $(this.address_selectTarget).selectpicker().on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
          that.setAlternateCustomerAddress(e.target.options[clickedIndex].dataset);
        });
      }

      new Sortable(positions, {
          handle: '.handle',
          animation: 150,
          onEnd: () => {
            that.refreshNumbering();
          }
      });

      if(document.querySelector('#datetimepicker1')) {
        new TempusDominus(document.getElementById('datetimepicker1'), {
          localization: {
            locale: this.locale
          },
          useCurrent: true,
          display: {
            components: {
              date: true,
              decades: true,
              month: true,
              year: true,
              hours: false,
              seconds: false,
              minutes: false
            }
          }
        });

        for(let i = 0; i < that.numberingTarget.options.length; i++) {
          if(!that.numberingTarget.options[i].value.includes(this.documentTarget.value)) {
            that.numberingTarget.options[i].classList.add('d-none')
          }
        }

        this.numberingTarget.value = `${this.documentTarget.value}s`
        $(this.numberingTarget).selectpicker('destroy').selectpicker();
      }

/*
      let inp = this.positionsTarget.querySelector("#position_name");
      const ac = new Autocomplete(inp, {
          data: [{label: "I'm a label", value: 42}],
          maximumItems: 5,
          onSelectItem: ({label, value}) => {
              console.log("user selected:", label, value);
          }
      });
*/

      Rails.ajax({
        url: `/${that.locale}/documents/items`,
        type: "get",
        data: "",
        success: function(data) {
          that.items = data;

          let inputs = that.positionsTarget.querySelectorAll("#position_name");
          inputs.forEach((inp, i) => {
            const ac = new Autocomplete(inp, {
                data: that.items,
                label: 'i18n_name',
                value: 'uuid',
                maximumItems: 10,
                treshold: 4,
                onSelectItem: ({label, value}) => {
                  that.insertItem(inp, value);
                }
            });
          });
        },
        error: function(data) {
        }
      });

      if(this.page == 'edit') {
        this.loadDocument();

        if(this.vats_summaryTarget.querySelectorAll(":scope > div:not(.d-none)").length > 0) {
          this.vats_summaryTarget.classList.add("border-bottom", "border-secondary");
        }
      }

      if(this.copy) {
        this.updateDocumentTotal()
      }

      I18n.locale = this.locale;
      this.loadLocale()
    }

    $('#loading').fadeOut(200);
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      if(currency) {
        return currency.toUpperCase();
      } else {
        return ''
      }
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  loadLocale(event) {
    var base = require(`locales/${this.locale}.json`)
    var doc = require(`locales/${this.localeTarget.value}.json`)
    var l = {}
    l[this.locale] = base[this.locale]
    l[this.localeTarget.value] = doc[this.localeTarget.value]
    I18n.translations = l;
    I18n.locale = this.locale;
  }

  filterDocuments() {
    let table = document.querySelector("#documents");
    let filter = this.filterTarget.value.toLowerCase();

    for (var i = 0, cell; cell = table.rows[i]; i++) {
      if(!table.rows[i].dataset.reference) {
        continue;
      }
      if(!table.rows[i].dataset.reference.toLowerCase().includes(filter) && !table.rows[i].dataset.date.includes(filter) && !table.rows[i].dataset.orderref.includes(filter) && !table.rows[i].dataset.customer.toLowerCase().includes(filter)) {
        table.rows[i].classList.add("d-none");
      } else {
        table.rows[i].classList.remove("d-none");
      }
    }

    if(this.documents) {
      Cookies.set(`filter_documents_${this.documents}`, this.filterTarget.value.toLowerCase());
    }
  }

  clearFilter() {
    Cookies.expire(`filter_documents_${this.documents}`);
    this.filterTarget.value = '';
    this.filterDocuments();

    $("#documents").bootstrapTable('resetSearch', '');
    this.filterTarget.focus();
    this.filterTarget.blur();
  }

  toggleWithVAT() {
    setTimeout((event) => {
      if(this.toggleVATTarget.checked) {
        this.td_priceTargets.forEach((td, i) => {
          td.classList.add('d-none');
        });
        this.td_price_with_vatTargets.forEach((td, i) => {
          td.classList.remove('d-none')
        });
        this.td_totalTargets.forEach((td, i) => {
          td.classList.add('d-none');
        });
        this.td_total_with_vatTargets.forEach((td, i) => {
          td.classList.remove('d-none')
        });
      } else {
        this.td_price_with_vatTargets.forEach((td, i) => {
          td.classList.add('d-none')
        });
        this.td_priceTargets.forEach((td, i) => {
          td.classList.remove('d-none');
        });
        this.td_total_with_vatTargets.forEach((td, i) => {
          td.classList.add('d-none')
        });
        this.td_totalTargets.forEach((td, i) => {
          td.classList.remove('d-none');
        });
      }
    }, 100)
  }

  toggleEquivalenceSurcharge() {
    setTimeout(() => {
      let eqs = document.querySelectorAll(".equivalence_surcharge");
      if(!this.equivalence_surchargeTarget.checked) {
        for(let i = 0; i < eqs.length; i++) {
          eqs[i].classList.remove("d-flex");
          eqs[i].classList.add("d-none");
        }
      }

      this.updateDocumentTotal();
    }, 100);
  }

  toggleRetention() {
    setTimeout(() => {
      let inp_retention = document.querySelector("#document_total_retention");
      if(this.retentionTarget.checked) {
        document.querySelector("#total_retention").classList.remove('d-none');
      } else {
        document.querySelector("#total_retention").classList.add('d-none');
        inp_retention.value = ''
      }
      this.updateDocumentTotal();
    }, 100);
  }

  toggleNote() {
    let td = event.target.closest('td');
    let note_div = td.querySelector('div.note');
    note_div.classList.toggle('d-none')
    let txt_note = td.querySelector('#position_note');
    txt_note.textContent = ''
  }

  togglePublicLink() {
    let that = this;
    let div = event.target.closest(".public_link");
    let btn = div.querySelector('button');
    if(event.target.checked) {
      Rails.ajax({
        url: `/${this.locale}/document/${that.documentUuidValue}/link/generate`,
        type: "post",
        data: "",
        success: function(data) {
          that.public_linkTarget.value = data.url;
        },
        error: function(data) {
          console.log("Error loading documents");
        }
      });
      btn.removeAttribute('disabled')
    } else {
      Rails.ajax({
        url: `/${this.locale}/document/${that.documentUuidValue}/link/delete`,
        type: "delete",
        data: "",
        success: function(data) {
          that.public_linkTarget.value = '';
        },
        error: function(data) {
          console.log("Error loading documents");
        }
      });
      btn.setAttribute('disabled', 'true')
    }
  }

  selectPublicLink() {
    event.target.select()
  }

  copyPublicLink() {
    let inp = event.target.parentNode.querySelector('input');
    navigator.clipboard.writeText(inp.value);
    business.showToast(I18n.t('document.notifications.public_link_copied'));
    event.target.blur();
  }

  insertPosition() {
    let row = event.target.closest('tr');
    if(row.rowIndex == this.positionsTarget.rows.length-1) {
      this.appendPosition()
    }
  }

  appendPosition() {
    let that = this;
    var row = this.positionsTarget.insertRow();
    row.innerHTML = `
      <input type="hidden" id="position_uuid" name="positions[][uuid]">
      <input type="hidden" id="position_item" name="positions[][item]">
    `
    var td1 = row.insertCell();
    td1.classList.add("pe-2");
    td1.innerHTML = `<div class="d-flex align-items-center pt-1">
      <div class="handle pointer-move-up-down me-2">
        <i class="fas fa-grip-vertical fa-lg"></i>
      </div>
      <span class="badge bg-secondary" data-documents-target="position_number">
        ${this.positionsTarget.rows.length-1}
      </span>
    </div>`;

    var td2 = row.insertCell();
    td2.innerHTML = `<div class="d-flex justify-content-between align-items-center">
                      <span class="item-info text-secondary d-none">
                        <i class="fa-solid fa-circle-info fa-fw"></i>
                      </span>
                      <input type="text" class="form-control position flex-fill" id="position_name" name="positions[][label]" data-action="input->documents#insertPosition" autocomplete="off">
                      <button type="button" class="btn btn-sm btn-link text-secondary ms-1" data-action="click->documents#toggleNote">
                        <i class="fa-solid fa-align-left"></i>
                      </button>
                    </div>
                    <div class="d-none note">
                      <textarea id="position_note" name="positions[][note]" rows="2" class="form-control form-controll-sm smaller mt-2 mb-2" placeholder="${I18n.t('item.note')}" style="width: calc(100% - 30px)"></textarea>
                    </div>`

    let inp = td2.querySelector("#position_name");
    const ac = new Autocomplete(inp, {
        data: that.items,
        label: 'i18n_name',
        value: 'uuid',
        maximumItems: 10,
        treshold: 4,
        onSelectItem: ({label, value}) => {
          that.insertItem(inp, value);
        }
    });

    var td3a = row.insertCell();
    td3a.innerHTML = `<input type="text" class="form-control position" id="position_item_number" name="positions[][item_number]">`

    var td3 = row.insertCell();
    td3.classList.add("text-end")
    td3.innerHTML = `<input type="number" class="form-control position text-end" step="any" id="position_quantity" name="positions[][quantity]" data-action="input->documents#updatePositionTotal">`

    var td4 = row.insertCell();
    td4.classList.add("text-end")
    if(this.toggleVATTarget.checked) {
      td4.classList.add("d-none")
    }
    td4.setAttribute("data-documents-target", "td_price")
    td4.innerHTML = `
      <input type="number" class="form-control position text-end" step="any" id="position_price" name="positions[][price]" data-action="input->documents#calcPriceWithVAT">
      <input type="hidden" id="position_discount" name="positions[][discount]">
      <input type="hidden" id="position_discount_type" name="positions[][discount_type]">
      <input type="number" class="form-control position text-end d-none text-danger" id="position_price_with_discount" name="positions[][price_with_discount]" readonly  data-action="click->documents#editPrice">
    `

    var td5 = row.insertCell();
    td5.classList.add("text-end")
    if(!this.toggleVATTarget.checked) {
      td5.classList.add("d-none")
    }
    td5.setAttribute("data-documents-target", "td_price_with_vat")
    td5.innerHTML = `
      <input type="number" class="form-control position text-end" step="any" id="position_price_with_vat" name="positions[][price_with_vat]" data-action="input->documents#calcPriceWithoutVAT">
      <input type="number" class="form-control position text-end d-none text-danger" id="position_price_with_discount_vat" name="positions[][price_with_discount_vat]" readonly>
    `

    var tdP = row.insertCell();
    tdP.classList.add("text-end")
    tdP.innerHTML = `<button type="button" class="btn btn-sm btn-link text-secondary ps-0 pe-1" data-action="click->documents#editPrice">
      <i class="fa-solid fa-tag"></i>
    </button>`

    var td6 = row.insertCell();
    td6.classList.add("text-end")
    if(this.toggleVATTarget.checked) {
      td6.classList.add("d-none")
    }
    td6.setAttribute("data-documents-target", "td_total")
    td6.innerHTML = `<input type="number" class="form-control position text-end" step="any" id="position_total" name="positions[][total]" data-documents-target="position_total">`

    var td7 = row.insertCell();
    td7.classList.add("text-end")
    if(!this.toggleVATTarget.checked) {
      td7.classList.add("d-none")
    }
    td7.setAttribute("data-documents-target", "td_total_with_vat")
    td7.innerHTML = `<input type="number" class="form-control position text-end" step="any" id="position_total_with_vat" name="positions[][total_with_vat]" data-documents-target="position_total_with_vat">`

    var td8 = row.insertCell();
    td8.innerHTML = this.vat_select_templateTarget.innerHTML;
    let sel = td8.querySelector('select');
    sel.setAttribute('data-documents-target', 'position_vat');

    var td9 = row.insertCell();
    td9.classList.add("text-end")
    td9.innerHTML = `<button type="button" class="btn btn-sm btn-link text-dark" data-action="click->documents#removePosition"><i class="fa-solid fa-trash"></i></button>`
  }

  removePosition() {
    let row = event.target.closest('tr');
    if(row) {
      row.remove()
    }
    if(this.positionsTarget.rows.length <= 1) {
      this.appendPosition()
    }
    this.refreshNumbering()
    this.updateDocumentTotal()
  }

  loadDocument() {
    let that = this;
    let positions = document.querySelector('#positions');
    positions.rows.forEach((row, i) => {
      let discount = row.querySelector('#position_discount').value;
      if(discount != '') {
        let inp_price = row.querySelector('#position_price');
        let inp_price_vat = row.querySelector('#position_price_with_vat');
        let inp_discount_price = row.querySelector('#position_price_with_discount');
        let inp_discount_price_vat = row.querySelector('#position_price_with_discount_vat');
        let vat = parseFloat(row.querySelector('#position_vat').value);

        inp_price.classList.add('strikethrough');
        inp_price.classList.add('form-control-sm');
        inp_price.setAttribute('readonly', true);

        inp_price_vat.classList.add('strikethrough');
        inp_price_vat.classList.add('form-control-sm');
        inp_price_vat.setAttribute('readonly', true);

        inp_discount_price.classList.remove('d-none');

        let price_with_vat = parseFloat(inp_discount_price.value)*(1+(vat/100))
        inp_discount_price_vat.value = price_with_vat.toFixed(2);
        inp_discount_price_vat.classList.remove('d-none');
      }
    });
    this.viesCheck();
    this.appendPosition()
  }

  viesCheck() {
    let that = this;

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/contact/${this.customerTarget.value}.json`,
      type: "get",
      data: "",
      success: function(data) {
        that.vies_labelTarget.innerHTML = '';
        that.vies_labelTarget.classList.add("d-none");

        if(data.data && data.data.vies) {
          if(data.data.vies.exists) {
            if(data.data.vies.country && data.data.vies.country != that.countryValue)
            {
              that.vies_labelTarget.innerHTML = `
                <i class="fa-regular fa-circle-check text-success"></i>
                ${I18n.t('contact.vies.vies')}
              `
              that.vies_labelTarget.classList.remove("d-none");
            }
          }
        }
      },
      error: function(data) {
      }
    });
  }

  editPrice() {
    let that = this;
    I18n.locale = this.locale
    let tr = event.target.closest('tr')
    let price = tr.querySelector('#position_price').value;
    let discount = tr.querySelector('#position_discount').value;
    let discount_type = tr.querySelector('#position_discount_type').value;
    let price_with_discount = tr.querySelector('#position_price_with_discount').value;
    if(price == '') {
      tr.querySelector('#position_price').value = "0.00";
      price = "0.00";
    } else {
      price = parseFloat(price).toFixed(2)
      tr.querySelector('#position_price').value = price;
    }
    let price_discounted = price;
    if(price_with_discount != '') {
      price_discounted = price_with_discount;
    }
    let html = `
      <div class="container" data-controller="documents">
        <div class="mb-3">
          <label for="price" class="form-label">${I18n.t('item.price')}</label>
          <div class="input-group" style="max-width: 210px">
            <input type="number" class="form-control text-end" id="discount_price" value="${price}" data-documents-target="discount_price" data-action="input->documents#calcPriceWithDiscount">
            <span class="input-group-text bg-white" style="width: 95px;">
              ${this.currenciesAbbr(this.currencyValue)}
            </span>
          </div>
        </div>
        <div class="mb-3">
          <label for="price" class="form-label">${I18n.t('item.discount')}</label>
          <div class="input-group" style="max-width: 210px">
            <input type="number" class="form-control text-end" id="discount" value="${discount}" data-documents-target="discount_discount" data-action="input->documents#calcPriceWithDiscount">
            <select id="discount_type" class="form-select" data-documents-target="discount_type" data-action="input->documents#calcPriceWithDiscount" style="max-width: 95px;">
              <option value="fix">
                ${this.currenciesAbbr(this.currencyValue)}
              </option>
              <option value="percentage" ${discount != '' ? 'selected' : ''}>
                %
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <label for="price" class="form-label">${I18n.t('item.price_with_discount')}</label>
          <div class="input-group" style="max-width: 210px">
            <input type="number" class="form-control text-end" id="price_with_discount" value="${price_discounted}" data-documents-target="discount_price_discounted" data-action="input->documents#calcPriceWithoutDiscount">
            <span class="input-group-text bg-white" style="width: 95px;">
              ${this.currenciesAbbr(this.currencyValue)}
            </span>
          </div>
        </div>
      </div>
    `
    var dialog = bootbox.dialog({
      message: html,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.apply'),
          className: "btn-outline-success",
          callback: function() {
            let box_price = document.querySelector("#discount_price");
            let box_discount_price = document.querySelector("#price_with_discount");
            let box_discount = document.querySelector("#discount");
            let box_discount_type = document.querySelector("#discount_type");

            let inp_price = tr.querySelector('#position_price');
            let inp_price_vat = tr.querySelector('#position_price_with_vat');
            let inp_discount_price = tr.querySelector('#position_price_with_discount');
            let inp_discount_price_vat = tr.querySelector('#position_price_with_discount_vat');
            let inp_discount = tr.querySelector('#position_discount');
            let inp_discount_type = tr.querySelector('#position_discount_type');

            let vat = parseFloat(tr.querySelector('#position_vat').value);

            if(box_discount.value != '') {
              inp_price.classList.add('strikethrough');
              inp_price.classList.add('form-control-sm');
              inp_price.setAttribute('readonly', true);
              inp_price.value = parseFloat(box_price.value).toFixed(2);

              inp_price_vat.classList.add('strikethrough');
              inp_price_vat.classList.add('form-control-sm');
              inp_price_vat.setAttribute('readonly', true);

              inp_discount_price.value = box_discount_price.value;
              inp_discount_price.classList.remove('d-none');

              let price_with_vat = parseFloat(box_discount_price.value)*(1+(vat/100))
              inp_discount_price_vat.value = price_with_vat.toFixed(2);
              inp_discount_price_vat.classList.remove('d-none');

              inp_discount.value = box_discount.value;
              inp_discount_type.value = box_discount_type.value;
            } else {
              inp_discount_price.classList.add('d-none');
              inp_discount_price.value = '';
              inp_discount_price_vat.classList.add('d-none');
              inp_discount_price_vat.value = '';

              inp_price.classList.remove('strikethrough');
              inp_price.classList.remove('form-control-sm');
              inp_price.removeAttribute('readonly');

              inp_price_vat.classList.remove('strikethrough');
              inp_price_vat.classList.remove('form-control-sm');
              inp_price_vat.removeAttribute('readonly');

              inp_discount.value = '';
              inp_discount_type.value = '';
            }

            inp_price.dispatchEvent(new Event('input'));
          }
        }
      }
    });
  }

  calcPriceWithVAT() {
    let row = event.target.closest('tr');
    let inp_quantity = row.querySelector("#position_quantity");
    let inp_price = row.querySelector("#position_price");
    let inp_price_with_vat = row.querySelector("#position_price_with_vat");
    let sel_vat = row.querySelector("#position_vat");

    let inp_discount_price = row.querySelector('#position_price_with_discount');
    let inp_discount_price_vat = row.querySelector('#position_price_with_discount_vat');
    let inp_discount = row.querySelector('#position_discount');

    let price = inp_price.value ? parseFloat(inp_price.value) : 0;
    let price_discounted = inp_discount_price.value ? parseFloat(inp_discount_price.value) : 0;
    let vat = parseFloat(sel_vat.value)
    inp_price_with_vat.value = (price*(1+(vat/100))).toFixed(2)

    if(inp_discount.value != '') {
      inp_discount_price_vat.value = (price_discounted*(1+(vat/100))).toFixed(2)
    }

    this.updatePositionTotal();
  }

  calcPriceWithoutVAT() {
    let row = event.target.closest('tr');
    let inp_quantity = row.querySelector("#position_quantity");
    let inp_price = row.querySelector("#position_price");
    let inp_price_with_vat = row.querySelector("#position_price_with_vat");
    let sel_vat = row.querySelector("#position_vat");

    let price = inp_price_with_vat.value ? parseFloat(inp_price_with_vat.value) : 0;
    let vat = parseFloat(sel_vat.value)
    inp_price.value = (price/(1+(vat/100))).toFixed(4)

    this.updatePositionTotal();
  }

  calcPriceWithDiscount() {
    let price = parseFloat(this.discount_priceTarget.value);
    let discount = parseFloat(this.discount_discountTarget.value);
    let discount_type = this.discount_typeTarget.value;
    if(discount_type == 'fix') {
      this.discount_price_discountedTarget.value = (price-discount).toFixed(2)
    } else {
      this.discount_price_discountedTarget.value = (price-(price/100*discount)).toFixed(2)
    }
  }

  calcPriceWithoutDiscount() {
    let price_discounted = parseFloat(this.discount_price_discountedTarget.value);
    let discount = parseFloat(this.discount_discountTarget.value);
    let discount_type = this.discount_typeTarget.value;
    if(discount_type == 'fix') {
      this.discount_priceTarget.value = (price_discounted+discount).toFixed(2)
    } else {
      this.discount_priceTarget.value = ((price_discounted*100)/(100-discount)).toFixed(2)
    }
  }

  updatePositionTotal() {
    let row= event.target.closest('tr');

    let inp_quantity = row.querySelector("#position_quantity");
    let inp_price = row.querySelector("#position_price");
    let inp_price_with_vat = row.querySelector("#position_price_with_vat");
    let inp_total = row.querySelector("#position_total");
    let inp_total_with_vat = row.querySelector("#position_total_with_vat");

    let inp_discount_price = row.querySelector('#position_price_with_discount');
    let inp_discount_price_vat = row.querySelector('#position_price_with_discount_vat');
    let inp_discount = row.querySelector('#position_discount');

    if(inp_quantity.value == '' && parseFloat(inp_quantity.value) <= 1) {
      inp_quantity.value = 1;
    }
    let quantity = inp_quantity.value ? parseFloat(inp_quantity.value) : 0;

    let price;
    let price_with_vat;
    if(inp_discount.value != '') {
      price = inp_discount_price.value ? parseFloat(inp_discount_price.value) : 0;
      price_with_vat = inp_discount_price_vat.value ? parseFloat(inp_discount_price_vat.value) : 0;
    } else {
      price = inp_price.value ? parseFloat(inp_price.value) : 0;
      price_with_vat = inp_price_with_vat.value ? parseFloat(inp_price_with_vat.value) : 0;
    }

    inp_total.value = (quantity * price).toFixed(2);
    inp_total_with_vat.value = (quantity * price_with_vat).toFixed(2);

    this.updateDocumentTotal()
  }

  updateDocumentTotal() {
    let that = this;

    let total = 0;
    let total_with_vat = 0;
    let total_vat = {};
    let total_vat_sum = {};
    let total_eqs = {};
    let total_eqs_sum = {};
    let total_retention = 0;
    let with_vat = false;

    this.total_vat_sumTargets.forEach((v, i) => {
      total_vat[v.dataset.vat] = 0;
      total_vat_sum[v.dataset.vat] = 0;
    });

    this.position_totalTargets.forEach((el, i) => {
      let tr = el.closest('tr');
      let inp_q = tr.querySelector('#position_quantity');
      let inp_p = tr.querySelector('#position_price');
      let inp_dp = tr.querySelector('#position_price_with_discount');

      //total += (el.value != '' ? parseFloat(el.value) : 0); //.round(2);
      if(inp_dp && inp_dp.value != '') {
        total += (inp_q.value != '' ? parseFloat(inp_q.value) : 0) * parseFloat(inp_dp.value);
      } else {
        total += (inp_q.value != '' ? parseFloat(inp_q.value) : 0) * (inp_p.value != '' ? parseFloat(inp_p.value) : 0);
      }
    });

    this.totalTarget.textContent = total.round(4).toFixed(2);

    this.position_vatTargets.forEach((el, i) => {
      let tr = el.closest('tr');
      let inp_q = tr.querySelector('#position_quantity');
      let inp_p = tr.querySelector('#position_price');
      let inp_dp = tr.querySelector('#position_price_with_discount');

      let vat_str = el.value.toString();
      //let tot = parseFloat(this.position_totalTargets[i].value);
      let tot;
      if(inp_dp && inp_dp.value != '') {
        tot = (inp_q.value != '' ? parseFloat(inp_q.value) : 0) * parseFloat(inp_dp.value);
      } else {
        tot = (inp_q.value != '' ? parseFloat(inp_q.value) : 0) * (inp_p.value != '' ? parseFloat(inp_p.value) : 0);
      }
      let tot_vat = parseFloat(this.position_total_with_vatTargets[i].value);
      //total_vat[vat_str] = (total_vat[vat_str] + (tot_vat - tot)).round(2);
      //total_vat_sum[vat_str] = (total_vat_sum[vat_str] + tot).round(2);
      total_vat_sum[vat_str] += tot
    })

    total_with_vat = total;

    this.total_vat_sumTargets.forEach((el, i) => {
      let sum = total_vat_sum[el.dataset.vat]; //.round(4);
      let vat = parseFloat(el.dataset.vat);
      let vat_sum = (sum*(vat/100)); //.round(4);
      total_vat[el.dataset.vat] = vat_sum;

      total_with_vat += vat_sum;

      var vat_position = document.querySelector("#vat_"+el.dataset.vat.replace('.', '-'));
      if(total_vat_sum[el.dataset.vat].round(4) != 0) {
        vat_position.classList.remove('d-none');
        with_vat = true;
      } else {
        vat_position.classList.add('d-none');
      }
      el.textContent = sum.toFixed(2);
      document.querySelector("#total_vat_sum_"+el.dataset.vat.replace('.', '-')).value = sum.toFixed(4);

      document.querySelector("#total_vat_"+el.dataset.vat.replace('.', '-')).value = vat_sum.toFixed(4);
    })

    if(with_vat) {
      that.vats_summaryTarget.classList.add("border-bottom", "border-secondary");
    } else {
      that.vats_summaryTarget.classList.remove("border-bottom", "border-secondary");
    }

    this.total_vatTargets.forEach((el, i) => {
      el.textContent = total_vat[el.dataset.vat].toFixed(2);
      document.querySelector("#total_vat_"+el.dataset.vat.replace('.', '-')).value = total_vat[el.dataset.vat].round(4);
    })

    if(this.equivalence_surchargeTarget.checked) {

      this.total_eqsTargets.forEach((eq, i) => {
        let eqs = parseFloat(eq.dataset.eqs);
        total_eqs_sum[eq.dataset.eqs] = total_vat_sum[eq.dataset.vat];
        total_eqs[eq.dataset.eqs] = total_vat_sum[eq.dataset.vat]*(eqs/100);

        total_with_vat += total_eqs[eq.dataset.eqs];
      });

      this.total_eqs_sumTargets.forEach((el, i) => {
        el.textContent = total_eqs_sum[el.dataset.eqs].toFixed(2);
        document.querySelector("#total_vat_sum_"+el.dataset.eqs.replace('.', '-')).value = total_eqs_sum[el.dataset.eqs].round(4);

        if(total_eqs_sum[el.dataset.eqs] > 0) {
          el.parentNode.parentNode.classList.remove("d-none");
          el.parentNode.parentNode.classList.add("d-flex");
        } else {
          el.parentNode.parentNode.classList.remove("d-flex");
          el.parentNode.parentNode.classList.add("d-none");
        }
      })

      this.total_eqsTargets.forEach((el, i) => {
        el.textContent = total_eqs[el.dataset.eqs].toFixed(2);
        document.querySelector("#total_vat_"+el.dataset.eqs.replace('.', '-')).value = total_eqs[el.dataset.eqs].toFixed(4);
      })
    }

    let retention = parseFloat(this.retentionTarget.dataset.retention);
    total_retention = (total.round(2)*(retention/100)).round(2);
    if(this.retentionTarget.checked) {
      this.total_retentionTarget.textContent = (0-total_retention).toFixed(2);
      total_with_vat = (total_with_vat - total_retention);
    } else {
      this.total_retentionTarget.textContent = 0;
    }

/*
    this.position_total_with_vatTargets.forEach((el, i) => {
      total_with_vat += el.value != '' ? parseFloat(el.value) : 0;
    });
*/

    this.total_with_vatTarget.textContent = total_with_vat.toFixed(2);

    //Set form values
    document.querySelector("#document_total").value = total.toFixed(2);
    document.querySelector("#document_total_with_vat").value = total_with_vat.toFixed(2);
    document.querySelector("#document_total_retention").value = total_retention.toFixed(2);
  }

  refreshNumbering() {
    this.position_numberTargets.forEach((lbl, i) => {
      lbl.innerText = i+1;
    });
  }

  updatePaymentConditions() {
    if(this.payment_conditionsTarget.value.match(/\D/)) {
      this.payment_conditionsTarget.parentNode.querySelector('.input-group-text').classList.add('d-none');
      this.payment_conditionsTarget.classList.add('rounded-end');
    } else {
      this.payment_conditionsTarget.parentNode.querySelector('.input-group-text').classList.remove('d-none');
      this.payment_conditionsTarget.classList.remove('rounded-end');
    }
  }

  insertItem(inp, uuid) {
    let that = this;
    Rails.ajax({
      url: `/${this.locale}/item/${uuid}.json`,
      type: "get",
      data: "",
      success: function(data) {
        let row = inp.closest('tr');

        let currency = that.currencyValue;
        if(that.hasCurrencyTarget) {
          currency = that.currencyTarget.value;
        }
        let customer = that.customerTarget.value;
        let groups = that.addressTarget.dataset.groups;

        let p = that.getCustomerPrice(data.prices, currency, customer, groups);

        let price = 0;
        let price_with_vat = 0;

        if(p) {
          price = p.price ? p.price : 0;
          price_with_vat = p.price_with_vat ? p.price_with_vat : 0;
        }

        let total = price;
        let total_with_vat = price_with_vat;

        let inp_uuid = row.querySelector("#position_uuid");
        let inp_item = row.querySelector("#position_item");
        let inp_item_number = row.querySelector("#position_item_number");
        let inp_name = row.querySelector("#position_name");
        let inp_quantity = row.querySelector("#position_quantity");
        let inp_price = row.querySelector("#position_price");
        let inp_price_with_vat = row.querySelector("#position_price_with_vat");
        let inp_total = row.querySelector("#position_total");
        let inp_total_with_vat = row.querySelector("#position_total_with_vat");
        let sel_vat = row.querySelector("#position_vat");
        let icon = row.querySelector(".item-info");

        if(data.data) {
          if(data.data.accounting) {
            if(data.data.accounting.account && data.data.accounting.account != '') {
              row.setAttribute("data-account", data.data.accounting.account);
            }
          }
        }

        inp_item.value = data.uuid;
        inp_item_number.value = data.number;
        icon.setAttribute('title', data.name)
        icon.classList.remove('d-none')
        new bootstrap.Tooltip(icon)

        if(inp_quantity.value == '' || parseFloat(inp_quantity.value) <= 1) {
          inp_quantity.value = 1;
        }

        inp_price.value = price.toFixed(2);
        inp_price_with_vat.value = price_with_vat.toFixed(2);
        inp_total.value = total.toFixed(2);
        inp_total_with_vat.value = total_with_vat.toFixed(2);
        sel_vat.value = p.vat.toFixed(1);

        if(row.rowIndex == that.positionsTarget.rows.length-1) {
          that.appendPosition()
        }

        inp_quantity.focus()
        that.updateDocumentTotal()
      },
      error: function(data) {
        console.log("Error loading documents");
      }
    });
  }

  getCustomerPrice(prices, currency, customer, groups = []) {
    let price_category;
    if(this.hasPrice_categoryTarget && this.price_categoryTarget.value != '') {
      price_category = this.price_categoryTarget.value;
    } else {
      price_category = null;
    }

    let document_locale = this.locale;
    if(this.hasLocaleTarget) {
      document_locale = this.localeTarget.value;
      I18n.locale = document_locale;
    }

    if(prices.length > 0) {
      let default_price;
      let group_price;
      let own_price;
      let price;

      for(let i = 0; i < prices.length; i++) {
        if(prices[i].active && (prices[i].default || !prices[i].contact) == true && prices[i].category == price_category) {
          if(default_price) {
            if(prices[i].currency == this.currencyValue) {
              default_price = prices[i];
            }
          } else {
            default_price = prices[i];
          }
        }
      }

      if(groups.length > 0) {
        for(let i = 0; i < prices.length; i++) {
          if(prices[i].active && groups.includes(prices[i].contact) && prices[i].category == price_category) {
            if(prices[i].price) {
              if(group_price) {
                if(prices[i].currency == currency) {
                  group_price = prices[i];
                }
              } else {
                group_price = prices[i];
              }
            }
          }
        }
      }

      for(let i = 0; i < prices.length; i++) {
        if(prices[i].active && prices[i].contact == customer && prices[i].category == price_category) {
          if(prices[i].price) {
            if(own_price) {
              if(prices[i].currency == currency) {
                own_price = prices[i];
              }
            } else {
              own_price = prices[i];
            }
          }
        }
      }

      if(default_price) {
        price = default_price;
      }
      if(group_price) {
        price = group_price;
      }
      if(own_price) {
        price = own_price;
      }
      return price;
    }

    I18n.locale = this.locale;
  }

  cancelConfirm() {
    let that = this;
    let msg = `${event.target.dataset.document} ${event.target.dataset.reference}`;
    let uuid = event.target.dataset.uuid;
    bootbox.dialog({
      message: `${I18n.t('document.confirm.cancel')}<br><br>${msg}<br><br><span class="text-danger smaller"><i class="fa-solid fa-circle-exclamation me-2"></i>${I18n.t('document.confirm.cancel_note')}</span>`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.cancel_invoice'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/document/${uuid}/cancel`,
              type: "post",
              data: "",
              success: function(data) {
                setTimeout(() => {
                  location.reload();
                }, 250);
              },
              error: function(data) {}
            });
          }
        }
      }
    });
  }

  deleteConfirm() {
    let that = this;
    let msg = `${event.target.dataset.document} ${event.target.dataset.reference}`;
    let uuid = event.target.dataset.uuid;
    bootbox.dialog({
      message: `${I18n.t('document.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/document/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
            location.reload();
          }
        }
      }
    });
  }

  customerSelected(e) {
    let uuid = e.target.value;
    let that = this;

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/contact/${uuid}.json`,
      type: "get",
      data: "",
      success: function(data) {
        that.setCustomerAddress(data);

        if(data.firstname || data.lastname) {
          that.contactTarget.value = [data.firstname, data.lastname].join(' ');
        } else {
          that.contactTarget.value = '';
        }

        if(data.equivalence) {
          that.equivalence_surchargeTarget.checked = true;
          that.toggleEquivalenceSurcharge();
        }

        if(data.data && data.data.locale) {
          that.localeTarget.value = data.data.locale;
          $(that.localeTarget).selectpicker('refresh');
        }

        if(data.payment_conditions) {
          that.payment_conditionsTarget.value = data.payment_conditions;
        } else {
          that.payment_conditionsTarget.value = that.payment_conditionsTarget.dataset.default;
        }


        that.vies_labelTarget.innerHTML = '';
        that.vies_labelTarget.classList.add("d-none");

        if(data.data.vies) {
          if(data.data.vies.exists) {
            if(data.data.vies.country && data.data.vies.country != that.countryValue)
            {
              that.vies_labelTarget.innerHTML = `
                <i class="fa-regular fa-circle-check text-success"></i>
                ${I18n.t('contact.vies.vies')}
              `
              that.vies_labelTarget.classList.remove("d-none");
            }
          }
        }
      },
      error: function(data) {}
    });
  }

  setCustomerAddress(data) {
    let document_locale = this.locale;
    if(this.hasLocaleTarget) {
      document_locale = this.localeTarget.value;
      I18n.locale = document_locale;
    }

    //Hidden fields
    let address_addressline1 = document.querySelector('#address_addressline1');
    let address_addressline2 = document.querySelector('#address_addressline2');
    let address_name = document.querySelector('#address_name');
    let address_street = document.querySelector('#address_street');
    let address_zip = document.querySelector('#address_zip');
    let address_location = document.querySelector('#address_location');
    let address_state = document.querySelector('#address_state');
    let address_country = document.querySelector('#address_country');

    let data_taxid = document.querySelector('#data_taxid');
    let data_fiscal_code = document.querySelector('#data_fiscal_code');
    let data_unique_code = document.querySelector('#data_unique_code');
    let data_cem = document.querySelector('#data_cem');

    let addr = '';
    if(data.company.trim() != '') {
      address_name.value = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        address_name.value = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    if(data.addresses.length > 0) {

      let address = data.addresses.find(a => a.address_type == 'invoice_address');
      if(address) {

        //If addrelines are set, do not set the company name
        if((address.addressline1 && address.addressline1 != '') || (address.addressline2 && address.addressline2 != '')) {
          if(address.addressline1 && address.addressline1 != '') {
            addr += address.addressline1+"\n";
            address_addressline1.value = address.addressline1;
          } else {
            address_addressline1.value = '';
          }
          if(address.addressline2 && address.addressline2 != '') {
            addr += address.addressline2+"\n";
            address_addressline2.value = address.addressline2;
          } else {
            address_addressline2.value = '';
          }

          address_name.value = '';
        } else {
          addr += address_name.value+"\n";

          address_addressline1.value = '';
          address_addressline2.value = '';
        }

        addr += address.street+"\n";

        if(address.country && address.country != '') {
          addr += `${address.country}-${address.zip} ${address.location}`;
        } else {
          addr += `${address.zip} ${address.location}`;
        }
        address_street.value = address.street;
        address_zip.value = address.zip;
        address_location.value = address.location;

        if(address.state) {
          //addr += "\n"+address.state;
          address_state.value = address.state;
        } else {
          address_state.value = '';
        }

        if(address.country) {
          //addr += "\n"+countries.getName(address.country, this.locale);
          address_country.value = address.country;
        } else {
          address_country.value = '';
        }

      } else {

        //If addrelines are set, do not set the company name
        if((data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') || (data.addresses[0].addressline2 && data.addresses[0].addressline2 != '')) {
          if(data.addresses[0].addressline1 && data.addresses[0].addressline1 != '') {
            addr += data.addresses[0].addressline1+"\n";
            address_addressline1.value = data.addresses[0].addressline1;
          } else {
            address_addressline1.value = '';
          }
          if(data.addresses[0].addressline2 && data.addresses[0].addressline2 != '') {
            addr += data.addresses[0].addressline2+"\n";
            address_addressline2.value = data.addresses[0].addressline2;
          } else {
            address_addressline2.value = '';
          }

          address_name.value = '';
        } else {
          addr += address_name.value+"\n";

          address_addressline1.value = '';
          address_addressline2.value = '';
        }

        addr += data.addresses[0].street+"\n";

        if(data.addresses[0].country && data.addresses[0].country != '') {
          addr += `${data.addresses[0].country}-${data.addresses[0].zip} ${data.addresses[0].location}`;
        } else {
          addr += `${data.addresses[0].zip} ${data.addresses[0].location}`;
        }

        address_street.value = data.addresses[0].street;
        address_zip.value = data.addresses[0].zip;
        address_location.value = data.addresses[0].location;

        if(data.addresses[0].state) {
          //addr += "\n"+data.addresses[0].state;
          address_state.value = data.addresses[0].state;
        } else {
          address_state.value = '';
        }
        if(data.addresses[0].country) {
          //addr += "\n"+countries.getName(data.addresses[0].country, this.locale);
          address_country.value = data.addresses[0].country;
        } else {
          address_country.value = '';
        }
      }
    }

    data_taxid.value = '';
    data_fiscal_code.value = '';
    data_unique_code.value = '';
    data_cem.value = '';
    if(data.taxid != '' && data.taxid != null) {
      addr += `\n\n${I18n.t('contact.cif_nif')}: ${data.taxid}`;

      data_taxid.value = data.taxid;
    }
    if(data.data && data.data.fiscal_code && data.data.fiscal_code != '') {
      addr += `\n${I18n.t("contact.fiscal_code_abbr")}: ${data.data.fiscal_code}`;

      data_fiscal_code.value = data.data.fiscal_code;
    }
    if(data.data && data.data.unique_code && data.data.unique_code != '') {
      addr += `\n${I18n.t("contact.unique_code")}: ${data.data.unique_code}`;

      data_unique_code.value = data.data.unique_code;
    }

    if(data.emails.length > 0) {
      data.emails.forEach((email) => {
        if(email.email_type == 'cem') {
          addr += `\n${I18n.t("contact.cem")}: ${email.email_address}`;

          data_cem.value = email.email_address;
        }
      })
    }

    this.addressTarget.value = addr;
    this.addressTarget.dataset.groups = data.groups;

    // Set alternate addresses if present
    this.addAlternateAddresses(data);

    I18n.locale = this.locale
  }

  addAlternateAddresses(data) {

    this.address_selectTarget.innerHTML = '';

    if(data.addresses.length > 1) {
      for(let i = 0; i < data.addresses.length; i++) {

        let str = [];

        //If addrelines are set, do not set the company name
        if((data.addresses[i].addressline1 && data.addresses[i].addressline1 != '') || (data.addresses[i].addressline2 && data.addresses[i].addressline2 != '')) {
          if(data.addresses[i].addressline1 && data.addresses[i].addressline1 != '') {
            str.push(data.addresses[i].addressline1);
          }
          if(data.addresses[i].addressline2 && data.addresses[i].addressline2 != '') {
            str.push(data.addresses[i].addressline2);
          }
        } else {
          if(data.company.trim() != '') {
            str.push(data.company);
          } else {
            if(data.firstname != '' || data.lastname != '') {
              str.push((`${data.firstname} ${data.lastname}`).trim());
            }
          }
        }

        str.push(data.addresses[i].street);
        str.push(data.addresses[i].zip);
        str.push(data.addresses[i].location);
        if(data.addresses[i].state) {
          str.push(data.addresses[i].state);
        }
        if(data.addresses[i].country) {
          str.push(countries.getName(data.addresses[i].country, this.locale));
        }

        let option = document.createElement("option");
        let option2 = document.createElement("option");
        option.text = str.join(', ');
        option.dataset.subtext = data.addresses[i].label;
        option2.text = str.join(', ');
        option2.dataset.subtext = data.addresses[i].label;
        this.setAddressDataFields(option, data, data.addresses[i]);
        this.setAddressDataFields(option2, data, data.addresses[i]);

        if(data.addresses[i].address_type == 'invoice_address') {
          option.selected = true
          option2.selected = true
        }

        this.address_selectTarget.appendChild(option);
      }

      $(this.address_selectTarget).selectpicker('refresh');
      this.address_select_divTargets.forEach((div) => {
        div.classList.remove("d-none");
      })
    } else {
      this.address_select_divTargets.forEach((div) => {
        div.classList.add("d-none");
      })

      $(this.address_selectTarget).selectpicker('refresh');
    }
  }

  setAlternateCustomerAddress(data) {
    let document_locale = this.locale;
    if(this.hasLocaleTarget) {
      document_locale = this.localeTarget.value;
      I18n.locale = document_locale;
    }

    let address_addressline1 = document.querySelector('#address_addressline1');
    let address_addressline2 = document.querySelector('#address_addressline2');
    let address_name = document.querySelector('#address_name');
    let address_street = document.querySelector('#address_street');
    let address_zip = document.querySelector('#address_zip');
    let address_location = document.querySelector('#address_location');
    let address_state = document.querySelector('#address_state');
    let address_country = document.querySelector('#address_country');

    let data_taxid = document.querySelector('#data_taxid');
    let data_fiscal_code = document.querySelector('#data_fiscal_code');
    let data_unique_code = document.querySelector('#data_unique_code');
    let data_cem = document.querySelector('#data_cem');

    address_addressline1.value = '';
    address_addressline2.value = '';
    address_name.value = '';
    address_street.value = '';
    address_zip.value = '';
    address_location.value = '';
    address_state.value = '';
    address_country.value = '';

    let str = [];

    if(data.addressline1) {
      address_addressline1.value = data.addressline1;
      str.push(data.addressline1);
    }
    if(data.addressline2) {
      address_addressline2.value = data.addressline2;
      str.push(data.addressline2);
    }
    if(data.name) {
      address_name.value = data.name;
      str.push(data.name);
    }

    address_street.value = data.street;
    address_zip.value = data.zip;
    address_location.value = data.location;

    str.push(data.street);

    if(data.country_code && data.country_code != '') {
      str.push(`${data.country_code}-${data.zip} ${data.location}`.trim());
    } else {
      str.push(`${data.zip} ${data.location}`.trim());
    }

    if(data.state) {
      //str.push(data.state);
      address_state.value = data.state;
    }
    if(data.country) {
      //str.push(data.country);
      address_country.value = data.country;
    }

    str.push('');

    if(data_taxid.value != '') {
      str.push(`${I18n.t("contact.cif_nif")}: ${data_taxid.value}`);
    }
    if(data_fiscal_code.value != '') {
      str.push(`${I18n.t("contact.fiscal_code_abbr")}: ${data_fiscal_code.value}`);
    }
    if(data_unique_code.value != '') {
      str.push(`${I18n.t("contact.unique_code")}: ${data_unique_code.value}`);
    }
    if(data_cem.value) {
      str.push(`${I18n.t("contact.cem")}: ${data_cem.value}`);
    }

    this.addressTarget.value = str.join('\n').trim();

    I18n.locale = this.locale
  }

  setAddressDataFields(target, data, address) {

    if(address.addressline1) {
      target.dataset.addressline1 = address.addressline1;
    }
    if(address.addressline2) {
      target.dataset.addressline2 = address.addressline2;
    }

    if(data.company.trim() != '') {
      target.dataset.name = data.company;
    } else {
      if(data.firstname != '' || data.lastname != '') {
        target.dataset.name = (`${data.firstname} ${data.lastname}`).trim();
      }
    }

    target.dataset.street = address.street;
    target.dataset.zip = address.zip;
    target.dataset.location = address.location;
    target.dataset.state = address.state;
    if(address.country) {
      target.dataset.country = countries.getName(address.country, this.locale);
      target.dataset.country_code = address.country;
    }

    if(data.taxid != '' && data.taxid != null) {
      target.dataset.vat = `${this.vat_label}: ${data.taxid}`;
    }
    if(data.data && data.data.fiscal_code && data.data.fiscal_code != '') {
      target.dataset.fiscal_code = `${I18n.t("contact.fiscal_code_abbr")}: ${data.data.fiscal_code}`;
    }
    if(data.data && data.data.unique_code && data.data.unique_code != '') {
      target.dataset.unique_code = `${I18n.t("contact.unique_code")}: ${data.data.unique_code}`;
    }

    if(data.emails.length > 0) {
      data.emails.forEach((email) => {
        if(email.email_type == 'cem') {
          target.dataset.cem = `${I18n.t("contact.cem")}: ${email.email_address}`;
        }
      })
    }
  }

  editAddress() {
    //Hidden fields
    let address_addressline1 = document.querySelector('#address_addressline1');
    let address_addressline2 = document.querySelector('#address_addressline2');
    let address_name = document.querySelector('#address_name');
    let address_street = document.querySelector('#address_street');
    let address_zip = document.querySelector('#address_zip');
    let address_location = document.querySelector('#address_location');
    let address_state = document.querySelector('#address_state');
    let address_country = document.querySelector('#address_country');

    let data_taxid = document.querySelector('#data_taxid');
    let data_fiscal_code = document.querySelector('#data_fiscal_code');
    let data_unique_code = document.querySelector('#data_unique_code');
    let data_cem = document.querySelector('#data_cem');

    let str = `target=${event.target.id}`;
    if(address_addressline1.value != '') {
      str += `&addressline1=${address_addressline1.value}`
    }
    if(address_addressline2.value != '') {
      str += `&addressline2=${address_addressline2.value}`
    }
    if(address_name.value != '') {
      str += `&name=${address_name.value}`
    }
    str += `&street=${address_street.value}`
    str += `&zip=${address_zip.value}`
    str += `&city=${address_location.value}`
    str += `&state=${address_state.value}`
    str += `&country=${address_country.value}`

    if(data_taxid.value != '') {
      str += `&vat=${data_taxid.value}`
    }
    if(data_fiscal_code.value != '') {
      str += `&fiscal_code=${data_fiscal_code.value}`
    }
    if(data_unique_code.value != '') {
      str += `&unique_code=${data_unique_code.value}`
    }
    if(data_cem.value != '') {
      str += `&cem=${data_cem.value}`
    }

    //Get contact details
    Rails.ajax({
      url: `/${this.locale}/document/0/address/edit`,
      type: "get",
      data: str,
      success: function(data) {},
      error: function(data) {}
    });
  }

  initData() {
    let that = this;
    let table = $("#documents");

    $.fn.bootstrapTable.locales[this.locale]['formatShowingRows'] = function (from, to, total) {
      return I18n.t('document.pagination', {from: from, to: to, total: total});
    }
    $.fn.bootstrapTable.locales[this.locale]['formatRecordsPerPage'] = function (count) {
      return I18n.t('document.per_page', {count: count});
    }

    table.bootstrapTable({
      url: `${window.location.href}`,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchSelector: '#filter_search',
      searchText: this.filterTarget.value,
      searchTimeOut: 500,
      loadingFontSize: '1rem',
    });

    table.on('load-success.bs.table', () => {
      if(this.filterTarget.value != '') {
        this.filterTarget.focus();
        this.filterTarget.blur();
      }
    });

    table.on('reset-view.bs.table', () => {
      $('[data-toggle="popover"]').popover();
    });
  }

  loadNextData(offset) {
    let table = $("#documents");

    let that = this;
    Rails.ajax({
      url: `${window.location.href}?limit=500&offset=${offset}`,
      type: "get",
      data: "",
      success: function(data) {
        table.bootstrapTable('append', data)
        if(data.length == 500) {
          that.loadNextData(offset+500);
        }
      },
      error: function(data) {
        console.log("Error loading documents");
      }
    });
  }

  setDocumentState() {
    let uuid = event.target.dataset.uuid;
    let state = event.target.dataset.state;
    bootbox.prompt({
      title: I18n.t('date.date'),
      inputType: 'date',
      size: 'small',
      value: new Date().toISOString().substring(0,10),
      max: new Date().toISOString().substring(0,10),
      callback: function (result) {
        Rails.ajax({
          url: `/${this.locale}/document/${uuid}/state/${state}/${result}/set`,
          type: "post",
          data: "",
          success: function(data) {
            location.reload();
          },
          error: function(data) {
            console.log("Error loading documents");
          }
        });
      }
    });
  }

  unsetDocumentState() {
    let uuid = event.target.dataset.uuid;
    let state = event.target.dataset.state;
    Rails.ajax({
      url: `/${this.locale}/document/${uuid}/state/${state}/unset`,
      type: "post",
      data: "",
      success: function(data) {
        location.reload();
      },
      error: function(data) {
        console.log("Error loading documents");
      }
    });
  }

  invoiceConfirm() {
    I18n.locale = this.locale;
    if(this.documentTarget.value == 'invoice') {
      if(this.customerTarget.value == '') {
        this.customerTarget.focus()
        return;
      }

      let credit_accounts = []
      this.td_totalTargets.forEach((td, i) => {
        let row = td.closest('tr')
        if(row && row.dataset.account) {
          let amount = td.querySelector('input').value;
          let ca = credit_accounts.filter(ca => ca.account == row.dataset.account);
          if(ca.length > 0) {
            ca[0].amount += parseFloat(amount)
          } else {
            credit_accounts.push({account: row.dataset.account, amount: parseFloat(amount)})
          }
        }
      });

      let total_with_vat = document.querySelector("input#document_total_with_vat").value;
      let vat = 0;
      let vats = document.querySelectorAll('input[name="vats[][vat]"]');
      for(let i = 0; i < vats.length; i++) {
        vat += parseFloat(vats[i].value)
      }
      let total = parseFloat(total_with_vat) - vat;
      let default_debit;
      let default_vat;

      var html = `
        <h1 class="display-7 mb-4">${I18n.t('document.confirm.invoice_create')}</h1>
        <span class="small semi-bold">${I18n.t('document.reference')}</span><br>
        <p class="lead">${this.numberingTarget.options[this.numberingTarget.selectedIndex].text}</p>
      `
      if(this.hasAccounting_select_templateTarget) {
        default_debit = this.accounting_select_templateTarget.dataset.defaultDebit;
        default_vat = this.accounting_select_templateTarget.dataset.defaultVat;
        let sel = this.accounting_select_templateTarget.innerHTML;
        html += `
          <h1 class="lead mt-5 mb-4">${I18n.t('accounting.accounting')}</h1>
          <div class="row mb-3">
            <div class="col">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="accounting_book_invoice" name="accounting[book_invoice]" checked>
                <label class="form-check-label" for="accounting_book_invoice">
                  ${I18n.t('accounting.book_invoice')}
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 debit">
              <label for="entry_debit" class="semi-bold">
                ${I18n.t('accounting.debit')}
              </label><br>
              <div class="d-flex mb-3">
                ${sel}
                <div class="input-group input-group-sm ms-2 flex-nowrap" style="max-width: 120px;min-width: 120px;">
                  <input id="debit_amount" name="debit[][amount]" step="any" value="0" class="form-control text-end" required="required" type="number">
                  <span class="input-group-text" data-accounting-target="currencies">
                    ${this.currenciesAbbr(this.currencyValue)}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-6 credit">
              <label for="entry_credit" class="semi-bold">
                ${I18n.t('accounting.credit')}
              </label>
          `
        if(credit_accounts.length > 0) {
          let doc_tot
          let calc_tot = 0;
          credit_accounts.forEach((ca, i) => {
            calc_tot += ca.amount;
            html += `
                    <div class="d-flex mb-3">
                      ${sel}
                      <div class="input-group input-group-sm ms-2 flex-nowrap" style="max-width: 120px;min-width: 120px;">
                        <input id="credit_amount" name="credit[][amount]" step="any" class="form-control text-end" required="required" type="number" value="${ca.amount.toFixed(2)}" data-account="${ca.account}">
                        <span class="input-group-text" data-accounting-target="currencies">
                          ${this.currenciesAbbr(this.currencyValue)}
                        </span>
                      </div>
                    </div>
                  `
          });
          if(parseFloat(total) != calc_tot.round(2)) {
            html += `
                    <div class="d-flex mb-3">
                      ${sel}
                      <div class="input-group input-group-sm ms-2 flex-nowrap" style="max-width: 120px;min-width: 120px;">
                        <input id="credit_amount" name="credit[][amount]" step="any" class="form-control text-end" required="required" type="number" value="${(parseFloat(total) - calc_tot).toFixed(2)}">
                        <span class="input-group-text" data-accounting-target="currencies">
                          ${this.currenciesAbbr(this.currencyValue)}
                        </span>
                      </div>
                    </div>
                  `
          }
        } else {
          html += `
                  <div class="d-flex mb-3">
                    ${sel}
                    <div class="input-group input-group-sm ms-2 flex-nowrap" style="max-width: 120px;min-width: 120px;">
                      <input id="credit_amount" name="credit[][amount]" step="any" value="0" class="form-control text-end" required="required" type="number">
                      <span class="input-group-text" data-accounting-target="currencies">
                        ${this.currenciesAbbr(this.currencyValue)}
                      </span>
                    </div>
                  </div>
                `
        }
        if(total != total_with_vat) {
          html += `
                  <div class="d-flex mb-3">
                    ${sel}
                    <div class="input-group input-group-sm ms-2 flex-nowrap" style="max-width: 120px;min-width: 120px;">
                      <input id="credit_amount" name="credit[][amount]" step="any" class="form-control text-end" required="required" type="number" value="${vat.toFixed(2)}" data-account="${default_vat}">
                      <span class="input-group-text" data-accounting-target="currencies">
                        ${this.currenciesAbbr(this.currencyValue)}
                      </span>
                    </div>
                  </div>
                `
        }
        html += `
            </div>
          </div>
        `
      }

      var confirm = bootbox.dialog({
        message: html,
        onEscape: true,
        size: 'large',
        buttons: {
          cancel: {
            label: `<i class="fa-solid fa-xmark"></i> ${I18n.t('btn.cancel')}`,
            className: "btn-outline-secondary",
            callback: function() {
              let form = document.querySelector('form');
              form.querySelectorAll('input[name="debit[][account]"]').forEach((el, i) => {
                el.remove()
              });
              form.querySelectorAll('input[name="debit[][amount]"]').forEach((el, i) => {
                el.remove()
              });
              form.querySelectorAll('input[name="credit[][account]"]').forEach((el, i) => {
                el.remove()
              });
              form.querySelectorAll('input[name="credit[][amount]"]').forEach((el, i) => {
                el.remove()
              });

              return true;
            }
          },
          create: {
            label: `<i class="fa-solid fa-check"></i> ${I18n.t('btn.create')}`,
            className: "btn-success",
            callback: function() {
              let box = document.querySelector(".bootbox-body");
              let form = document.querySelector('form');
              if(form) {
                let accounting_check = box.querySelector("#accounting_book_invoice");
                if(accounting_check && accounting_check.checked) {
                  let sels = box.querySelectorAll("select");
                  for(let i = 0; i < sels.length; i++) {
                    if(sels[i].value == '') {
                      sels[i].focus()
                      return false;
                    }
                  }

                  let divs = box.querySelector('.col-6.debit').querySelectorAll('div.d-flex')
                  for(let i = 0; i < divs.length; i++) {
                    let sel = divs[i].querySelector('select');
                    let inp = divs[i].querySelector('input[type="number"]');

                    const el1 = document.createElement("input");
                    el1.setAttribute('name', 'debit[][account]');
                    el1.setAttribute('type', 'hidden');
                    el1.setAttribute('value', sel.value);
                    const el2 = document.createElement("input");
                    el2.setAttribute('name', 'debit[][amount]');
                    el2.setAttribute('type', 'hidden');
                    el2.setAttribute('value', inp.value);
                    form.appendChild(el1);
                    form.appendChild(el2);
                  }

                  divs = box.querySelector('.col-6.credit').querySelectorAll('div.d-flex')
                  for(let i = 0; i < divs.length; i++) {
                    let sel = divs[i].querySelector('select');
                    let inp = divs[i].querySelector('input[type="number"]');

                    const el1 = document.createElement("input");
                    el1.setAttribute('name', 'credit[][account]');
                    el1.setAttribute('type', 'hidden');
                    el1.setAttribute('value', sel.value);
                    const el2 = document.createElement("input");
                    el2.setAttribute('name', 'credit[][amount]');
                    el2.setAttribute('type', 'hidden');
                    el2.setAttribute('value', inp.value);
                    form.appendChild(el1);
                    form.appendChild(el2);
                  }

                  Rails.fire(form, 'submit');
                } else {
                  Rails.fire(form, 'submit');
                }
              }
            }
          }
        }
      });

      confirm.on('shown.bs.modal', () => {
        if(document.querySelector("div.accounting")) {
          document.querySelector('.col-6.debit').querySelectorAll('select.accounts').forEach((sel, i) => {
            sel.value = default_debit;
            $(sel).selectpicker({width: 'calc(100% - 135px)'});
          });
          document.querySelector('.col-6.debit').querySelectorAll('input[type="number"]').forEach((inp, i) => {
            inp.value = total_with_vat;
          });

          document.querySelector('.col-6.credit').querySelectorAll('select.accounts').forEach((sel, i) => {
            let inp = sel.closest('div.d-flex').querySelector('input');
            sel.value = inp.dataset.account;
            sel.setAttribute('id', 'credit_account');
            sel.setAttribute('name', 'credit[account]');
            $(sel).selectpicker({width: 'calc(100% - 135px)'});
          });
        }
      })
    }
  }

  selectAttachment() {
    var div = event.target.closest("div.row");
    var input = div.querySelector("#filepicker");
    input.click();
  }

  uploadAttachment() {
    let that = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;
    var attachments = document.querySelector("table.attachments");

    var files = input.files.length;
    var count = 0;

    var busy = this.uploadingAttachmentTarget;
    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      busy.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          var tr = attachments.insertRow();
          tr.classList.add('align-bottom');

          var c1 = tr.insertCell(0);
          var c2 = tr.insertCell(1);
          var c3 = tr.insertCell(2);
          var c4 = tr.insertCell(3);
          c3.classList.add("small", "text-secondary");

          c1.innerHTML = '<i class="fa-solid fa-paperclip"></i>';
          c2.textContent = blob.filename;
          c3.textContent = that.getSize(blob.byte_size);
          c4.innerHTML = `
            <button type="button" class="btn btn-xs btn-outline-danger ms-3" data-action="click->documents#removeAttachment">
              <i class="fa-solid fa-trash"></i>
            </button>
            <input type="hidden" name="attachments[][file]" value="${blob.signed_id}">
          `
        }
        busy.classList.add('d-none');
      })
    })

    input.value = '';
  }

  removeAttachment() {
    let tr = event.target.closest('tr');
    let inp = tr.querySelector('input')
    inp.setAttribute('name', 'delete[][file]')
    tr.classList.add('d-none');
  }

  getSize(size) {
    let sizes = [' Bytes', ' KB', ' MB', ' GB',
        ' TB', ' PB', ' EB', ' ZB', ' YB'];

    for (let i = 1; i < sizes.length; i++) {
        if (size < Math.pow(1024, i))
            return (Math.round((size / Math.pow(
                1024, i - 1)) * 100) / 100) + sizes[i - 1];
    }
    return size;
  }

  reloadEmailTemplate() {
    let that = this;
    let uuid = event.target.dataset.documentUuid;
    let locale = event.target.value;
    var attachments = box[0].querySelector("table.attachments").rows;
    box.modal('hide');
    setTimeout(() => {
      Rails.ajax({
        url: `/${I18n.locale}/document/${uuid}/send/email?l=${locale}`,
        type: "get",
        data: "",
        success: function(data) {
          let table = document.querySelector("table.attachments > tbody");
          if(attachments.length > 1) {
            for(let i = 1; i < attachments.length; i++) {
              table.append(attachments[i]);
            }
          }
        },
        error: function(data) {}
      });
    }, 500);
  }

  bexioSendConfirm() {
    let that = this;
    let html = `
      <h1 class="display-7 mb-4">${I18n.t('custom_modules.bexio.confirm.send_invoice')}</h1>
      <span class="lead">${I18n.t('document.invoice')} <span class="semi-bold">${event.target.dataset.reference}</span></span><br><br>
    `

    let uuid = event.target.dataset.uuid;
    var confirm = bootbox.dialog({
      title: I18n.t('custom_modules.bexio.label'),
      message: html,
      onEscape: true,
      buttons: {
        cancel: {
          label: `<i class="fa-solid fa-xmark"></i> ${I18n.t('btn.cancel')}`,
          className: "btn-outline-secondary"
        },
        send: {
          label: `<i class="fa-solid fa-upload"></i> ${I18n.t('btn.send')}`,
          className: "btn-success",
          callback: function() {
            Rails.ajax({
              url: `/${that.locale}/document/${uuid}/module/bexio/send`,
              type: "post",
              data: "",
              success: function(data) {
                if(data.status == "success") {
                  business.showAlert('success',   I18n.t('custom_modules.bexio.notifications.invoice_sent_to_bexio'), true, 8000);
                } else {
                  business.showAlert('danger', `${I18n.t('custom_modules.bexio.notifications.error_invoice_sent_to_bexio')}<br><br>${data.message}`);
                }
              },
              error: function(data) {
                business.showAlert('danger', `${I18n.t('custom_modules.bexio.notifications.error_invoice_sent_to_bexio')}<br><br>${data.message}`);
              }
            });
          }
        }
      }
    });
  }

  initTableFunctions() {
    let that = this;
    I18n.locale = this.locale;
    let locale = this.locale;

    window.referenceFormatter = function(reference, doc) {
      return `<a href="/${locale}/document/${doc.uuid}">${reference}</a>`;
    }

    window.dateFormatter = function(date) {
      return I18n.l("date.formats.default", date);
    }

    window.orderFormatter = function(order, row) {
      if(row.order && row.order.length > 0) {
        let link = `/${locale}/order/${order[0].uuid}`;
        return `<a href="${link}" class="smaller">${order[0].orderref}</a>`
      } else  {
        return '';
      }
    }

    window.purchaseFormatter = function(purchase, row) {
      if(row.purchase && row.purchase.length > 0) {
        let link = `/${locale}/purchase/${purchase[0].uuid}`;
        return `<a href="${link}" class="smaller">${purchase[0].purchaseref}</a>`
      } else  {
        return '';
      }
    }

    window.customerFormatter = function(address, row) {
      if(address) {
        let addr = address.split("\n").filter(function (el) {
          return (el != '');
        })

        let str = `
          ${addr[0]}
          <span class="small">
            | ${addr.slice(1,3).join(', ')}
          </span>
        `
        return str;
      } else {
        return ''
      }
    }

    window.amountFormatter = function (total, doc) {
      if(doc.total_with_vat) {
        return `
          <span class="small">
            ${that.currenciesFormat(doc.total.toFixed(2), doc.currency)}
            <br>
            ${that.currenciesFormat(doc.total_with_vat.toFixed(2), doc.currency)}
          </span>
        `
      } else {
        return `
          <span class="small">
            ${that.currenciesFormat('0.00', doc.currency)}
            <br>
            ${that.currenciesFormat('0.00', doc.currency)}
          </span>
        `
      }
    }

    window.statusFormatter = function(status, doc) {
      if(status) {
        let color = 'text-secondary'
        switch(status) {
          case 'draft':
            color = 'text-warning'
            break;
          case 'created':
            color = 'text-primary text-opacity-75'
            break;
          case 'ready':
            color = 'text-primary'
            break;
          case 'sent':
            color = 'text-success text-opacity-75'
            break;
          case 'paid':
            color = 'text-success'
            break;
          case 'draft':
            color = 'text-info'
            break;
          case 'rejected':
            color = 'text-muted'
            break;
          case 'overdue':
            color = 'text-danger'
            break;
          case 'completed':
            color = 'text-dark'
            break;
          case 'cancelled':
            color = 'text-black-50'
            break;
        }

        return `<span class="semi-bold smaller ${color}">${I18n.t(`document.status.${status}`)}</span>`;
      } else {
        return '';
      }
    }

    window.paidFormatter = function(paid, doc) {
      if(doc.draft) {
        return ''
      } else {
        if(doc.data && doc.data.paid) {
          return '<i class="far fa-check-square text-success"></i>';
        } else {
          return '<i class="fas fa-minus-square text-danger"></i>';
        }
      }
    }

    window.actionsFormatter = function(data, row) {
      return `
        <a class="btn btn-outline-secondary" href="/${locale}/document/${row.uuid}"><i class="fas fa-info-circle fa-fw"></i></a>
        <a target="_blank" class="btn btn-outline-secondary" href="/${locale}/document/${row.uuid}/open"><i class="fas fa-file-invoice fa-fw"></i></a>
        <a target="_blank" class="btn btn-outline-secondary" href="/${locale}/document/${row.uuid}/download"><i class="fas fa-download fa-fw"></i></a>
      `
    }
  }
}
