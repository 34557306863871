import { Controller } from "stimulus"

export default class extends Controller {

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-search")) {
      this.search(this.element.getAttribute("data-search"));
    } else {
      let searchController = this;
      $( document ).ready(function() {
        window.removeEventListener("keydown", () => {}, false);
        window.addEventListener("keydown",function (e) {
          if(e.keyCode === 111 && e.srcElement.nodeName == "BODY") {
            let form = document.querySelector("#searchForm");
            if(form) {
              let inp = form.querySelector("input#q");
              if(inp) {
                inp.select();
              }
            } else {
              searchController.showSearch();
            }
            e.preventDefault();
          }

        });
      });
    }
  }

  connect() {
    this.found = false;
    $('#loading').fadeOut(200);
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == 'pre') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  showSearch() {
    let locale = document.querySelector("meta[name='locale']").getAttribute('content');
    let content = `
      <form method="GET" action="/${locale}/search" class="form-inline mt-5 mb-3" id="searchForm">
        <div class="input-group mb-3 w-100">
          <input id="q" name="q" type="text" class="form-control form-control-lg" placeholder="${I18n.t('search.search_placeholder')}">
            <button class="btn btn-outline-secondary">
              <i class="fas fa-search fa-lg"></i>
            </button>
        </div>
      </form>
    `;
    var box = bootbox.dialog({
      id: 'searchModal',
      message: content,
      onEscape: true,
      size: 'large',
      onShown: () => {
        box[0].querySelector("#q").focus();
      },
      buttons: {}
    });
  }

  search(search) {
    let locale = document.querySelector("meta[name='locale']").getAttribute('content');
    this.searchContacts(search, locale);
    this.searchItems(search, locale);
    this.searchOrders(search, locale);
    this.searchPurchases(search, locale);
    this.searchDocuments(search, locale);
    this.searchAccounting(search, locale);
    this.searchInventory(search, locale);
    this.searchAttachments(search, locale);
  }

  searchContacts(search, locale) {
    let results = document.querySelector("#results_contacts");
    let table = document.querySelector("#results_contacts > table > tbody");
    let count = document.querySelector("#results_contacts_count");
    Rails.ajax({
      url: `/${locale}/search/contacts`,
      type: "post",
      data: `q=${search}`,
      success: function(data) {
        table.innerHTML = '';
        if(data.length > 0) {
          count.innerText = `(${data.length})`;
          results.classList.remove('d-none')
          data.slice(0, 50).forEach((res, i) => {
            let company;
            let name = '';
            if(res.company) {
              company = res.company;
              name = `${res.firstname} ${res.lastname}`;
            } else {
              company = `${res.firstname} ${res.lastname}`;
            }
            let icon = 'fa-building';
            if(!res.business) {
              icon = 'fa-user';
            }
            table.innerHTML += `
              <tr>
                <td>
                  <span class="fa-stack mr-1" style="vertical-align: top; font-size:.75rem;">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas ${icon} fa-stack-1x fa-inverse"></i>
                  </span>
                  <a href="/${locale}/contact/${res.uuid}" class="text-dark" style="text-decoration: none;">
                    ${company} <span class="smaller text-muted">&nbsp;&nbsp;&nbsp;${name}</span>
                  </a>
                </td>
              </tr>
            `;
            if(i == 49 && data.length > 50) {
              table.innerHTML += `
                <tr><td class="semi-bold pt-3">
                  &#8230 ${I18n.t('search.more_results', {count: data.length - 50})}
                </td></tr>
              `
            }
          });

        } else {
          results.classList.add('d-none')
        }
      },
      error: function(data) {}
    });
  }

  searchItems(search, locale) {
    let results = document.querySelector("#results_items");
    let table = document.querySelector("#results_items > table > tbody");
    let count = document.querySelector("#results_items_count");
    Rails.ajax({
      url: `/${locale}/search/items`,
      type: "post",
      data: `q=${search}`,
      success: function(data) {
        table.innerHTML = '';
        if(data.length > 0) {
          count.innerText = `(${data.length})`;
          results.classList.remove('d-none')
          data.slice(0, 50).forEach((res, i) => {
            let name = res.i18n[0].name;
            for(let i = 0; i < res.i18n.length; i++) {
              if(res.i18n[i].locale == locale) {
                name = res.i18n[i].name;
              }
            }
            let number = res.number ? res.number : '';
            table.innerHTML += `
              <tr>
                <td>
                  <span class="fa-stack mr-1" style="vertical-align: top; font-size:.75rem;">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-list fa-stack-1x fa-inverse"></i>
                  </span>
                  <a href="/${locale}/item/${res.uuid}" class="text-dark" style="text-decoration: none;">
                    ${name} <span class="smaller text-muted">${number}</span>
                  </a>
                </td>
              </tr>
            `;
            if(i == 49 && data.length > 50) {
              table.innerHTML += `
                <tr><td class="semi-bold pt-3">
                  &#8230 ${I18n.t('search.more_results', {count: data.length - 50})}
                </td></tr>
              `
            }
          });

        } else {
          results.classList.add('d-none')
        }
      },
      error: function(data) {}
    });
  }

  searchOrders(search, locale) {
    let results = document.querySelector("#results_orders");
    let table = document.querySelector("#results_orders > table > tbody");
    let count = document.querySelector("#results_orders_count");
    Rails.ajax({
      url: `/${locale}/search/orders`,
      type: "post",
      data: `q=${search}`,
      success: function(data) {
        table.innerHTML = '';
        if(data.length > 0) {
          count.innerText = `(${data.length})`;
          results.classList.remove('d-none')
          data.slice(0, 50).forEach((res, i) => {
            let details = [];
            let reference = '';
            if(res.subject) {
              reference = res.subject;
            }
            details = details.concat(res.address.split('\n').slice(0, 3));

            table.innerHTML += `
              <tr>
                <td>
                  <span class="fa-stack mr-1" style="vertical-align: top; font-size:.75rem;">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-file-invoice fa-stack-1x fa-inverse"></i>
                  </span>
                  <a href="/${locale}/order/${res.uuid}" class="text-dark" style="text-decoration: none;">
                    ${res.orderref} <span class="smaller text-muted">${reference} | ${res.total_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${res.currency}`)}</span><br><span class="small text-muted" style="margin-left: 37px;  ">${details.join(', ')}</span>
                  </a>
                </td>
              </tr>
            `;
            if(i == 49 && data.length > 50) {
              table.innerHTML += `
                <tr><td class="semi-bold pt-3">
                  &#8230 ${I18n.t('search.more_results', {count: data.length - 50})}
                </td></tr>
              `
            }
          });

        } else {
          results.classList.add('d-none')
        }
      },
      error: function(data) {}
    });
  }

  searchPurchases(search, locale) {
    let results = document.querySelector("#results_purchases");
    let table = document.querySelector("#results_purchases > table > tbody");
    let count = document.querySelector("#results_purchases_count");
    Rails.ajax({
      url: `/${locale}/search/purchases`,
      type: "post",
      data: `q=${search}`,
      success: function(data) {
        table.innerHTML = '';
        if(data.length > 0) {
          count.innerText = `(${data.length})`;
          results.classList.remove('d-none')
          data.slice(0, 50).forEach((res, i) => {
            let details = [];
            let reference = '';
            if(res.subject) {
              reference = res.subject;
            }
            details = details.concat(res.address.split('\n').slice(0, 3));

            table.innerHTML += `
              <tr>
                <td>
                  <span class="fa-stack mr-1" style="vertical-align: top; font-size:.75rem;">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-file-invoice fa-stack-1x fa-inverse"></i>
                  </span>
                  <a href="/${locale}/purchase/${res.uuid}" class="text-dark" style="text-decoration: none;">
                    ${res.purchaseref} <span class="smaller text-muted">${reference} | ${res.total_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${res.currency}`)}</span><br><span class="small text-muted" style="margin-left: 37px;  ">${details.join(', ')}</span>
                  </a>
                </td>
              </tr>
            `;
            if(i == 49 && data.length > 50) {
              table.innerHTML += `
                <tr><td class="semi-bold pt-3">
                  &#8230 ${I18n.t('search.more_results', {count: data.length - 50})}
                </td></tr>
              `
            }
          });

        } else {
          results.classList.add('d-none')
        }
      },
      error: function(data) {}
    });
  }

  searchAccounting(search, locale) {
    let that = this;
    let results = document.querySelector("#results_accounting");
    let table = document.querySelector("#results_accounting > table > tbody");
    let count = document.querySelector("#results_accounting_count");
    Rails.ajax({
      url: `/${locale}/search/accounting`,
      type: "post",
      data: `q=${search}`,
      success: function(data) {
        table.innerHTML = '';
        if(data.length > 0) {
          count.innerText = `(${data.length})`;
          results.classList.remove('d-none')
          data.slice(0, 50).forEach((res, i) => {
            let details = [];
            if(res.reference) {
              details.push(res.reference)
            }
            table.innerHTML += `
              <tr>
                <td>
                  <span class="fa-stack mr-1" style="vertical-align: top; font-size:.75rem;">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-calculator-simple fa-stack-1x fa-inverse"></i>
                  </span>
                  <a href="/${locale}/accounting/entry/${res.uuid}" class="text-dark" style="text-decoration: none;">
                    ${res.description} <span class="smaller text-muted">| ${that.currenciesFormat(res.gross.toFixed(2), res.currency)}</span>
                    <br><span class="small text-muted" style="margin-left: 37px;  ">${details.join(', ')}</span>
                  </a>
                </td>
              </tr>
            `;
            if(i == 49 && data.length > 50) {
              table.innerHTML += `
                <tr><td class="semi-bold pt-3">
                  &#8230 ${I18n.t('search.more_results', {count: data.length - 50})}
                </td></tr>
              `
            }
          });

        } else {
          results.classList.add('d-none')
        }
      },
      error: function(data) {}
    });
  }

  searchDocuments(search, locale) {
    let results = document.querySelector("#results_documents");
    let table = document.querySelector("#results_documents > table > tbody");
    let count = document.querySelector("#results_documents_count");
    Rails.ajax({
      url: `/${locale}/search/documents`,
      type: "post",
      data: `q=${search}`,
      success: function(data) {
        table.innerHTML = '';
        if(data.length > 0) {
          count.innerText = `(${data.length})`;
          results.classList.remove('d-none')
          data.slice(0, 50).forEach((res, i) => {
            let order = '';
            if(res.orderref) {
              order = `${I18n.t('order.order')}: ${res.orderref}`
            }
            if(res.purchaseref) {
              order = `${I18n.t('purchase.purchase')}: ${res.purchaseref}`
            }

            table.innerHTML += `
              <tr>
                <td>
                  <span class="fa-stack mr-1" style="vertical-align: top; font-size:.75rem;">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-copy fa-stack-1x fa-inverse"></i>
                  </span>
                  <a href="/${locale}/document/${res.uuid}/open" class="text-dark" style="text-decoration: none;" target="_blank">
                    ${res.reference} | <span class="smaller text-muted">${order}</span>
                  </a>
                </td>
              </tr>
            `;
            if(i == 49 && data.length > 50) {
              table.innerHTML += `
                <tr><td class="semi-bold pt-3">
                  &#8230 ${I18n.t('search.more_results', {count: data.length - 50})}
                </td></tr>
              `
            }
          });

        } else {
          results.classList.add('d-none')
        }
      },
      error: function(data) {}
    });
  }

  searchInventory(search, locale) {
    let results = document.querySelector("#results_inventory");
    let table = document.querySelector("#results_inventory > table > tbody");
    let count = document.querySelector("#results_inventory_count");
    Rails.ajax({
      url: `/${locale}/search/inventory`,
      type: "post",
      data: `q=${search}`,
      success: function(data) {
        table.innerHTML = '';
        if(data.length > 0) {
          count.innerText = `(${data.length})`;
          results.classList.remove('d-none')
          data.slice(0, 50).forEach((res, i) => {
            let details = []
            if(res.inventorynumber) {
              details.push(`${I18n.t('inventory.inventorynumber')}: ${res.inventorynumber}`);
            }
            if(res.serialnumber) {
              details.push(`${I18n.t('inventory.serialnumber')}: ${res.serialnumber}`)
            }
            table.innerHTML += `
              <tr>
                <td>
                  <span class="fa-stack mr-1" style="vertical-align: top; font-size:.75rem;">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-shelves fa-stack-1x fa-inverse"></i>
                  </span>
                  <a href="/${locale}/inventory/item/${res.uuid}" class="text-dark" style="text-decoration: none;" target="_blank">
                    ${res.name}<br><span class="small text-muted" style="margin-left: 37px;  ">${details.join(', ')}</span>
                  </a>
                </td>
              </tr>
            `;
            if(i == 49 && data.length > 50) {
              table.innerHTML += `
                <tr><td class="semi-bold pt-3">
                  &#8230 ${I18n.t('search.more_results', {count: data.length - 50})}
                </td></tr>
              `
            }
          });

        } else {
          results.classList.add('d-none')
        }
      },
      error: function(data) {}
    });
  }

  searchAttachments(search, locale) {
    let results = document.querySelector("#results_attachments");
    let table = document.querySelector("#results_attachments > table > tbody");
    let count = document.querySelector("#results_attachments_count");
    Rails.ajax({
      url: `/${locale}/search/attachments`,
      type: "post",
      data: `q=${search}`,
      success: function(data) {
        table.innerHTML = '';
        if(data.length > 0) {
          count.innerText = `(${data.length})`;
          results.classList.remove('d-none')
          data.slice(0, 50).forEach((res, i) => {
            let html = `
              <tr>
                <td>
                  <span class="fa-stack mr-1 mt-1" style="vertical-align: top; font-size:.75rem;">
                    <i class="fas fa-circle fa-stack-2x"></i>
                    <i class="fas fa-copy fa-stack-1x fa-inverse"></i>
                  </span>
                  <div class="d-inline-block">
            `
              if(res.contact) {
                html += `
                  <small>
                    <i class="fas fa-users"></i>
                    <a href="/${locale}/contact/${res.contact.uuid}" class="text-muted">
                      ${res.contact.firstname} ${res.contact.lastname}
                    </a>
                  </small><br>
                  <a href="${res.url}" class="text-dark" style="text-decoration: none;" target="_blank">
                    ${res.name}
                  </a>
                `
              } else if(res.accounting_entry) {
                let name = res.accounting_entry.reference;
                if(!name) {
                  name = res.accounting_entry.address.name
                }
                if(!name) {
                  name = I18n.t('accounting.entry')
                }
                html += `
                  <small>
                    <i class="far fa-calculator-simple"></i>
                    <a href="/${locale}/accounting/entry/${res.accounting_entry.uuid}" class="text-muted">
                      ${name}
                    </a>
                  </small><br>
                  <a href="${res.url}" class="text-dark" style="text-decoration: none;" target="_blank">
                    ${res.name}
                  </a>
                `
              } else if(res.item) {
                html += `
                  <small>
                    <i class="fas fa-list"></i>
                    <a href="/${locale}/item/${res.item.uuid}" class="text-muted">
                      ${res.item_name}
                    </a>
                  </small><br>
                  <a href="${res.url}" class="text-dark" style="text-decoration: none;" target="_blank">
                    ${res.name}
                  </a>
                `
              } else if(res.order) {
                html += `
                  <small>
                    <i class="fas fa-file-invoice"></i>
                    <a href="/${locale}/order/${res.order.uuid}" class="text-muted">
                      ${I18n.t('order.order')} ${res.order.orderref}
                    </a>
                  </small><br>
                  <a href="${res.url}" class="text-dark" style="text-decoration: none;" target="_blank">
                    ${res.name}
                  </a>
                `
              } else if(res.purchase) {
                html += `
                  <small>
                    <i class="fas fa-file-invoice"></i>
                    <a href="/${locale}/purchase/${res.purchase.uuid}" class="text-muted">
                      ${I18n.t('purchase.purchase')} ${res.purchase.purchaseref}
                    </a>
                  </small><br>
                  <a href="${res.url}" class="text-dark" style="text-decoration: none;" target="_blank">
                    ${res.name}
                  </a>
                `
              } else if(res.inventory_item) {
                html += `
                  <small>
                    <i class="fas fa-shelves"></i>
                    <a href="/${locale}/inventory/item/${res.inventory_item.uuid}" class="text-muted">
                      ${res.inventory_item.name}
                    </a>
                  </small><br>
                  <a href="${res.url}" class="text-dark" style="text-decoration: none;" target="_blank">
                    ${res.name}
                  </a>
                `
              }
            html += `
                  </div>
                </td>
              </tr>
            `;

            table.innerHTML += html;

            if(i == 49 && data.length > 50) {
              table.innerHTML += `
                <tr><td class="semi-bold pt-3">
                  &#8230 ${I18n.t('search.more_results', {count: data.length - 50})}
                </td></tr>
              `
            }
          });

        } else {
          results.classList.add('d-none')
        }
      },
      error: function(data) {}
    });
  }

}
