import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "filter" ]
  static store = '';

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
  }

  connect() {
    this.store = this.data.get('store-uuid');

    $('#loading').fadeOut(200);
  }

  filterSales() {
    let table = document.querySelector("#sales");
    let filter = this.filterTarget.value.toLowerCase();

    for (var i = 0, cell; cell = table.rows[i]; i++) {
      if(!table.rows[i].dataset.reference.toLowerCase().includes(filter) && !table.rows[i].dataset.customer.includes(filter) && !table.rows[i].dataset.total.includes(filter)) {
        table.rows[i].classList.add("d-none");
      } else {
        table.rows[i].classList.remove("d-none");
      }
    }
  }

  clearFilter() {
    this.filterTarget.value = '';
    this.filterSales();
  }

  showSale() {
    let uuid = event.target.parentNode.dataset.uuid;
    var str = document.querySelector("#config");

    Rails.ajax({
      url: `/${this.locale}/store/${this.store}/sale/${uuid}`,
      type: "GET",
      data: '',
      success: function(data) {
        let customer = '';
        if(data.customer && data.customer.company) {
          customer = data.customer.company+'<br>';
        }
        if(data.customer && (data.customer.firstname || data.customer.lastname)) {
          customer += data.customer.firstname+' '+data.customer.lastname;
        }
        customer = customer.trim();

        var content = `<div class="container-fluid">
          <h1 class="display-7 mt-2 mb-3">${customer}</h1>
          <h1 class="display-4 mt-2 mb-3">${data.total_with_vat.toFixed(2)} ${data.currency_abbr}</h1>
          <table class="table table-sm">
            <colgroup>
              <col width=*>
              <col width=80px>
              <col width=*>
              <col width=60px>
              <col width=60px>
            </colgroup>`

        data.items.forEach(function(item) {
          content += `<tr>
            <td>${item.label}</td>
            <td>${item.quantity}</td>`;
          if(item.price_with_discount) {
            content += `<td class="text-right">
              <span class="strikethrough">${item.price_with_vat.toFixed(2)} ${data.currency_abbr}</span>&nbsp;
              <span class="text-danger">${item.price_with_discount.toFixed(2)} ${data.currency_abbr}</span>
              </td>`;
          } else {
            content += `<td class="text-right">${item.price_with_vat.toFixed(2)}&nbsp;${data.currency_abbr}</td>`;
          }
          content += `<td class="semi-bold text-right">${item.total_with_vat.toFixed(2)}&nbsp;${data.currency_abbr}</td>
            <td class="text-right">${item.vat} %</td>`;
        });

        content += `</table></div>`;

        bootbox.dialog({
          message: content,
          onEscape: true,
          size: 'large',
          buttons: {
            cancel: {
              label: str.dataset.btn_close,
              className: "btn-outline-secondary",
            }
          }
        });

      },
      error: function(data) {}
    });


  }
}
