import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {

  static targets = [ "uploading", "profilePicture", "pictureSid" ]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
  }

  connect() {
    document.querySelectorAll('[role="switch"]').forEach((swch, i) => {
      let checkbox = document.querySelector(`#${swch.id}_readonly`);
      if(checkbox) {
        checkbox.disabled = !swch.checked;
        if(!swch.checked) {
          checkbox.checked = false;
        }
      }
    });

    if(this.page == 'profile') {
      $(".selectpicker_tz").selectpicker({ width: '100%', liveSearch: true});
    }

    $('#loading').fadeOut(200);
  }

  checkCurrentPassword(){
    if(event.target.value.length >= 6) {
      $("#btnChangePassword").prop('disabled', false);
    } else {
      $("#btnChangePassword").prop('disabled', true);
    }
  }

  checkNewPassword() {
    if(event.target.value.length >= 6) {
      $("#passwordShort2").addClass("d-none");
      $("#user_password2").removeClass("is-invalid")
      $("#user_password2").addClass("is-valid")

      if(event.target.value == $("#user_password3").val()) {
        $("#user_password2").removeClass("is-invalid")
        $("#user_password2").addClass("is-valid")
        $("#passwordMismatch2").addClass("d-none");

        $("#user_password3").removeClass("is-invalid")
        $("#user_password3").addClass("is-valid")
        $("#passwordMismatch3").addClass("d-none");
      } else {
        $("#user_password2").removeClass("is-valid")
        $("#user_password2").addClass("is-invalid")
        $("#passwordMismatch2").removeClass("d-none");

        $("#user_password3").removeClass("is-valid")
        $("#user_password3").addClass("is-invalid")
        $("#passwordMismatch3").removeClass("d-none");
      }
    } else {
      $("#user_password2").removeClass("is-valid")
      $("#user_password2").addClass("is-invalid")
      $("#passwordShort2").removeClass("d-none");
    }
  }

  checkPasswordConfirmation() {
    if(event.target.value.length >= 6) {
      $("#passwordShort3").addClass("d-none");
      $("#user_password3").removeClass("is-invalid")
      $("#user_password3").addClass("is-valid")

      if(event.target.value == $("#user_password2").val()) {
        $("#user_password2").removeClass("is-invalid")
        $("#user_password2").addClass("is-valid")
        $("#passwordMismatch2").addClass("d-none");

        $("#user_password3").removeClass("is-invalid")
        $("#user_password3").addClass("is-valid")
        $("#passwordMismatch3").addClass("d-none");
      } else {
        $("#user_password2").removeClass("is-valid")
        $("#user_password2").addClass("is-invalid")
        $("#passwordMismatch2").removeClass("d-none");

        $("#user_password3").removeClass("is-valid")
        $("#user_password3").addClass("is-invalid")
        $("#passwordMismatch3").removeClass("d-none");
      }
    } else {
      $("#user_password3").removeClass("is-valid")
      $("#user_password3").addClass("is-invalid")
      $("#passwordShort3").removeClass("d-none");
    }
  }

  disableChangeButton() {
    var form = document.querySelector('#formPassword');
    Rails.fire(form, 'submit');
  }

  deleteUser() {
    let usersController = this;
    var parent = event.target.closest("tr");
    var uuid = parent.dataset.uuid;
    var msg = `<span class="lead">${parent.dataset.firstname} ${parent.dataset.lastname}</span>`;
    bootbox.dialog({
      message: `${I18n.t('user.confirm.delete')}<br><br>${msg}`,
      onEscape: true,
      buttons: {
        cancel: {
          label: I18n.t('btn.cancel'),
          className: "btn-outline-secondary",
        },
        delete: {
          label: I18n.t('btn.delete'),
          className: "btn-danger",
          callback: function() {
            Rails.ajax({
              url: `/${usersController.locale}/user/${uuid}/delete`,
              type: "delete",
              data: "",
              success: function(data) {},
              error: function(data) {}
            });
    	      bootbox.hideAll();
          }
        }
      }
    });
  }

  togglePermissions() {
    let checkbox = document.querySelector(`#${event.target.id}_readonly`);
    if(checkbox) {
      checkbox.disabled = !event.target.checked;
      if(!event.target.checked) {
        checkbox.checked = false;
      }
    }
  }

  disable2FA() {
    bootbox.confirm({
      title: I18n.t('user.two_factor_authentication'),
      message: I18n.t('user.message.2fa_disable'),
      buttons: {
        confirm: {
            label: I18n.t('btn.disable'),
            className: 'btn-outline-danger'
        },
        cancel: {
            label: I18n.t('btn.cancel'),
            className: 'btn-outline-secondary'
        }
      },
      callback: function (result) {
        if(result) {
          Rails.ajax({
            url: `/${this.locale}/users/two_factor_disable`,
            type: "post",
            data: "",
            success: function(data) {
              location.reload();
            },
            error: function(data) {}
          });
        }
      }
    });
  }

  selectProfilePicture() {
    var div = event.target.closest("div");
    var input = div.querySelector("#imgpicker");
    input.click();
  }

  deleteProfilePicture() {
    this.profilePictureTarget.innerHTML = '<i class="fa-solid fa-user fa-6x"></i>';
    this.pictureSidTarget.value = '';
  }

  uploadProfilePicture() {
    let that = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;

    var files = input.files.length;
    var count = 0;

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      that.uploadingTarget.classList.remove('d-none');

      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/users/picture/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              let img = document.createElement('img')
              img.src = data;
              img.classList.add('rounded-circle', 'profile-picture', 'border', 'border-secondary-subtl')
              img.style.maxHeight = '250px';
              img.style.maxWidth = '250px';
              that.profilePictureTarget.innerHTML = '';
              that.profilePictureTarget.appendChild(img);

              that.pictureSidTarget.value = btoa(blob.signed_id);
            },
            error: function(data) {}
          });
        }
        that.uploadingTarget.classList.add('d-none');
      })
    })

    input.value = '';
  }
}
