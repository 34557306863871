import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $('#loading').fadeOut(200);
  }

  toggleInput() {
    let body = event.target.closest('.modal-body');
    let input = body.querySelector(`#address_${event.target.dataset.target}`);
    if(input) {
      let row = input.closest('div.row');
      if(row) {
        if(event.target.checked) {
          row.classList.remove('d-none');
        } else {
          row.classList.add('d-none');
          input.value = '';
        }
      }
    }

    event.stopPropagation();
  }
}
