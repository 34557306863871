import { Controller } from "stimulus"
import { TempusDominus } from "@eonasdan/tempus-dominus"

export default class extends Controller {

  static targets = [ "datefrom", "dateto", "customers", "items", "customers", "selected_items_wrapper", "selected_customers_wrapper", "revenueChart", "revenueCountryChart", "itemsChart", "customersChart", "reportType", "report", "report_header", "wrapperFilterCustomers", "wrapperFilterItems" ]

  static values = {
    currency: String,
    currencyFormat: String,
    currencyAbbr: String
  }

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }
    if(this.element.getAttribute("data-page")) {
      this.page = this.element.getAttribute("data-page");
    }
  }

  connect() {
    var sp = $(".selectpicker").selectpicker({ width: '100%' });

    sp.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      let selector = event.target.id;
      if(selector == 'sales') {
        switch(event.target.value) {
          case 'item':
            document.querySelector('#sales_date').classList.add('d-none');
            document.querySelector('#sales_customer').classList.add('d-none');
            document.querySelector('#sales_item').classList.remove('d-none');
            break;
          case 'customer':
            document.querySelector('#sales_date').classList.add('d-none');
            document.querySelector('#sales_item').classList.add('d-none');
            document.querySelector('#sales_customer').classList.remove('d-none');
            break;
          default:
            document.querySelector('#sales_item').classList.add('d-none');
            document.querySelector('#sales_customer').classList.add('d-none');
            document.querySelector('#sales_date').classList.remove('d-none');
        }
      }
    });

    if(document.querySelector('#datetimepicker1')) {
      this.dt1 = new TempusDominus(document.getElementById('datetimepicker1'), {
        localization: {
          locale: this.locale
        },
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          }
        }
      });
    }

    if(document.querySelector('#datetimepicker2')) {
      this.dt2 = new TempusDominus(document.getElementById('datetimepicker2'), {
        localization: {
          locale: this.locale
        },
        display: {
          components: {
            date: true,
            decades: true,
            month: true,
            year: true,
            hours: false,
            seconds: false,
            minutes: false
          }
        }
      });
    }

    if(this.page == 'index') {
      this.loadOverview()
    }

    $('#loading').fadeOut(200);

    if(this.page == 'sales' || this.page == 'items') {
      this.initItemSelectDialog();
      this.initCustomerSelectDialog();
      this.loadReportFunctions();
    }
  }

  loadOverview() {
    let that = this;

    this.loadRevenueChart();
    this.loadRevenueByCountryChart();
    this.loadItemsChart();
    this.loadCustomersChart();
  }

  loadRevenueChart() {
    let that = this;

    let period = "month"
    if(Cookies.get('reporting_overview_revenue')) {
      period = Cookies.get('reporting_overview_revenue')
    }
    if(event.target && event.target.dataset && event.target.dataset.period) {
      period = event.target.dataset.period;
    }

    Rails.ajax({
      url: `/${that.locale}/reporting/report/revenue/${period}`,
      type: "get",
      data: '',
      success: function(data) {
        const chart = Chart.getChart(that.revenueChartTarget);
        if(chart) {
          chart.destroy();
        }

        const revenueChart = new Chart(that.revenueChartTarget, {
          data: data,
          type: 'bar',
          options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  title: function(tooltipItem, d) {
                    return `${tooltipItem[0].label} ${tooltipItem[0].dataset.label}`;
                  },
                  label: function(tooltipItem, d) {
                    return that.currenciesFormat(tooltipItem.formattedValue, that.currencyValue);
                  },
                }
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  callback: function(value, index, values) {
                    return that.currenciesFormat(value, that.currencyValue);
                  }
                }
              }
            },
            onHover: (event, item, legend) => {
              if(item.length > 0) {
                event.native.target.style.cursor = 'pointer';
              } else {
                event.native.target.style.cursor = 'auto';
              }
            },
            onLeave: (event, item, legend) => {
              event.native.target.style.cursor = 'auto';
            }
          }
        });
      },
      error: function(data) {}
    });
    Cookies.set('reporting_overview_revenue', period)
  }

  loadRevenueByCountryChart() {
    let that = this;

    let period = "month"
    if(Cookies.get('reporting_overview_revenue_by_country')) {
      period = Cookies.get('reporting_overview_revenue_by_country')
    }
    if(event.target && event.target.dataset && event.target.dataset.period) {
      period = event.target.dataset.period;
    }

    Rails.ajax({
      url: `/${that.locale}/reporting/report/revenue-country/${period}`,
      type: "get",
      data: '',
      success: function(data) {
        const chart = Chart.getChart(that.revenueCountryChartTarget);
        if(chart) {
          chart.destroy();
        }

        const revenueChart = new Chart(that.revenueCountryChartTarget, {
          data: data,
          type: 'bar',
          options: {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  title: function(tooltipItem, d) {
                    return `${tooltipItem[0].label} ${tooltipItem[0].dataset.label}`;
                  },
                  label: function(tooltipItem, d) {
                    return that.currenciesFormat(tooltipItem.formattedValue, that.currencyValue);
                  },
                }
              },
            },
            scales: {
              y: {
                offset: true,
                beginAtZero: true,
                ticks: {
                  callback: function(value, index, values) {
                    return that.currenciesFormat(value, that.currencyValue);
                  }
                }
              }
            },
            onHover: (event, item, legend) => {
              if(item.length > 0) {
                event.native.target.style.cursor = 'pointer';
              } else {
                event.native.target.style.cursor = 'auto';
              }
            },
            onLeave: (event, item, legend) => {
              event.native.target.style.cursor = 'auto';
            }
          }
        });
      },
      error: function(data) {}
    });
    Cookies.set('reporting_overview_revenue_by_country', period)
  }

  loadItemsChart() {
    let that = this;

    let period = "month"
    if(Cookies.get('reporting_overview_items')) {
      period = Cookies.get('reporting_overview_items')
    }
    if(event.target && event.target.dataset && event.target.dataset.period) {
      period = event.target.dataset.period;
    }

    Rails.ajax({
      url: `/${that.locale}/reporting/report/items/${period}`,
      type: "get",
      data: '',
      success: function(data) {
        const chart = Chart.getChart(that.itemsChartTarget);
        if(chart) {
          chart.destroy();
        }

        const itemsChart = new Chart(that.itemsChartTarget, {
          data: data,
          type: 'bar',
          options: {
            maintainAspectRatio: false,
            responsive: true,
            indexAxis: 'y',
            plugins: {
              legend: {
                display: true
              },
              tooltip: {
                callbacks: {
                  label: function(tooltipItem, d) {
                    return `${I18n.t('item.quantity')}: ${tooltipItem.formattedValue} | Ø ${that.currenciesFormat(tooltipItem.raw.price.toFixed(2), that.currencyValue)}`;
                  },
                }
              },
            },
            onHover: (event, item, legend) => {
              if(item.length > 0) {
                event.native.target.style.cursor = 'pointer';
              } else {
                event.native.target.style.cursor = 'auto';
              }
            },
            onLeave: (event, item, legend) => {
              event.native.target.style.cursor = 'auto';
            }
          }
        });
      },
      error: function(data) {}
    });
    Cookies.set('reporting_overview_items', period)
  }

  loadCustomersChart() {
    let that = this;

    let period = "month"
    if(Cookies.get('reporting_overview_customers')) {
      period = Cookies.get('reporting_overview_customers')
    }
    if(event.target && event.target.dataset && event.target.dataset.period) {
      period = event.target.dataset.period;
    }

    Rails.ajax({
      url: `/${that.locale}/reporting/report/customers/${period}`,
      type: "get",
      data: '',
      success: function(data) {
        const chart = Chart.getChart(that.customersChartTarget);
        if(chart) {
          chart.destroy();
        }

        const customersChart = new Chart(that.customersChartTarget, {
          data: data,
          type: 'bar',
          options: {
            maintainAspectRatio: false,
            responsive: true,
            indexAxis: 'y',
            plugins: {
              legend: {
                display: true
              },
              tooltip: {
                callbacks: {
                  label: function(tooltipItem, d) {
                    return `${tooltipItem.dataset.label}: ${that.currenciesFormat(tooltipItem.raw.toFixed(2), that.currencyValue)}`;
                  },
                }
              },
            },
            onHover: (event, item, legend) => {
              if(item.length > 0) {
                event.native.target.style.cursor = 'pointer';
              } else {
                event.native.target.style.cursor = 'auto';
              }
            },
            onLeave: (event, item, legend) => {
              event.native.target.style.cursor = 'auto';
            }
          }
        });
      },
      error: function(data) {}
    });
    Cookies.set('reporting_overview_customers', period)
  }

  updateView() {
    switch (event.target.value) {
      case 'items':
        this.wrapperFilterItemsTarget.classList.remove('d-none')
        this.wrapperFilterCustomersTarget.classList.remove('d-none')
        break;
      case 'customers':
        this.wrapperFilterItemsTarget.classList.add('d-none')
        this.wrapperFilterCustomersTarget.classList.add('d-none')
        break;
      default:
        this.wrapperFilterCustomersTarget.classList.remove('d-none')
        this.wrapperFilterItemsTarget.classList.add('d-none')
    }
  }

  loadReport() {
    let that = this;
    let fd = new FormData();
    fd.append("report", this.reportTypeTarget.value);
    fd.append("dtstart", this.datefromTarget.value);
    fd.append("dtend", this.datetoTarget.value);
    fd.append("customers", this.customersTarget.value);
    fd.append("items", this.itemsTarget.value);

    Rails.ajax({
      url: `/${that.locale}/reporting/sales`,
      type: "post",
      data: fd,
      success: function(data) {
        if(that.reportTypeTarget.value == 'date') {
          that.loadSalesByDateReport(data);
        } else if(that.reportTypeTarget.value == 'items') {
          that.loadSalesByItemsReport(data);
        } else if(that.reportTypeTarget.value = 'customers') {
          that.loadSalesByCustomersReport(data);
        }
      },
      error: function(data) {}
    });
  }

  loadPeriodReport() {
    let dt1 = new Date(event.target.dataset.dt1);
    let dt2 = new Date(event.target.dataset.dt2);
    this.dt1.dates.setValue(tempusDominus.DateTime.convert(dt1));
    this.dt2.dates.setValue(tempusDominus.DateTime.convert(dt2));
    this.loadReport()
  }

  loadSalesByDateReport(data) {
    var currencies = data.reduce(function(map, doc){
      map[doc.currency] = (map[doc.currency] || 0) + doc.total;
      return map;
    }, {});

    this.reportTarget.innerHTML = '';
    let table = document.createElement('table');
    table.classList.add('table', 'table-sm');
    table.innerHTML = `
      <tr><td class="semi-bold">
        ${I18n.t('date.date')}:
      </td><td class="text-end">
        ${I18n.l("date.formats.default", this.dt1.viewDate)} - ${I18n.l("date.formats.default", this.dt2.viewDate)}
      </td></tr>
      <tr><td class="semi-bold">
        ${I18n.t('document.invoices')}:
      </td><td class="text-end">
        ${data.length}
      </td></tr>
    `
    for (var currency in currencies){
      table.innerHTML += `<tr><td class="semi-bold">${I18n.t('order.total')}:</td><td class="text-end">${this.currenciesFormat(currencies[currency].toFixed(2), currency)}</td></tr>`;
    }
    table.innerHTML += `</table>`;

    this.report_headerTarget.innerHTML = '';
    this.report_headerTarget.appendChild(table);
    this.report_headerTarget.parentNode.classList.remove('d-none');

    table = document.createElement('table');
    table.classList.add('table', 'table-sm')
    table.innerHTML = `
      <thead class="small">
        <tr>
          <th scope="col" data-field="date" data-sortable="true" data-class="align-top" data-formatter="dateFormatter">${I18n.t('date.date')}</th>
          <th scope="col" data-field="reference" data-sortable="true" data-class="align-top" data-formatter="referenceFormatter">${I18n.t('document.reference')}</th>
          <th scope="col" data-field="customer_name" data-sortable="true" data-class="align-top">${I18n.t('document.customer')}</th>
          <th scope="col" data-field="items" data-sortable="true" data-class="align-top" data-formatter="itemsFormatter">${I18n.t('item.items')}</th>
          <th scope="col" data-field="total" data-sortable="true" data-class="align-top text-end" data-formatter="totalFormatter">${I18n.t('order.amount')}</th>
        </tr>
      </thead>
      <tbody class="smaller">
      </tbody>
    `

    this.reportTarget.innerHTML = '';
    this.reportTarget.appendChild(table);

    $(table).bootstrapTable({
      data: data,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchTimeOut: 250,
      loadingFontSize: '1rem',
      sortName: 'date',
      sortOrder: 'desc'
    });
  }

  loadSalesByItemsReport(data) {
    var currencies = data.reduce(function(map, doc){
      map[doc.currency] = (map[doc.currency] || 0) + doc.total;
      return map;
    }, {});

    var totalItems = data.reduce(function (quantity, item) {
      return quantity + item.quantity;
    }, 0);

    this.reportTarget.innerHTML = '';
    let table = document.createElement('table');
    table.classList.add('table', 'table-sm');
    table.innerHTML = `
      <tr><td class="semi-bold">
        ${I18n.t('date.date')}:
      </td><td class="text-end">
        ${I18n.l("date.formats.default", this.dt1.viewDate)} - ${I18n.l("date.formats.default", this.dt2.viewDate)}
      </td></tr>
      <tr><td class="semi-bold">
        ${I18n.t('item.items')}:
      </td><td class="text-end">
        ${totalItems}
      </td></tr>
    `
    for (var currency in currencies){
      table.innerHTML += `<tr><td class="semi-bold">${I18n.t('order.total')}:</td><td class="text-end">${this.currenciesFormat(currencies[currency].toFixed(2), currency)}</td></tr>`;
    }
    table.innerHTML += `</table>`;

    this.report_headerTarget.innerHTML = '';
    this.report_headerTarget.appendChild(table);
    this.report_headerTarget.parentNode.classList.remove('d-none');

    table = document.createElement('table');
    table.classList.add('table', 'table-sm')
    table.innerHTML = `
      <thead class="small">
        <tr>
          <th scope="col" data-field="label" data-sortable="true" data-class="align-top" data-formatter="itemFormatter">${I18n.t('item.item')}</th>
          <th scope="col" data-field="documents" data-sortable="true" data-class="align-top" data-formatter="documentsFormatter">${I18n.t('document.documents')}</th>
          <th scope="col" data-field="quantity" data-sortable="true" data-class="align-top text-end">${I18n.t('item.quantity')}</th>
          <th scope="col" data-field="total" data-sortable="true" data-class="align-top text-end" data-formatter="totalFormatter">${I18n.t('order.total')}</th>
          <th scope="col" data-field="price" data-sortable="true" data-class="align-top text-end" data-formatter="totalFormatter">Ø ${I18n.t('item.price')}</th>
        </tr>
      </thead>
      <tbody class="smaller">
      </tbody>
    `

    this.reportTarget.innerHTML = '';
    this.reportTarget.appendChild(table);

    $(table).bootstrapTable({
      data: data,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchTimeOut: 250,
      loadingFontSize: '1rem',
      sortName: 'quantity',
      sortOrder: 'desc'
    });
  }

  loadSalesByCustomersReport(data) {
    var currencies = data.reduce(function(map, doc){
      map[doc.currency] = (map[doc.currency] || 0) + doc.total;
      return map;
    }, {});

    this.reportTarget.innerHTML = '';
    let table = document.createElement('table');
    table.classList.add('table', 'table-sm');
    table.innerHTML = `
      <tr><td class="semi-bold">
        ${I18n.t('date.date')}:
      </td><td class="text-end">
        ${I18n.l("date.formats.default", this.dt1.viewDate)} - ${I18n.l("date.formats.default", this.dt2.viewDate)}
      </td></tr>
      <tr><td class="semi-bold">
        ${I18n.t('order.customers')}:
      </td><td class="text-end">
        ${data.length}
      </td></tr>
    `
    for (var currency in currencies){
      table.innerHTML += `<tr><td class="semi-bold">${I18n.t('order.total')}:</td><td class="text-end">${this.currenciesFormat(currencies[currency].toFixed(2), currency)}</td></tr>`;
    }
    table.innerHTML += `</table>`;

    this.report_headerTarget.innerHTML = '';
    this.report_headerTarget.appendChild(table);
    this.report_headerTarget.parentNode.classList.remove('d-none');

    table = document.createElement('table');
    table.classList.add('table', 'table-sm')
    table.innerHTML = `
      <thead class="small">
        <tr>
          <th scope="col" data-field="customer_name" data-sortable="true" data-class="align-top" data-formatter="customerFormatter">${I18n.t('document.customer')}</th>
          <th scope="col" data-field="documents" data-sortable="true" data-class="align-top">${I18n.t('document.documents')}</th>
          <th scope="col" data-field="total" data-sortable="true" data-class="align-top text-end" data-formatter="totalFormatter">${I18n.t('order.total')}</th>
        </tr>
      </thead>
      <tbody class="smaller">
      </tbody>
    `

    this.reportTarget.innerHTML = '';
    this.reportTarget.appendChild(table);

    $(table).bootstrapTable({
      data: data,
      locale: this.locale,
      minHeight: '400',
      classes: 'table',
      search: true,
      pagination: true,
      paginationLoop: false,
      pageSize: 100,
      pageList: [50, 100, 250, 'All'],
      searchTimeOut: 250,
      loadingFontSize: '1rem',
      sortName: 'total',
      sortOrder: 'desc'
    });
  }

  currenciesAbbr(currency) {
    if(this.currencyAbbrValue == 'iso4217') {
      return currency.toUpperCase();
    } else {
      return I18n.t(`currencies.abbr.${currency}`);
    }
  }

  currenciesFormat(value, currency) {
    if(this.currencyFormatValue == '%u %n') {
      return `${this.currenciesAbbr(currency)} ${value}`
    } else {
      return `${value} ${this.currenciesAbbr(currency)}`
    }
  }

  selectCustomers() {
    var table = $('#customerSelect').bootstrapTable({});

    this.customerSelectDialog.show();

    let dialog = document.querySelector('#customerSelectDialog');

    dialog.addEventListener('shown.bs.modal', function (event) {
      event.target.querySelector(".search-input").focus();
    })
  }

  selectItems() {
    var table = $('#itemSelect').bootstrapTable({});

    this.itemSelectDialog.show();

    let dialog = document.querySelector('#itemSelectDialog');

    dialog.addEventListener('shown.bs.modal', function (event) {
      event.target.querySelector(".search-input").focus();
    })
  }

  loadReportFunctions() {
    let that = this;

    window.referenceFormatter = function(reference, row) {
      return `<a href="/${that.locale}/document/${row.uuid}" target="_blank">${reference}</a>`;
    }
    window.customerFormatter = function(customer, row) {
      return `<a href="/${that.locale}/contact/${row.uuid}" target="_blank">${customer}</a>`;
    }
    window.dateFormatter = function(date, row) {
      return I18n.l("date.formats.default", date);
    }
    window.itemFormatter = function(label, row) {
      if(row.item) {
        return `<a href="/${that.locale}/item/${row.item}" target="_blank">${label}</a> <span class="text-secondary small">  |  ${row.item_number}</span>`;
      } else {
        return label;
      }
    }
    window.totalFormatter = function(total, row) {
      return that.currenciesFormat(total.toFixed(2), row.currency);
    }
    window.itemsFormatter = function(items, row) {
      let str = `<details><summary>${I18n.t('item.items')}</summary>`;
      if(row.items) {
        str += '<table class="table table-borderless table-sm small"><colgroup><col width="50px"><col width="*"><col width="150px"></colgroup>'
        items.forEach((item, i) => {
          str += `<tr><td class="text-end">${item.quantity}</td><td>${item.name} <span class="text-secondary">  |  ${item.item_number}</span></td><td class="text-end">${item.total.toFixed(2)}</td></tr>`
        });
        str += "</table></details>";
      }

      return str;
    }
    window.documentsFormatter = function(documents, row) {
      let str = `<details><summary>${I18n.t('document.documents')}</summary><p class="small">`;
      if(row.documents) {
        documents.forEach((doc, i) => {
          str += `${doc[2].name} | <a href="/${that.locale}/document/${doc[1]}" target="_blank">${doc[0]}</a><br>`
        });
      }
      str += "</p></details>"
      return str;
    }
  }

  initCustomerSelectDialog() {
    let that = this;

    this.customerSelectDialog = new bootstrap.Modal(document.querySelector('#customerSelectDialog'), {
      keyboard: true,
      focus: true
    });

    let table = $('#customerSelect').bootstrapTable({
      url: `/${that.locale}/reporting/contacts`,
      locale: that.locale,
      stickyHeader: true,
      undefinedText: '',
      uniqueId: 'uuid',
      search: true,
      loadingFontSize: '1rem',
      classes: 'table',
      searchTimeOut: 250,
      clickToSelect: true,
      showColumns: true,
    });

    document.querySelector('#customerSelectDialog div.search').classList.add('w-75');
    document.querySelector('#customerSelectDialog input.search-input').style.height = '34px';

    let dialog = document.querySelector('#customerSelectDialog');
    let btnSelect = dialog.querySelector("button#btnSelect");
    btnSelect.addEventListener('click', function (event) {
      btnSelect.disabled = true;
      let icon = document.createElement("i");
      icon.classList.add("fas", "fa-spinner", "fa-pulse");
      btnSelect.prepend(icon);

      setTimeout(() => {
        let customers = table.bootstrapTable('getSelections');
        if(customers.length > 0) {
          let arr = [];
          for(let i = 0; i < customers.length; i++) {
            arr.push(customers[i].uuid)
          }
          that.customersTargets.forEach((inp, i) => {
            inp.value = arr;
          });
          that.selected_customers_wrapperTargets.forEach((div, i) => {
            div.innerHTML = `<span class="ms-2 mt-2 badge bg-secondary">${customers.length} ${I18n.t('order.customers')}</span>`
          });
        } else {
          that.customersTargets.forEach((inp, i) => {
            inp.value = "";
          });
          that.selected_customers_wrapperTargets.forEach((div, i) => {
            div.innerHTML = ""
          });
        }
      }, 50);

      setTimeout(() => {
        that.customerSelectDialog.hide();
        let icon2 = btnSelect.querySelector('svg');
        btnSelect.removeChild(icon2);
        btnSelect.disabled = false;

        dialog.querySelectorAll("button[data-loaded='true']").forEach((btn, i) => {
          let tab = btn.parentNode.querySelector('table');
          tab.innerHTML = '';
          btn.dataset.loaded = 'false';
        });

      }, 250)
    });
  }

  initItemSelectDialog() {
    let reportingController = this;

    this.itemSelectDialog = new bootstrap.Modal(document.querySelector('#itemSelectDialog'), {
      keyboard: true,
      focus: true
    });

    let table = $('#itemSelect').bootstrapTable({
      locale: this.locale,
      url: `/${this.locale}/orders/items`,
      search: true,
      loadingFontSize: '1rem',
      classes: 'table',
      searchTimeOut: 250,
      clickToSelect: true,
      showColumns: true,
    });

    document.querySelector('#itemSelectDialog div.search').classList.add('w-75');
    document.querySelector('#itemSelectDialog input.search-input').style.height = '34px';

    table.on("column-switch.bs.table", function() {
      let cols = JSON.stringify(table.bootstrapTable('getVisibleColumns').map(function (it) {
        return it.field
      }))

      Rails.ajax({
        url: `/${reportingController.locale}/subscription/config`,
        type: "post",
        data: `parameter=reporting_items_select_columns&value=${cols}`,
        success: function(data) {},
        error: function(data) {}
      });
    });

    let dialog = document.querySelector('#itemSelectDialog');
    let btnSelect = dialog.querySelector("button#btnSelect");
    btnSelect.addEventListener('click', function (event) {
      btnSelect.disabled = true;
      let icon = document.createElement("i");
      icon.classList.add("fas", "fa-spinner", "fa-pulse");
      btnSelect.prepend(icon);

      setTimeout(() => {
        let items = table.bootstrapTable('getSelections');
        if(items.length > 0) {
          let arr = [];
          for(let i = 0; i < items.length; i++) {
            arr.push(items[i].uuid)
          }
          reportingController.itemsTargets.forEach((inp, i) => {
            inp.value = arr;
          });
          reportingController.selected_items_wrapperTargets.forEach((div, i) => {
            div.innerHTML = `<span class="ms-2 mt-2 badge bg-secondary">${items.length} ${I18n.t('item.items')}</span>`
          });
        } else {
          reportingController.itemsTargets.forEach((inp, i) => {
            inp.value = "";
          });
          reportingController.selected_items_wrapperTargets.forEach((div, i) => {
            div.innerHTML = ""
          });
        }
      }, 50);

      setTimeout(() => {
        reportingController.itemSelectDialog.hide();
        let icon2 = btnSelect.querySelector('svg');
        btnSelect.removeChild(icon2);
        btnSelect.disabled = false;

        dialog.querySelectorAll("button[data-loaded='true']").forEach((btn, i) => {
          let tab = btn.parentNode.querySelector('table');
          tab.innerHTML = '';
          btn.dataset.loaded = 'false';
        });

      }, 250)
    });

    window.itemNameFormatter = function(name, item) {
      return `${name}&nbsp;&nbsp;<span class="small text-muted">${[item.size, item.color].filter(item => item).join(' | ')}</span>`
    }

    window.itemStockFormatter = function(stock, item) {
      if(stock == null) {
        stock = '-'
      }
      if(item.with_stock) {
        return `
          <button type="button" class="btn btn-outline-light btn-sm text-dark" data-action="click->items#loadStockQuantities" data-uuid="${item.uuid}" data-loaded="false">
            ${stock}
          </button>
          <table class="small table-borderless table-sm warehouse-quantities"></table>
        `
      } else {
        return ''
      }
    }

    window.itemStatusFormatter = function(status, item) {
      let str = '';
      if(item.statusname) {
        if(item.status_subscription_id) {
          str += item.statusname;
        } else {
          str += I18n.t(`item.states.${item.statusname}`);
        }
      }
      return str
    }

    window.itemPriceFormatter = function(price, item) {
      if(price && !isNaN(price)) {
        if(item.price_with_discount) {
          return `
            <span class="text-danger">${item.price_with_discount.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}</span>
            <br>
            <small class="text-muted">
              ${item.price_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            </small>
          `
        } else {
          return `
            ${price.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            <br>
            <small class="text-muted">
              ${item.price_with_vat.toFixed(2)} ${I18n.t(`currencies.abbr.${item.currency}`)}
            </small>
          `
        }
      } else {
        return ''
      }
    }

    window.itemReservedFormatter = function(reserved, item) {
      if(item.with_stock && item.reserved && item.reserved > 0) {
        return reserved
      } else {
        return ''
      }
    }

    window.itemAvailabilityFormatter = function(available, item) {
      if(item.with_stock) {
        return item.availability
      } else {
        return ''
      }
    }
  }
}
