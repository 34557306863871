import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "signin_div", "signup_div" ]

  connect() {
    $('#user_email').focus();
    if(this.hasSignin_divTarget) {
      this.signin_divTarget.style.maxWidth = `${this.signin_divTarget.offsetWidth+1}px`;
    }
    if(this.hasSignup_divTarget) {
      this.signup_divTarget.style.maxWidth = `${this.signup_divTarget.offsetWidth+1}px`;
    }
  }
}
